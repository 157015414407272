<template>

<div>
  <link rel="stylesheet" href="./css/extra.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
<div class="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <div>
      <img class="mx-auto h-12 w-auto" src="images/logos/barpot_logo_only_w_text.svg" alt="Barpot">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Join our Discord
      </h2>
      <div class="flex justify-center mt-4" id="discord-button">
              <a href="https://discord.gg/8TFrWS5EZa" style="font-size: 0.5em;">
                <div class="icon">
                  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 240">
                    <path class="st0" d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
                    <path class="st0" d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" />
                  </svg>
                </div>
                <span>Join the Discord</span>
              </a>
            </div>
      <p class="mt-2 text-center text-sm text-gray-600">
        Or
      </p>
      <div id="discord-button" class="flex justify-center mt-4 animate__animated animate__tada animate__delay-2s">
        <a href="/register" style="background-color: #5ed1fd;"><span>Register Now <span style="font-size: 1em;" class="animate__animated animate__tada animate__delay-3s">👋</span></span></a>
      </div>
      <p class="mt-2 text-center text-sm text-gray-600">
        to view all of our alerts!
      </p>
    </div>
    <div v-if="xhrDataCardData['signal_stream'].length < 1" class="flex flex-col items-center justify-center">
      <fingerprint-spinner
        :animation-duration="1500"
        :size="120"
        color="#0051d8"
      />
      <p class="mt-2 text-center text-xl text-gray-600">Loading...</p>
    </div>
  </div>

  <div class="max-w-md w-full space-y-8">
    <section class="dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <!-- <vAb
          @sample="(value) => candidate = value"
          :weight="{candidate1: 10, candidate2: 10, candidate3: 10, candidate4: 10}">
          <div slot="candidate1">
              <div class="mx-auto max-w-screen-sm text-center">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Losing Profits? Make Them back! Try Barpot!</h2>
                <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg underline">Send us a message in the chat for your promo code!</p>
            </div>
          </div>
          <div slot="candidate2">
              <div class="mx-auto max-w-screen-sm text-center">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Our Winrate is +80%, We'll give you the edge you need!</h2>
                <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg underline">Send us a message in the chat for your promo code!</p>
            </div>
          </div>
          <div slot="candidate3">
              <div class="mx-auto max-w-screen-sm text-center">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Having troubles making the right trades? We'll Pick them for you!</h2>
                <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg underline">Send us a message in the chat for your promo code!</p>
            </div>
          </div>
          <div slot="candidate4">
              <div class="mx-auto max-w-screen-sm text-center">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">Let's face it, trading is hard, expecially profits! Let us choose the right stocks for you!</h2>
                <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg underline">Send us a message in the chat for your promo code!</p>
            </div>
          </div>
        </vAb> -->
          
      </div>
  </section>
  </div>
</div>

<div class="min-h-screen flex flex-col bg-gray-50 py-2 px-4 sm:px-6 lg:px-8">
  <p class="mb-5 text-center text-3xl font-extrabold text-gray-600 underline">
        Recent Alerts
      </p>
<table class="w-full">
        <thead>
          <tr class="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
            <th class="px-4 py-3">Symbol</th>
            <th class="px-4 py-3">Price</th>
            <th class="px-4 py-3">Winrate</th>
            <th class="px-4 py-3">Momentum Status</th>
            <th class="px-4 py-3">Entry Price</th>
            <th class="px-4 py-3">Alert Time</th>
          </tr>
        </thead>
        <tbody v-for="symbolStatus in xhrDataCardData['signal_stream']" v-bind:key="symbolStatus.symbol + symbolStatus.lastest_trade_time + symbolStatus.entryprice">
          <StockSnippitTable
                    v-bind:key="symbolStatus.symbol + symbolStatus.timestamp"
                    v-bind:data="symbolStatus"
                    v-bind:risk_indicators="risk_indicators"
                    >{{symbolStatus}}</StockSnippitTable>
        </tbody>
      </table>
</div>
</div>
</template>

<script>

import { FingerprintSpinner } from 'epic-spinners'
import StockSnippitTable from './components/StockSnippitTable.vue'
// import vAb from 'vue-ab'

export default {
  name: 'PageChat',
  components: {
    StockSnippitTable,
    FingerprintSpinner,
  },
  data () {
      return {
          xhrDataCardData: {
            "signal_stream": [],
            "pagination": {
              "curr_page": "",
              "page_total": ""
            }
          }
      }
  },
  mounted(){
    window.location.href = "/";
    this.populateGridAsOverviewAsNonPremiumMember()
  },
  methods: {
    gotodashboard(){
        window.location.href = '/dashboard'
    },
    async populateGridAsOverviewAsNonPremiumMember(){
        let url = new URL(window.location.href);
        let c_page = url.searchParams.get("page_num")
        await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream_overview?page_num=${c_page}`, {
          method: 'GET', // or 'PUT'
          headers: {
              'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if(response.status == 403){
            return response.json()
          }
          return response.json()
        })
        .then(data => {
            this.xhrDataCardData = {
              ...this.xhrDataCardData,
              ...data,
            }
        });
    },
    }
}

</script>

<style>

.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
</style>