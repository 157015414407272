<template>
    <div class="flex justify-center">
        <button @click="paperTradingStrategyFlow.modal.show()" className="shadow-[0_4px_14px_0_rgb(0,118,255,39%)] hover:shadow-[0_6px_20px_rgba(0,118,255,23%)] hover:bg-[rgba(0,118,255,0.9)] px-2 pt-2 pb-1 bg-[#2AA7FF] rounded-md items-center text-white font-light transition duration-200 ease-linear">
          <span class="material-symbols-outlined">settings</span>
        </button>

        <div id="popup-modal" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div class="relative p-4 w-full max-w-md max-h-full">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <!-- Modal header -->
                  <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                          Strategy Settings
                      </h3>
                      <button @click="paperTradingStrategyFlow.modal.hide()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span class="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div class="p-4 md:p-5 text-center">
                      <div class="flex items-center mb-4">

                        <!-- item[n0] -->
                        <div class="flex">
                          <span id="investment-apply-button" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">
                              <span class="material-symbols-outlined text-sm">attach_money</span>
                                Capital
                              </span>
                          <div class="relative w-full">
                              <input type="number" id="investment-input"
                              class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                              :value="traderInstanceStore?.xhrDataPortfolioItem?.investment?.configuration?.initial_amount"
                              :placeholder="formatCashValue(traderInstanceStore?.xhrDataPortfolioItem?.investment?.configuration?.initial_amount)" required>
                          </div>
                        </div>

                      </div>
                      <button @click="handlePaperInvestment()" type="button" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                          Apply
                      </button>
                      <button @click="paperTradingStrategyFlow.modal.hide()" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { Modal } from 'flowbite';
import { reactive } from 'vue'
import { traderInstanceStore } from '../stores/PageBotTraderInstanceStore'
import { formatCashValue } from '../utils'

const paperTradingStrategyFlow = reactive({
  modal: null
})

const $toast = useToast();
let currPath = new URL(window.location.href);
export default {
 props: {
 },
 components: {
  },
 data(){
     return {paperTradingStrategyFlow, traderInstanceStore}
 },
 mounted(){
  const $modalElement = document.querySelector('#popup-modal');

  const modalOptions = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
          'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
          console.log('modal is hidden');
      },
      onShow: () => {
          console.log('modal is shown');
      },
      onToggle: () => {
          console.log('modal has been toggled');
      },
  };

  // instance options object
  const instanceOptions = {
    id: 'popup-modal',
    override: true
  };

  paperTradingStrategyFlow.modal = new Modal($modalElement, modalOptions, instanceOptions);

 },
 
 methods: {
  formatCashValue,
    async createPaperInvestment(){
          const payloadData = {
            amount: document.getElementById("investment-input").value,
            id: currPath.searchParams.get('portfolio_id')
          }
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/portfolio_trader/invest/paper/strategy/create`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            },
            body: JSON.stringify(payloadData)
          })
          .then(response => {
            if(response.status == 401 || response.status == 500 || response.status == 404){
              $toast.open({
                message: "Failed to update Portfolio | Code: " + response.status ,
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
            }
            return response.json()
          })
          .then(data => {
            if (data?.code == "token_not_valid" || data?.status == "portfolio_item.user_not_owner"){
              $toast.open({
                message: "Could not complete operation you are not authorized.",
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
              return
            }
              $toast.open({
                message: data?.message,
                type: data?.status == "warn" | data?.status == "error" ? 'warning': 'success',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
          });
        },
    async updatePaperInvestment(){
          const payloadData = {
            amount: document.getElementById("investment-input").value,
            id: currPath.searchParams.get('portfolio_id')
          }
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/portfolio_trader/invest/paper/strategy/update`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            },
            body: JSON.stringify(payloadData)
          })
          .then(response => {
            if(response.status == 401 || response.status == 500 || response.status == 404){
              $toast.open({
                message: "Failed to update Portfolio | Code: " + response.status ,
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
            }
            return response.json()
          })
          .then(data => {
            if (data?.code == "token_not_valid" || data?.status == "portfolio_item.user_not_owner"){
              $toast.open({
                message: "Could not complete operation you are not authorized.",
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
              return
            }
              $toast.open({
                message: data?.message,
                type: data?.status == "warn" | data?.status == "error" ? 'warning': 'success',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
          });
        },
    async handlePaperInvestment(){
      console.warn('traderInstanceStore?.xhrDataPortfolioItem?.investment?.allowed', traderInstanceStore?.xhrDataPortfolioItem?.investment?.allowed)
      if (traderInstanceStore?.xhrDataPortfolioItem?.investment?.allowed){

        this.updatePaperInvestment();
      } else {
        this.createPaperInvestment();
      }
 },
 }
}
</script>

<style scoped>

</style>