<template>
  <div class="flex flex-col relative bg-white">
  <link rel="stylesheet" href="./css/extra.css">
  <link rel="stylesheet" href="./css/SliderAnimation.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
    <!-- This example requires Tailwind CSS v2.0+ -->
<nav class="bg-gray-800">
  <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <!--
            Icon when menu is closed.

            Heroicon name: outline/menu

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <!--
            Icon when menu is open.

            Heroicon name: outline/x

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <img v-on:click="handleHome" class="block lg:hidden h-8 w-auto" src="images/logos/barpot_logo_only_w_text_alt.svg" alt="BarPot">
          <img v-on:click="handleHome" class="hidden lg:block h-8 w-auto" src="images/logos/barpot_logo_only_w_text_alt.svg" alt="BarPot">
        </div>
        <div class="hidden sm:block sm:ml-6">
          <div class="flex space-x-4">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <a href="#" class="hidden bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" aria-current="page">Dashboard</a>

            <a href="#" class="hidden text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Team</a>

            <a href="#" class="hidden text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Projects</a>

            <a href="#" class="hidden text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Calendar</a>
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

        <!-- <button class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span class="sr-only">View notifications</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
        </button> -->

        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div class="flex flex-row justify-center">
            <button v-on:click="handleAccount" class="flex mr-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded space-x-1 items-center rounded-lg hover:text-gray-500 bg-gradient-to-br"> 
              <span>Account</span>
            </button>
            
            <button v-on:click="handleSignout" class="flex bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded space-x-1 items-center rounded-lg hover:text-gray-500 bg-gradient-to-br"> 
              <span>Sign Out</span>
            </button>
          </div>

          <div class="hidden origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
            <!-- Active: "bg-gray-100", Not Active: "" -->
            <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Profile</a>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1">Settings</a>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:hidden" id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <a href="#" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Dashboard</a>

      <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>

      <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>

      <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a>
    </div>
  </div>
</nav>
  <div class="flex flex-col w-full mx-auto px-4 sm:px-6 sm:py-6 items-center">
		<div class="flex flex-row space-x-2">
			<div class="shadow-lg rounded-lg overflow-hidden">
				<div class="py-3 px-5 bg-gray-50 text-center">P/L Performance (This Week)</div>
				<div class="py-3 px-5 bg-gray-50 text-center">Total Profit Opportunity: <span class="px-2 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm"> {{parseFloat(xhrDataCardData.total_pl).toFixed(2)}}% </span></div>
				<div class="py-3 px-5 bg-gray-50 text-center">Win Rate: <span class="px-2 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm"> {{parseFloat(xhrDataCardData.win_rate).toFixed(2)}}% </span></div>
				<div class="py-3 px-5 bg-gray-50 text-center">Win Rate (including OPEN Trades): <span class="px-2 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm"> {{parseFloat(xhrDataCardData.win_rate_w_opens).toFixed(2)}}% </span></div>
				<canvas class="p-3" id="chartDoughnut"></canvas>
			</div>
			
		</div>
		<div class="shadow-lg overflow-hidden">
			<div class="py-3 px-5 bg-gray-50 text-center">** This Information is Updated Every Hour.</div>
		</div>
  </div>
  <div class="mx-3">
    <section class="font-mono">
  <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
    <div class="w-full overflow-x-auto">
      <div v-if="xhrDataCardData.detail" class="flex w-full overflow-x-auto justify-center py-8">
          <div class="flex w-24">
            <svg style="fill: indianred;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
              </svg>
          </div>
        </div>
      <table class="w-full">
        <thead>
          <tr class="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
            <th class="px-4 py-3">Symbol</th>
            <th class="px-4 py-3">Entry Price</th>
            <!-- <th class="px-4 py-3">Chance of Profit</th> -->
            <th class="px-4 py-3">Momentum Status</th>
            <th class="px-4 py-3">P/L Opportunity</th>
            <th class="px-4 py-3">Alert Time</th>
          </tr>
        </thead>
        <tbody v-for="symbolStatus in xhrDataCardData['signal_stream']" v-bind:key="symbolStatus.symbol + symbolStatus.lastest_trade_time + symbolStatus.entryprice">
          <StockSnippitTableReporting
                    v-bind:key="symbolStatus.symbol + symbolStatus.entrytime"
                    v-bind:data="symbolStatus"
                    v-bind:risk_indicators="risk_indicators"
                    >{{symbolStatus}}</StockSnippitTableReporting>
        </tbody>

        
      </table>
      <div v-if="xhrDataCardData.signal_stream.length <= 0" class="flex w-full overflow-x-auto justify-center py-4">
          <semipolar-spinner
            :animation-duration="2000"
            :size="120"
            color="#0051d8"
          />
      </div>
      <div class="flex flex-row w-full mx-auto px-4 sm:px-6 sm:py-6 justify-center">
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <a v-on:click="handleBeginPage" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-5 w-5" fill="currentColor" aria-hidden="true"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 415.1V96.03c0-17.67 14.33-31.1 31.1-31.1C49.67 64.03 64 78.36 64 96.03v131.8l171.5-156.5C256.1 54.28 288 68.66 288 96.03v131.9l171.5-156.5C480.1 54.28 512 68.66 512 96.03v319.9c0 27.37-31.88 41.74-52.5 24.62L288 285.2v130.7c0 27.37-31.88 41.74-52.5 24.62L64 285.2v130.7c0 17.67-14.33 31.1-31.1 31.1C14.33 447.1 0 433.6 0 415.1z"/></svg>
          </a>

          <a v-on:click="handlePreviousPage" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </a>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <!--
          <a href="#" aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 1 </a>
          <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 2 </a>
          <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 3 </a>
          -->
          <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"> {{xhrDataCardData["pagination"]["curr_page"]}} of {{xhrDataCardData["pagination"]["page_total"]}} </span>
          <!--
          <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 8 </a>
          <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 9 </a>
          <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 10 </a>
          -->
          <a v-on:click="handleNextPage" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </a>

          <a v-on:click="handleLastPage" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-5 w-5" fill="currentColor" aria-hidden="true"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 96.03v319.9c0 17.67-14.33 31.1-31.1 31.1C462.3 447.1 448 433.6 448 415.1V284.1l-171.5 156.5C255.9 457.7 224 443.3 224 415.1V284.1l-171.5 156.5C31.88 457.7 0 443.3 0 415.1V96.03c0-27.37 31.88-41.74 52.5-24.62L224 226.8V96.03c0-27.37 31.88-41.74 52.5-24.62L448 226.8V96.03c0-17.67 14.33-31.1 31.1-31.1C497.7 64.03 512 78.36 512 96.03z"/></svg>
          </a>
        </nav>
      </div>
        
    </div>
  </div>
</section>
</div>
</div>
</template>

<script>
import StockSnippitTableReporting from './components/StockSnippitTableReporting.vue'
import { SemipolarSpinner  } from 'epic-spinners'
export default {
  name: 'reportingDashboard',
  components: {
    StockSnippitTableReporting,
    SemipolarSpinner
  },
  data () {
      this.userData = {
        "username": "...",
        "email": "...",
        info: {
          phone_number: ""
        }
      }
      return {
      xhrDataCardDataPopular: [
        ],
      xhrDataCardDataETF: [
        ],
      xhrDataCardData: {
        "signal_stream": [],
        "pagination": {
          "curr_page": "",
          "page_total": ""
        }
      }
        ,
      risk_indicators: {
          low: true,
          high: true,
          moderate: true
      },
      userData: this.userData
      }
  },
  mounted(){
      this.refreshToken();
      this.getUser();
      setInterval(() => {
        console.log("Auto JWT refresh");
        this.refreshToken();
      }, 60000);

      this.populateGrid();
      setInterval(() => {
            let date = new Date();
            // console.log(date.getSeconds());
            if (date.getSeconds() == 1){
                this.populateGrid();
            }
        },999);
  },
  methods: {
        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => response.json())
            .then(data => {
              this.userData = data
            })
            .catch(error => console.log('error', error));
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        handleAccount(){
          window.location = "/account"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
        async populateGridAsOverviewAsNonPremiumMember(){
            let url = new URL(window.location.href);
            let c_page = url.searchParams.get("page_num")
            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream_overview?page_num=${c_page}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                this.xhrDataCardData = {
                  ...this.xhrDataCardData,
                  ...data,
                }
            });
        },
        async toggleRiskClick(){
          this.populateGrid()
        },
        async populateGrid(){
            let url = new URL(window.location.href);
            let c_page = url.searchParams.get("page_num");

            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/reporting_stream?page_num=${c_page}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                this.populateGridAsOverviewAsNonPremiumMember()
                return response.json()
              }
              return response.json()
            })
            .then(data => {					
							const dataDoughnut = {
								labels: [`Wins (${data.wins})`, `Exits (${data.loss})`, `Opens (${data.opens})`],
								datasets: [
									{
										label: "P/L Performance",
										data: [data.wins, data.loss, data.opens],
										backgroundColor: [
											"rgb(65,152,132)",
											"rgb(204,64,64)",
											"rgb(101, 143, 241)",
										],
										hoverOffset: 4,
									},
								],
							};

							const configDoughnut = {
								type: "doughnut",
								data: dataDoughnut,
								options: {},
							};

							if (window.Chart !== undefined){
								new window.Chart(
									document.getElementById("chartDoughnut"),
									configDoughnut
								);
							}

                this.xhrDataCardData = {
                  ...this.xhrDataCardData,
                  ...data,
                }
            });
        },
        onStockSubmit(event){
          if (event.keyCode === 13){
            window.location.href = `/stock?symbol=${event.target.value}`
          }
          
        },
        handlePreviousPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0){
            c_page = 0;
          }
          url.searchParams.set('page_num', (c_page - 1));
          window.location.href = url.href;
        },
        handleNextPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0 || isNaN(c_page) == true){
            c_page = 0;
          }
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.next_page);
          window.location.href = url.href;
        },
        handleBeginPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', 0);
          window.location.href = url.href;
        },
        handleLastPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.page_total);
          window.location.href = url.href;
        },
        normalizeTime(){
            console.log(this.users)
        },
        generateRandomNumber(min, max) {
            let highlightedNumber = Math.random() * (max - min) + min;
            return(highlightedNumber);
        }
    }
}

</script>

<style>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
</style>