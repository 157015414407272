<template>
  <div class="px-6 py-4 rounded-md text-lg flex flex-col items-center w-full">
        <div class="flex flex-col justify-center text-center items-center">
            <fingerprint-spinner
                :animation-duration="2000"
                :size="160"
                color="#0051d8"
            />
        </div>
        <div id="default-carousel" class="relative w-full 2xl:w-full xl:w-full lg:w-full sm:min-w-[15rem] text-center" data-carousel="slide">
          <!-- Carousel wrapper -->
          <div class="relative overflow-hidden rounded-lg h-16">
              <!-- Item 1 -->
              <div class="hidden duration-700 ease-in-out" data-carousel-item>
                  <span class="text-gray-800">Loading your data...</span>
              </div>
              <!-- Item 2 -->
              <div class="hidden duration-700 ease-in-out" data-carousel-item>
                  <span class="text-gray-800">Counting all the kittens....</span>
              </div>
              <!-- Item 3 -->
              <div class="hidden duration-700 ease-in-out" data-carousel-item>
                  <span class="text-gray-800">There seems to be alot of em!</span>
              </div>
              <!-- Item 4 -->
              <div class="hidden duration-700 ease-in-out" data-carousel-item>
                  <span class="text-gray-800">Still Working on the final touches....</span>
              </div>
              <!-- Item 5 -->
              <div class="hidden duration-700 ease-in-out" data-carousel-item>
                  <span class="text-gray-800">Almost there....</span>
              </div>
          </div>
        </div>
        <progressBar />
      </div>
</template>

<script>
import progressBar from './progressBar.vue'
import { FingerprintSpinner } from 'epic-spinners';

export default {
  components: {
    FingerprintSpinner,
    progressBar,
  },
 props: {
 },
 mounted(){
 },
 data(){
    return {
    }
 },
 methods: {

 }
 
}
</script>

<style scoped>

</style>