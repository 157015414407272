<script setup>

const onDragStart = (event, nodeType, nodeAttributeType, nodeLabel) => {
  if (event.dataTransfer) {
    console.log(event.dataTransfer)
    event.dataTransfer.setData('application/vueflow-type', nodeType)
    event.dataTransfer.setData('application/vueflow-attribute-type', nodeAttributeType)
    event.dataTransfer.setData('application/vueflow-label', nodeLabel)
    event.dataTransfer.effectAllowed = 'move'
  }
}

// const onDrag = (event, nodeType, NodeLabel) => {
//   // console.log(event)
//   if (event.dataTransfer) {
//     event.dataTransfer.setData('application/vueflow-type', nodeType)
//     event.dataTransfer.setData('application/vueflow-type', NodeLabel)
//     // console.log(event.dataTransfer)
//     event.dataTransfer.effectAllowed = 'move'
//   }
// }
</script>

<template>
  <aside class="flex text-center flex-col mt-2 my-2 overflow-y-scroll overflow-x-hidden max-h-full mostly-customized-scrollbar" style=" min-width: max-content; ">
    <div class="flex flex-col w-full mr-2 pb-8">
      <div class="font-bold underline">Attributes</div>

      <div id="section-datastream">
        <span class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Data Stream</span>
        <div class="flex flex-col space-y-2 nodes">
          <div class="vue-flow__node-input has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'stock_aggregator_node', 'stock_aggregator_node', 'Stock Data Stream')">Stock Data Stream<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip bg-white items-center text-sm p-4"><p>Historical stock information to use for back testing, investigating, or trading.</p></span></span></div>
        </div>
      </div>
      
      <div id="section-datapoints">
        <span class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Data Points</span>
        <div class="flex flex-col space-y-2 nodes">
          <!-- <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_flow_node', 'trade_rule', 'Trade Rule')">Trade Rule<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div> -->
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'stock_symbol_node', 'stock_symbol_node', 'Stock Symbol')">Stock Symbol<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>Choose from securities available on the stock market.</p></span></span></div>
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'stock_model_node', 'stock_model_node', 'Trading Model')">Trading Model<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>Choose the AI model(s) you want to process your symbol(s).</p></span></span></div>
        </div>
      </div>

      <div id="section-trading-rules" class="hidden">
        <span class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Trading Rules</span>
        <div class="flex flex-col space-y-2 nodes">
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'minimum_entry_price', 'Minimum Entry Price')">Minimum Entry Price<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'maximum_entry_price', 'Maximum Entry Price')">Maximum Entry Price<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'minimum_profit_exit_percent', 'Minimum Profit Exit Percent')">Minimum Profit Exit Percent<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'maximum_stop_loss_exit_percent', 'Maximum StopLoss Percent')">Maximum StopLoss Percent<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
        </div>
      </div>
      
      <div id="section-trigger" class="hidden">
        <span class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Triggers</span>
        <div class="flex flex-col space-y-2 nodes">
          <div class="vue-flow__node-input has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_flow_node', 'standard_flow_node', 'Automated Trader')">Automated Trader (TBD)<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-input has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'trade_execute_node', 'trade_execute_node', 'Execute Trade')">Execute Trade<span class="tooltip-wrapper shadow-[0_4px_14px_0_rgb(0,0,0,10%)] hover:shadow-[0_6px_20px_rgba(93,93,93,23%)] px-8 py-2 bg-[#fff] text-[#696969] rounded-md font-light transition duration-200 ease-linear"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
        </div>
      </div>

    </div>
  </aside>
</template>

<style scoped>
.tooltip {
    display: flex;
    position: relative;
    width: 12em;
    height: 100%;
    /* margin-left: -76px; */
    color: #222;
    text-align: center;
    border-radius: 8px;
}

.has-tooltip:hover .tooltip-wrapper {
    position: absolute;
    visibility: visible;
    opacity: 1;
    /* top: 30px; */
    left: auto;
    /* margin-left: -76px; */
}
</style>