<template>
    <div class="flex">
    <!-- Modal toggle -->
    <button data-modal-target="authentication-modal" data-modal-toggle="authentication-modal" class="block hidden text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
    Toggle modal (ID:authentication-modal)
    </button>

    <!-- Main modal -->
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-md max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Sign in to our platform
                    </h3>
                    <button @click="store.targetModal.hide()" type="button" class="hidden end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5">
                    <form id="login_form" class="space-y-4" method="POST" @submit="onFormSubmit">
                        <div>
                            <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your username</label>
                            <input type="username" name="username" id="username" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white placeholder:italic" placeholder="username here..." required>
                        </div>
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                            <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required>
                        </div>
                        <div class="flex justify-between">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input id="remember" type="checkbox" value="" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800">
                                </div>
                                <label for="remember" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                            </div>
                            <a href="forget_password" class="text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
                        </div>
                        <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
                        <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
                            Not registered? <a href="/register" class="text-blue-700 hover:underline dark:text-blue-500">Create account</a>
                        </div>
                    </form>
                </div>

                <div class="hidden px-6 py-2 bg-purple-700 rounded-lg dark:bg-gray-800 mb-2 md:py-6 md:px-12 lg:py-8 lg:px-16 animate__animated animate__shakeY">
                    <div class="flex flex-col animate__animated animate__shakeY animate__delay-5s">
                        <div class="">
                            <h2 class="text-2xl font-extrabold leading-8 tracking-tight text-white sm:text-3xl sm:leading-9">Psst... Came here from Discord?</h2>
                            <p class="max-w-3xl mt-3 text-lg leading-6 text-indigo-200 text-center">We noticed you mysteriously appeared 🤔, enter your discord username below
                                to get access 😉</p>
                        </div>
                        <div class="mt-2 sm:w-full sm:max-w-md xl:mt-2">
                            <div class="mt-3 rounded-md shadow sm:mt-0 sm:flex-shrink-0">
                                
                                <input type="text" placeholder="👉 discord username..."
                                    class="flex items-center justify-center w-full px-5 py-3 mb-2 placeholder:italic text-base font-medium leading-6 transition duration-150 ease-in-out border border-transparent rounded-md"
                                    ref="discordUsernameEntry">
                                
                                <button
                                    class="flex items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-purple-500 border border-transparent rounded-md hover:bg-purple-400 focus:outline-none focus:bg-purple-400"
                                    @click="onDiscordSubmit"> Submit </button></div>
                            <p class="mt-3 text-sm leading-5 text-indigo-200 italic">You'll only get access to this symbol information or symbols alerts
                                in your originating discord</p>
                        </div>
                    </div>
                </div>

                <div class="px-6 py-2 bg-purple-700 rounded-lg dark:bg-gray-800 mb-2 md:py-6 md:px-12 lg:py-8 lg:px-16 animate__animated animate__shakeY">
                    <div class="flex flex-col animate__animated animate__shakeY animate__delay-5s">
                        <div class="">
                            <h2 class="text-2xl font-extrabold leading-8 tracking-tight text-white sm:text-3xl sm:leading-9">Don't have a account?... Enter your email to continue viewing!</h2>
                            <p class="max-w-3xl mt-3 text-lg leading-6 text-indigo-200 text-center">You'll get access to view this stock and a select group of others! 😉</p>
                        </div>
                        <div class="mt-2 sm:w-full sm:max-w-md xl:mt-2">
                            <div class="mt-3 rounded-md shadow sm:mt-0 sm:flex-shrink-0">
                                <form action="">
                                    <input type="email"  name="email" placeholder="👉 Email here..."
                                        class="flex items-center justify-center w-full px-5 py-3 mb-2 placeholder:italic text-base font-medium leading-6 transition duration-150 ease-in-out border border-transparent rounded-md"
                                        ref="discordUsernameEntry">
                                </form>
                                <button
                                    class="flex items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-purple-500 border border-transparent rounded-md hover:bg-purple-400 focus:outline-none focus:bg-purple-400"
                                    @click="onDiscordSubmit"> Close </button></div>
                            <p class="mt-3 text-xs leading-5 text-indigo-200 italic">You'll only get access to this stock information and future email notifications in our newsletter about stock alerts and important information!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 

    </div>
</template>

<script>
import { Modal } from 'flowbite';
import { reactive } from 'vue'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const store = reactive({
    targetModal: null,
    userData: null
})

export default {
 props: {
   risk_indicators: Object,
   data: Object
 },
 async mounted(){
    await this.getUser()
    console.log("Init nonReg")
    // Init Identify
    this.identifyUser()

    const $targetEl = document.getElementById('authentication-modal');

    // options with default values
    const options = {
        placement: 'bottom-right',
        backdrop: 'dynamic',
        backdropClasses:
            'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
        closable: true,
        onHide: () => {
            console.log('modal is hidden');
        },
        onShow: () => {
            console.log('modal is shown');
        },
        onToggle: () => {
            console.log('modal has been toggled');
        },
    };

    // instance options object
    const instanceOptions = {
        id: 'authentication-modal',
        override: true
    };

    store.targetModal = new Modal($targetEl, options, instanceOptions);
    console.log("Init nonReg")
    const targetDiv = document.querySelector('.k-line-chart-container');
    let currUsername = localStorage.getItem("username")
    
    if (currUsername == null){
        let interactAmount = 0
        
        const interactHandler = (interactThreshold, resetInteractAmountAfterTrigger) => {
            interactAmount = interactAmount + 1;
            let existingDiscordUsername = localStorage.getItem("barpot_discord_username")
            console.log("interactAmount", targetDiv, interactAmount)
            if(interactAmount >= interactThreshold && existingDiscordUsername == null){
                store.targetModal.show()
                if (resetInteractAmountAfterTrigger){
                    interactAmount = 0
                }
            }
            
            // Init Identify
            this.identifyUser()
        }

        targetDiv.addEventListener('click', () => interactHandler(2, false));
        targetDiv.addEventListener('wheel', () => interactHandler(10, true));
        targetDiv.addEventListener('touchmove', () => interactHandler(30, true));
    }

 },
 data(){
     return {store}
 },
 methods: {
    identifyUser(){
        console.log("st:po:idt")
        let existingDiscordUsername = localStorage.getItem("barpot_discord_username")
        let currUsername = localStorage.getItem("username")
        let identifyDict = {}
        
        if(currUsername !== null){
            identifyDict["username"] = currUsername
        }
        if(existingDiscordUsername !== null){
            identifyDict["discordUsername"] = existingDiscordUsername
        }

        // Identify
        if(Object.keys(identifyDict).length > 0){
            console.log("ed:suss:po:idt")
            window.posthog.identify(
                existingDiscordUsername,  // Replace 'distinct_id' with your user's unique identifier
                identifyDict // optional: set additional user properties
            );
        }
    },
    onDiscordSubmit(){
        let enteredDiscordUsername = this.$refs.discordUsernameEntry.value
        console.log(enteredDiscordUsername)
        if(enteredDiscordUsername){
            localStorage.setItem("barpot_discord_username", enteredDiscordUsername)
            store.targetModal.hide()
            this.identifyUser()
            $toast.open({
                message: "🚀 Nice! You now have access!",
                type: 'default',
                duration: 10000,
                position: 'bottom'
            });
        }
    },
    async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => {
              if(response.status == 401){
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                if (data?.code == "token_not_valid"){
                    return
                }
                
                store.userData = data
                localStorage.setItem("username", data["info"]["username"])
            })
            .catch(error => console.log('error', error));
        },
   async onFormSubmit(e){
        e.preventDefault();
      this.isAttemptingAuth = true
      var formdata = new FormData(document.querySelector('form[id="login_form"]'));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/`, requestOptions)
        .then(response => {
          if(response.status == 401){
            this.isAttemptingAuth = false
            alert("Invaild login Creditials. Please try again!")
          }
          return response.json()
        })
        .then(result => {
          if (result.access && result.access){
              localStorage.setItem("barpot_jwt_access", result.access)
              localStorage.setItem("barpot_jwt_refresh", result.refresh)
              window.location.reload()
            }
        })
        .catch(error => console.log('error', error));

      e.preventDefault();
      await this.getUser()
    }
 }
 
}
</script>

<style scoped>
.Header {
  margin-bottom: 15px;
}
.hidden-as-sec-look {
  opacity: 0.3;
  -webkit-text-security: disc;
}


@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}
</style>