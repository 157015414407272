<template>
    <div class="flex justify-center w-full">
      <!-- Chart widget -->
      <div class="flex flex-col w-full col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
          <header class="flex flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col px-5 py-4 border-b border-gray-100 flex items-center justify-between">
              <div class="flex">
                <h2 class="font-semibold text-gray-800 text-xl">Paper Trading - Analytics</h2>
              </div>
              <div class="flex space-x-2 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-4 sm:pt-4">
                <paperTradingFlow />
                <button id="dropdownToggleButton" data-dropdown-toggle="dropdownToggle" class="flex items-center shadow-[0_4px_14px_0_rgb(0,118,255,39%)] hover:shadow-[0_6px_20px_rgba(0,118,255,23%)] hover:bg-[rgba(0,118,255,0.9)] px-8 py-2 bg-[#008FFB] rounded-md text-white font-light transition duration-200 ease-linear">
                    Filter Strategies <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                                  </svg>
                  </button>
              </div>
          </header>
          <div class="px-5 py-1">
            <div class="flex justify-center md:flex-row sm:flex-col 2xl:border-b-0 xl:border-b-0 lg:border-b-0 md:border-b-2 sm:border-b-2">
              <div :class="((this.propData?.status == 'ok' && store.chart.series.filter(item => item.data.length > 0).length == 0) || !this.propData?.status) ? 'flex flex-wrap blur-state-loading': 'flex flex-wrap'">
                  <!-- Current Value -->
                  <div class="flex items-center py-2">
                      <div class="mr-5">
                          <div class="flex items-center">
                              <div class="text-3xl font-bold text-gray-800 mr-2">{{formatCashValue(store.TradingAccountInfo?.data?.equity)}}</div>
                          </div>
                          <div class="flex items-center space-x-4">
                            <div class="text-sm text-gray-500">Current Value</div>
                            <div class="flex inline-block ripple-container">
                              <span class="ripple pinkBg"></span>
                              <span class="ripple pinkBg"></span>
                              <span class="ripple pinkBg"></span>
                            </div>
                          </div>
                      </div>
                      <div class="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
                  </div>
                  <!-- Return % -->
                  <div class="flex items-center py-2">
                      <div class="mr-5">
                          <div class="flex items-center">
                              <div class="text-3xl font-bold text-green-500 mr-2">{{formatFixedNumber(store.TradingAccountInfo?.data?.account_total_return_percent, prepend="", postpend="%", decimal=2)}}</div>
                          </div>
                          <div class="text-sm text-gray-500">Return %</div>
                      </div>
                      <div class="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
                  </div>
                  <!-- Return Amount -->
                  <div class="flex items-center">
                      <div>
                          <div class="flex items-center">
                              <div class="text-3xl font-bold text-gray-800 mr-2">{{store.TradingAccountInfo?.data?.account_total_return ? (store.TradingAccountInfo?.data?.account_total_return > 0 ? '+' + formatCashValue(store.TradingAccountInfo?.data?.account_total_return): formatCashValue(store.TradingAccountInfo?.data?.account_total_return)): ''}}</div>
                          </div>
                          <div class="text-sm text-gray-500">Return Amount</div>
                      </div>
                  </div>
              </div>
              
              <div class="flex flex-wrap items-center md:pt-0 sm:pt-4">
                <!-- Dropdown menu -->
                <div id="dropdownToggle" class="z-20 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-fit dark:bg-gray-700 dark:divide-gray-600">
                    <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownToggleButton">
                      <li v-for="strategyId in Object.keys(this.propData?.portfolio)" :key="strategyId">
                        <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                          <label class="inline-flex items-center w-full cursor-pointer">
                            <input @click="handleToggleStrategy(strategyId)" :checked="(store.userFilter?.[strategyId]?.enabled ? store.userFilter?.[strategyId]?.enabled: true)" type="checkbox" value="" class="sr-only peer">
                            <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-blue-600"></div>
                            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{this.propData?.portfolio[strategyId].portfolio_meta.name}}</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                </div>
                </div>
            </div>
          </div>
          <!-- Chart built with Chart.js 3 -->
          <div class="flex-grow w-full h-[32em] p-8">
            <div v-if="(this.propData?.status == 'ok' && store.chart.series.filter(item => item.data.length > 0).length == 0)" class="bottom-0 left-0 right-0 top-0 grid place-items-center">
              <div class="absolute flex flex-col items-center pt-24">
                <h2 class="font-semibold text-gray-800 text-xl">No Paper Trading data to Present</h2>
                <h2 class="font-semibold text-gray-800 text-base">Create a Paper Trading Account and Start Investing!</h2>
              </div>
            </div>
              <div id="labels-chart" :class="(this.propData?.status == 'ok' && store.chart.series.filter(item => item.data.length > 0).length == 0) ? 'blur-state-loading': ''"></div>
              
          </div>
          
      </div>
    </div>
</template>

<script>
import _ from 'lodash';
import paperTradingFlow from './paperTradingFlow.vue';
import { initFlowbite } from 'flowbite'
import {formatCashValue, formatFixedNumber, booleanToggle} from '../utils.js'
import { reactive } from 'vue'
import { traderDashboardStore } from '../stores/PageBotTraderDashboardStore'

const store = reactive({
  chart: {
    ref: null,
    series: []
  },
  TradingAccountInfo: {loaded: false},
  userFilter: {

  }
})

export default {
 props: {
  propData: Object,
 },
 components: {
  paperTradingFlow
  },
  watch: {
    propData: function(newVal, oldVal) { // watch it
      console.log('Prop propData changed: ', newVal, ' || was: ', oldVal)
      if(this.propData !== null){
        this.populateChart()
      }
    },
  },
 data(){
     return {store, traderDashboardStore}
 },
 mounted(){
  initFlowbite();
  this.getTradingAccountInfo();

  // Auto Update the Info
  setInterval(async() => {
    await this.getTradingAccountInfo();
  }, 60000);
 },
 
 methods: {
  formatCashValue,
  formatFixedNumber,
  async getTradingAccountInfo(){
    let requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
        }
      };
    await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/portfolio_trader/info/`, requestOptions)
            .then(response => response.json())
            .then(data => {
              store.TradingAccountInfo = data
              store.TradingAccountInfo.loaded = true;
            })
            .catch(error => console.log('error', error));
  },
  handleToggleStrategy(strategyId){
    if (store.userFilter[strategyId]){
       store.userFilter[strategyId] = {
          enabled: booleanToggle(store.userFilter[strategyId].enabled)
        }
    } else {
      store.userFilter[strategyId] = {enabled: false}
    }
    store.chart.ref.destroy();
    this.populateChart();
  },
  populateChart() {
  // data format = [timestamp, value] [[1486944000000, 40],....]
  let allSeries = _.compact(Object.keys(this.propData?.portfolio).map(portfolioId => {
      if(Object.keys(store.userFilter).indexOf(portfolioId) > -1){
        if (!store.userFilter[portfolioId].enabled){
          return
        }
      }

      let portfolio_timestamps = this.propData?.portfolio[portfolioId]?.investment?.trade_journal?.analysis?.sell_percent_return_change?.cumulative_pct_timestamp.map(tstamp => tstamp * 1000)
      let portfolio_values = this.propData?.portfolio[portfolioId]?.investment?.trade_journal?.analysis?.sell_percent_return_change?.cumulative_pct.map(cval => Number(cval).toFixed(2))
      let serie_data = _.zip(portfolio_timestamps,
                            portfolio_values)
      return {
        name: this.propData?.portfolio[portfolioId]?.portfolio_meta.name,
        id: portfolioId,
        data: serie_data
      }
  }))


this.store.chart.series = allSeries;
    var options = {
          series: allSeries,
          chart: {
          type: 'line',
          height: '100%',
          width: '100%',
          stacked: false,
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            autoSelected: 'pan' 
          },
          events: {
            selection: function (chart, e) {
              console.log(new Date(e.xaxis.min))
            }
          },
        },
        colors: ['#008FFB', '#3CC157', '#e4c8a3', '#FCBC0F'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: Array.from({length: allSeries.length}, () => 2)
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        },
        grid: {
          strokeDashArray: 2
        },
        markers: {
          // size: 2,
          // shape: "circle",
          // radius: 2,
          // strokeColors: ['#008FFB', '#00E396', '#CED4DC'],
          showNullDataPoints: false,
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: true,
            x: {
              show: false,
            },
          },
          labels: {
            style: {
              colors: '#9ca3af',
              fontSize: '13px',
              fontFamily: 'Inter, ui-sans-serif',
              fontWeight: 400
            }
          }
        },
        yaxis: {
          labels: {
            align: 'left',
            minWidth: 0,
            maxWidth: 140,
            style: {
              colors: '#9ca3af',
              fontSize: '13px',
              fontFamily: 'Inter, ui-sans-serif',
              fontWeight: 400
            },
            formatter: (value) => `${value}%`
          }
        },
        };

    if (typeof window.ApexCharts !== 'undefined') {
        const chart = new window.ApexCharts(document.getElementById("labels-chart"), options);
        store.chart.ref = chart
      
      chart.render();
      document.getElementById("labels-chart").addEventListener('wheel', function(event) {
        event.preventDefault(); // Prevent scrolling the page

        // Calculate the relative position of the mouse on the chart
        var chartRect = chart.w.globals.dom.baseEl.getBoundingClientRect();
        var mouseX = (event.clientX - chartRect.left) / chartRect.width;

        var currentMinX = chart.w.globals.minX;
        var currentMaxX = chart.w.globals.maxX;
        var totalX = currentMaxX - currentMinX;

        // Determine zoom factor
        var zoomFactorIn = 0.9; // Adjust this value as needed for zooming in
        var zoomFactorOut = 0.9; // Significantly larger factor for zooming out
        var zoomRange;

        var newMinX, newMaxX;
        if (event.deltaY < 0) {
            // Zoom In
            zoomRange = zoomFactorIn * totalX;
            var midPoint = currentMinX + mouseX * totalX;
            newMinX = midPoint - zoomRange / 2;
            newMaxX = midPoint + zoomRange / 2;
        } else {
            // Zoom Out
            zoomRange = zoomFactorOut * totalX;
            newMinX = currentMinX - zoomRange / 2;
            newMaxX = currentMaxX + zoomRange / 2;
        }

        // Constrain within original chart bounds
        newMinX = Math.max(newMinX, chart.w.globals.initialMinX);
        newMaxX = Math.min(newMaxX, chart.w.globals.initialMaxX);

        // Apply zoom if valid
        if (!isNaN(newMinX) && !isNaN(newMaxX) && newMinX < newMaxX) {
            chart.zoomX(newMinX, newMaxX);
        }
    });
    }
  },
 }
}
</script>

<style scoped>
.blur-state-loading {
  filter: blur(3px);
}

.pinkBg {
    background-color: #0e9f6e!important;
    background-image: linear-gradient(90deg, #3CC157, rgb(98, 255, 0));
}

.ripple-container{
  position: relative;
  height: 0;
  left: 0;
  top: 0;
  opacity: 0;
  animation: ripple-b2f4b3f0 1.8s infinite;
}

.ripple{
    position:absolute;
    width:160px;
    height:160px;
    left:50%;
    top:50%;
    opacity:0;
    margin:-80px 0 0 -80px;
    border-radius:100px;
    -webkit-animation:ripple 1.8s infinite;
    animation:ripple 1.8s infinite
}

@-webkit-keyframes ripple{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.5);
        transform:scale(0.5)
    }
}
@keyframes ripple{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.5);
        transform:scale(0.5)
    }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2){
    animation-delay:.3s;
    -webkit-animation-delay:.3s
}
.intro-banner-vdo-play-btn .ripple:nth-child(3){
    animation-delay:.6s;
    -webkit-animation-delay:.6s
}

.ripple:nth-child(2){
    animation-delay:.3s;
    -webkit-animation-delay:.3s
}
.ripple:nth-child(3){
    animation-delay:.6s;
    -webkit-animation-delay:.6s
}

</style>