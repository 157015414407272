<script setup>
import {defineProps } from 'vue';
// import ChartType from "./chart/ChartType.vue";
import MainChart from "./chart/MainChart.vue";
// import Theme from "./chart/Theme.vue";
// import Language from "./chart/Language.vue";
// import YAxis from "./chart/YAxis.vue";
// import Tooltip from "./chart/Tooltip.vue";
// import Timezone from "./chart/Timezone.vue";
// import Overlay from "./chart/Overlay.vue";
// import LoadMore from "./chart/LoadMore.vue";
// import Update from "./chart/Update.vue";

const props = defineProps(['symbol'])
console.log(props)

let currPath = new URL(window.location.href);
let passingSymbol = currPath.searchParams.get("symbol");
</script>

<template>
  <div id="app" class="app">
    <MainChart :propSymbol="passingSymbol"/>
  </div>
</template>

<style scoped>
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f5;
}
div,
p {
  box-sizing: border-box;
}
p {
  margin: 0;
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 98%;
  height: 40vw;
  padding: 16px 6px 16px 16px;
}
</style>