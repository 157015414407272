import { reactive } from "vue";

export const traderDashboardStore = reactive({
  xhrDataCardDataPopular: [],
  xhrDataCardDataETF: [],
  xhrDataCardData: {
    signal_stream: [],
    pagination: {
      curr_page: "",
      page_total: "",
    },
  },
  risk_indicators: {
    low: true,
    high: true,
    moderate: true,
  },
  userData: {
    subscription_status: "",
    username: "...",
    email: "...",
    info: {
      phone_number: "",
    },
  },
  http_portfolio_agg: {
    portfolio: {},
  },
  expanded: {},
  parameterVisConfig: {
    name: {
      display: false,
      name: "Name",
      formatter: (string) => string,
    },
    max_entryprice: {
      display: true,
      name: "(Max) Entry Price",
      formatter: (string) => "$" + string,
    },
    min_entryprice: {
      display: true,
      name: "(Min) Entry Price",
      formatter: (string) => "$" + string,
    },
    neural_model_winrate_precent: {
      display: true,
      name: "Neural Winrate",
      formatter: (string) => string + "%",
    },
    start_epoch: {
      display: false,
      name: "start_epoch",
      formatter: (string) => string,
    },
    winrate_precent: {
      display: true,
      name: "Alert Confidence",
      formatter: (string) => string + "%",
    },
    win_rate_pct: {
      display: true,
      name: "Overall Winrate",
      formatter: (string) => string + "%",
    },
  },
});
