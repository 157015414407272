<script setup>
import {defineProps } from 'vue';
import { initFlowbite } from 'flowbite'
import { Handle, Position, useVueFlow} from '@vue-flow/core'
import { onMounted } from 'vue'
import { reactive } from 'vue'

const { updateNodeData } = useVueFlow()

const store = reactive({
    xhrDataStockSearch: {data: [], timestamp: null},

    // {"symbol": {"checked": true}}
    isAutoSelectModels: false,
})

const props = defineProps(['label', 'id', 'data'])
if (props.data.configuration === undefined){
  updateNodeData(props.id, { configuration: {} })
}

console.warn("88888888888888888", props)
const handleUserSelectedSymbol = (symbol) => {
      let copyNodeConfiguration = {...props.data.configuration}
      delete copyNodeConfiguration[symbol]
      updateNodeData(props.id, { configuration: copyNodeConfiguration })
    }
    const addSymbolAsSelected = (event, symbol) => {
      let copyNodeConfiguration = {...props.data.configuration}
        if (Object.keys(copyNodeConfiguration).indexOf(symbol) > -1){
            copyNodeConfiguration[symbol].checked = event.target.checked
        } else {
            copyNodeConfiguration[symbol] = {"checked": true, "symbol": symbol}
        }
        updateNodeData(props.id, { configuration: copyNodeConfiguration })
    }
    const getAllItem = (data) => {
        return data.filter(d => Object.keys(props.data.configuration ? props.data.configuration : {}).indexOf(d.symbol) === -1)
    }
    const onPressSymbolSearch = async (event) => {
        console.log(store)
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };
          fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stock_search?keyword=${event.target.value}`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              if (store.xhrDataStockSearch.timestamp !== null){
                if (respData.timestamp > store.xhrDataStockSearch.timestamp){
                  store.xhrDataStockSearch = respData
                }
              } else {
                store.xhrDataStockSearch = respData
              }
              return
            })
            .catch(error => console.log('error', error));
        }

    onMounted(() => {
    initFlowbite();
})
</script>

<template>
<div class="node-container">
  <Handle type="target" :position="Position.Top" />
  <div class="mt-2 mb-2 text-base font-medium p-0">{{ props.label }}</div>
  <div class="flex">
        <button  :id="'modelSelection' + props.id" :data-dropdown-toggle="'modelSelectiondropdownSearch' + props.id" data-dropdown-placement="bottom" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 z-30" type="button">
            <div :class="Object.keys(props.data.configuration ? props.data.configuration : {}).length > 0 ? 'grid grid-cols-3 gap-2': 'flex'">
              <span v-if="Object.values(props.data.configuration ? props.data.configuration : {}).filter(item => item?.checked).length == 0">Select Symbols</span>
              <span v-for="(item) in Object.values(props.data.configuration ? props.data.configuration : {}).filter(item => item?.checked)" :key="item.symbol" class="flex">
                {{item.symbol}}
              </span>
            </div>
            <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg></button>
              <!-- Dropdown menu -->
              <div :id="'modelSelectiondropdownSearch' + props.id" class="z-10 w-fit hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                  

                <div class="flex items-center my-2 mx-4">   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"/>
            </svg>
        </div>
        <input type="text" @change="onPressSymbolSearch" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Symbols" required />
    </div>
    <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button>
</div>


                  <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="modelSelection">
                    <li v-for="(item, index) in getAllItem(store.xhrDataStockSearch?.data)" :key="item.symbol + index" class="my-2">
                      <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <button :id="'modelSelectioncheckbox-item-button' + index" @click="addSymbolAsSelected($event, item.symbol)" type="button" value="" class="w-full h-10 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label :for="'checkbox-item-' + index" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{item.symbol}}</label>
                        </button>
                        <button :id="'modelSelectioncheckbox-item-plus' + index" @click="addSymbolAsSelected($event, item.symbol)" type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm ml-2 px-2.5 py-0.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                          <span class="material-symbols-outlined text-2xl font-bold "> add </span></button>
                      </div>
                    </li>
                    <div v-if="store.xhrDataStockSearch.data.length <= 0 && Object.keys(props.data.configuration ? props.data.configuration : {}).length == 0" class="flex flex-col h-full items-center justify-between">
                        <hr class="w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                        <span class="material-symbols-outlined mt-2" style=" font-size: 5em; ">find_in_page</span>
                        <span class="text-base">Find Stocks and Securities</span>
                        <hr class="" />
                    </div>
                    <div v-if="store.xhrDataStockSearch.data.length >= 0 && getAllItem(store.xhrDataStockSearch?.data).length == 0" class="flex flex-col h-full items-center justify-between">
                        <hr class="w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                        <span class="material-symbols-outlined mt-2" style=" font-size: 5em; ">redo</span>
                        <span class="text-base">Search to add more symbols</span>
                        <hr class="" />
                    </div>
                    
                  </ul>
                  
                  <div v-if="Object.keys(props.data.configuration ? props.data.configuration : {}).length > 0" class="flex flex-col h-full w-full items-center p-3 text-sm font-medium border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:text-green-500">
                      <div class="flex flex-row items-center space-x-2">
                        <label for="ai-picks-checkbox" class="font-medium text-gray-900 dark:text-gray-300">Selected</label>
                        <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1v3m5-3v3m5-3v3M1 7h7m1.506 3.429 2.065 2.065M19 7h-2M2 3h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm6 13H6v-2l5.227-5.292a1.46 1.46 0 0 1 2.065 2.065L8 16Z"></path>
                        </svg>
                      </div>
                      <hr class="w-full mt-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                    <!-- <div class="flex items-center h-5">
                          <button id="modelSelectionai-picks-checkbox" :checked="store.isAutoSelectModels" @change="store.isAutoSelectModels = $event.target.checked" aria-describedby="ai-picks-checkbox-text" type="button" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></button>
                      </div>
                      <div class="ml-2 text-sm">
                          <div class="flex flex-row items-center space-x-2">
                            <label for="ai-picks-checkbox" class="font-medium text-gray-900 dark:text-gray-300">Model Selection</label>
                            <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1v3m5-3v3m5-3v3M1 7h7m1.506 3.429 2.065 2.065M19 7h-2M2 3h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm6 13H6v-2l5.227-5.292a1.46 1.46 0 0 1 2.065 2.065L8 16Z"></path>
                            </svg>
                          </div>
                          <p id="modelSelectionai-picks-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">Automatically select your models.</p>
                      </div> -->

                      <div class="flex flex-col w-full items-center space-x-2">
                            
                            <ul class="h-full w-full px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="modelSelection">
                              <li v-for="existingItem in Object.keys(props.data.configuration ? props.data.configuration : {})" :key="existingItem.symbol + 'user-selected' + props.data.configuration[existingItem].symbol" class="w-full my-2">
                                <div class="flex items-center w-full rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                  <button :id="'modelSelectionuser-selected-checkbox-item-' + props.data.configuration[existingItem].symbol" @click="handleUserSelectedSymbol(existingItem)" type="button" :checked="props.data.configuration[existingItem].checked" class="w-full h-10 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label :for="'user-selected-checkbox-item-' + props.data.configuration[existingItem].symbol" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{props.data.configuration[existingItem].symbol}}</label>
                                  </button>
                                  <button :id="'modelSelectionuser-selected-checkbox-delete-item-' + props.data.configuration[existingItem].symbol" @click="handleUserSelectedSymbol(existingItem)" type="button" class="focus:outline-none text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm ml-2 px-2.5 py-0.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                    <span class="material-symbols-outlined text-2xl "> delete_forever </span></button>
                                </div>
                              </li>
                            </ul>
                          </div>
                  </div>
              </div>
    </div>
  
  <Handle type="source" :position="Position.Bottom" />
  </div>
</template>

<style>
.vue-flow__handle-left {
  display: none;
}

.vue-flow__handle-right {
  display: none;
}

.vue-flow__node-stock_symbol_node {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  width: 250px;
  font-size: 12px;
  text-align: center;
  color: var(--vf-node-text);
  cursor: grab;
  transform-origin: 0 0;
  box-sizing: border-box;
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);

  background: var(--vf-node-bg);
  border-color: var(--vf-node-color);
  outline: none;
  border: 1px solid var(--vf-node-color);
  box-shadow: 0 0 0 0.5px var(--vf-box-shadow);
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);
  background: var(--vf-node-bg);
}

.vue-flow__node-stock_symbol_node > .node-container > .vue-flow__handle-top {
  width: 10px;
  height: 10px;
}

.vue-flow__node-stock_symbol_node > .node-container > .vue-flow__handle-bottom {
  width: 10px;
  height: 10px;
}
</style>