<script setup>

const onDragStart = (event, nodeType, nodeAttributeType, nodeLabel) => {
  if (event.dataTransfer) {
    console.log(event.dataTransfer)
    event.dataTransfer.setData('application/vueflow-type', nodeType)
    event.dataTransfer.setData('application/vueflow-attribute-type', nodeAttributeType)
    event.dataTransfer.setData('application/vueflow-label', nodeLabel)
    console.log(event.dataTransfer)
    event.dataTransfer.effectAllowed = 'move'
  }
}

// const onDrag = (event, nodeType, NodeLabel) => {
//   // console.log(event)
//   if (event.dataTransfer) {
//     event.dataTransfer.setData('application/vueflow-type', nodeType)
//     event.dataTransfer.setData('application/vueflow-type', NodeLabel)
//     // console.log(event.dataTransfer)
//     event.dataTransfer.effectAllowed = 'move'
//   }
// }
</script>

<template>
  <aside class="flex text-center flex-col mt-2 my-2 overflow-y-scroll overflow-x-hidden max-h-full mostly-customized-scrollbar" style=" min-width: max-content; ">
    <div class="flex flex-col w-full mr-2 pb-8">
      <div class="font-bold underline">Attributes</div>

      <div id="section-datastream">
        <span href="/product/algo_creator#profiler" class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Data Points</span>
        <div class="flex flex-col space-y-2 nodes">
          <div class="vue-flow__node-input has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'stock_data_aggregator', 'stock_data_stream', 'Stock Data Stream')">Stock Data Stream<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
        </div>
      </div>

      <div id="section-datapoints">
        <span href="/product/algo_creator#profiler" class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Data Points</span>
        <div class="flex flex-col space-y-2 nodes">
          <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_node', 'close_price', 'Close Price')">Close Price<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_node', 'open_price', 'Open Price')">Open Price<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The open price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_node', 'low_price', 'Low Price')">Low Price<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The low price for a stock postion.</p></span></span></div>
          
          <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_node', 'macd_histogram', 'MACD Histogram')">MACD Histogram<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_node', 'macd_signal', 'MACD Signal')">MACD Signal<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_node', 'macd_values', 'MACD Values')">MACD Values<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
        </div>
      </div>

      <div id="section-filters">
        <span href="/product/algo_creator#profiler" class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Filters</span>
        <div class="flex flex-col space-y-2 nodes">
          <div class="vue-flow__node-input has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'data_windows_size', 'Data Windows Size')">Data Windows Size<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-default has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'win_precent_threshold', 'Win Precent Threshold')">Win Precent Threshold<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'stop_loss_precent', 'Stop Loss Precent')">Stop Loss Precent<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'time_span_window', 'Calculation Time Span Window')">Calculation Time Span Window<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
          <div class="vue-flow__node-output has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'standard_input_node', 'desired_profit_precent', 'Desired Profit Precent')">Desired Profit Precent<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
        </div>
      </div>
      
      <div id="section-trigger">
        <span href="/product/algo_creator#profiler" class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300">Triggers</span>
        <div class="flex flex-col space-y-2 nodes">
          <div class="vue-flow__node-input has-tooltip" :draggable="true" @dragstart="onDragStart($event, 'trigger_node', 'trigger_model_complete', 'Model Complete')">Model Complete<span class="tooltip-wrapper"><span class="flex tooltip items-center text-sm p-4"><p>The close price for a stock postion.</p></span></span></div>
        </div>
      </div>

    </div>
  </aside>
</template>
