<template>
<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <div>
      <img class="mx-auto h-12 w-auto" src="/images/logos/barpot_logo_only_w_text.svg" alt="Barpot">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Reset Your Password
      </h2>
    </div>
    <div v-if="isAttemptingAuth == true" class="flex flex-col items-center justify-center">
      <fingerprint-spinner
        :animation-duration="1500"
        :size="120"
        color="#0051d8"
      />
      <p class="mt-2 text-center text-xl text-gray-600">Loading...</p>
    </div>
    <form v-if="isAttemptingAuth == false" id="login_form" class="mt-8 space-y-6" method="POST" @submit="onFormSubmit">
      <!-- <div>
          <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
          <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="">
      </div> -->
      <div>
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
          <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
      </div>
      <div>
          <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
          <input type="password" name="confirm_password" id="confirm_password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
      </div>
      <div class="flex items-start">
          <div class="flex items-center h-5">
            <input id="newsletter" aria-describedby="newsletter" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
          </div>
          <div class="ml-3 text-sm">
            <label for="newsletter" class="font-light text-gray-800 dark:text-gray-300">I accept the <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="/terms-and-conditions">Terms and Conditions</a></label>
          </div>
      </div>
      <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Reset passwod</button>
    </form>
  </div>
</div>
</template>

<script>

import { FingerprintSpinner } from 'epic-spinners'

export default {
  name: 'PageLogin',
  components: {
    FingerprintSpinner
  },
  data () {
      return {
          isAttemptingAuth: true,
          authUrl: `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/`,
          currUrl: new URL(window.location.href)
      }
  },
  mounted(){
    setTimeout(this.checkIsLoginedIn, 2000);
  },
  methods: {
    gotodashboard(){
        window.location.href = '/dashboard'
    },
    async checkIsLoginedIn(){
      await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 200){
                window.location = "/product/portfolio_trader"
              }
              if(response.status == 401){
                this.isAttemptingAuth = false
              }
              return response.json()
            })
            .then(data => {
                console.log(data)
            });
    },
    onFormSubmit(e){
      this.isAttemptingAuth = true
      var formdata = new FormData(document.querySelector('form[id="login_form"]'));
      formdata.set("token", this.currUrl.searchParams.get('token'))
      if (formdata.get("password") !== formdata.get("confirm_password")){
        this.isAttemptingAuth = false
        alert("Your 'Password' and 'Confirm Password' Does not match")
        e.preventDefault();
        return
      }
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/password/reset/`, requestOptions)
        .then(response => {
          if(response.status == 401){
            this.isAttemptingAuth = false
            alert("You are not authorized")
          }
          if(response.status == 500){
            this.isAttemptingAuth = false
            alert("Server Error")
          }
          return response.json()
        })
        .then(result => {
          if (result.status == "error.validation"){
              this.isAttemptingAuth = false
              alert(result.message)
            }
            if (result.status == "ok"){
              alert("Password Reset Successful! You can now sign in with your new credentials!")
              window.location.href = "/login"
            }
        })
        .catch(error => console.log('error', error));

      e.preventDefault();
    }
    }
}

</script>

<style>

.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
</style>