<template>
    <div class="flex flex-row w-full md:flex-row sm:flex-col animate__animated animate__shakeY animate__delay-5s animate__repeat-2">
        <div class="xl:w-0 xl:flex-1">
            <h2 class="text-2xl font-extrabold leading-8 tracking-tight text-white sm:text-3xl sm:leading-9">
                <span class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400">Limited Time</span>
                Get 80% off your first month 
            </h2>
            <div class="max-w-3xl mt-3 text-indigo-200">
                <div class="flex flex-col mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <div class="flex justify-center items-baseline">
                  <span class="mr-2 text-3xl font-extrabold line-through decoration-red-200">$120</span>
                  <span class="mr-2 text-5xl text-green-500 font-extrabold">$24</span>
                  <span class="text-gray-500 dark:text-gray-400">/month</span>
              </div>
          </div>
            </div>
        </div>
        <div class="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <div class="mt-3 rounded-md shadow sm:mt-0 sm:flex-shrink-0">
                <p class="flex items-center justify-center w-full px-5 py-3 underline text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md hover:bg-purple-400 focus:outline-none focus:bg-purple-400">
                    special80offfirsttime
                </p>

                <a class="flex mt-2 items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-purple-500 border border-transparent rounded-md hover:bg-purple-400 focus:outline-none focus:bg-purple-400"
                    href="/register">
                    Get Started
                </a>
            </div>
            <p class="mt-3 text-sm leading-5 text-indigo-200">
                Don't miss out on profits! Stay in the loop ♾️ You'll get access to our dashboard of all our alerts!
            </p>
        </div>
    </div>
</template>

<script>
/*
<div class="px-6 py-6 bg-purple-700 rounded-lg dark:bg-gray-800 mb-2 md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center"></div>
*/
export default {
 props: {
   risk_indicators: Object,
   data: Object
 },
 mounted(){
 },
 data(){
     return {
     }
 },
 methods: {
   async subscribeViaEmail(){

   }
 }
 
}
</script>

<style scoped>
.Header {
  margin-bottom: 15px;
}
.hidden-as-sec-look {
  opacity: 0.3;
  -webkit-text-security: disc;
}


@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}
</style>