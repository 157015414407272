<script setup>
import {defineProps } from 'vue';
import { initFlowbite } from 'flowbite'
import { Handle, Position, useVueFlow} from '@vue-flow/core'
import { onMounted } from 'vue'
import { reactive, watch } from 'vue'
import {formatFixedNumber} from '../../utils'

const { updateNodeData, getConnectedEdges } = useVueFlow()
let DidInitFlowByte = false;
const store = reactive({
    xhrDataStockSearch: {},

    // {"symbol": {"checked": true}}
    isAutoSelectModels: false,
})

const props = defineProps(['label', 'id', 'data'])
if (props.data.configuration === undefined){
  updateNodeData(props.id, { configuration: {} })
}

const getConnectedSourceNode = () => {
  let sNode = getConnectedEdges(props.id)[0]

  if (sNode?.sourceNode){
    if (sNode.sourceNode.type == "stock_symbol_node"){
      return sNode.sourceNode
    }
    return null
  }
  return null
}

const handleUserSelectedSymbol = (symbol, model) => {
      let copyNodeConfiguration = {...props.data.configuration}
      delete copyNodeConfiguration[symbol][model.model_id]
      updateNodeData(props.id, { configuration: copyNodeConfiguration })
    }
    const addSymbolAsSelected = (symbol, model) => {
      let copyNodeConfiguration = {...props.data.configuration}
      if (!copyNodeConfiguration[symbol]){
        copyNodeConfiguration[symbol] = {}
      }
      copyNodeConfiguration[symbol][model.model_id] = {
        model_id: model.model_id,
        model_seeded_name: model.model_seeded_name,
      }
        updateNodeData(props.id, { configuration: copyNodeConfiguration })
    }
    const getAllItem = (symbol, data) => {
      if (data){
        if (!DidInitFlowByte){
         initFlowbite();
         DidInitFlowByte = true;
        }
        return data.filter(d => Object.keys(props.data.configuration[symbol] ? props.data.configuration[symbol]: {}).indexOf(d.model_id) === -1)
        
      }
      return {}
      }
    const onPressSymbolSearch = async (symbol) => {
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };
          
          // Set it to loading
          store.xhrDataStockSearch[symbol] = {...store.xhrDataStockSearch[symbol], ...{loading: true}}

          fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stock_model_search?keyword=${symbol}`, requestOptions)
            .then(response => response.json())
            .then(respData => {
                  store.xhrDataStockSearch[symbol] = {...respData, ...{loading: false}}
              return
            })
            .catch(error => console.log('error', error));
        }


onMounted (() => {
  getConnectedSourceNode()
  initFlowbite();
})

watch(
    () => getConnectedSourceNode()?.data,
      (newValue, oldValue) => {
        console.log('Upper Node updated!', newValue, oldValue);
        let sNode = getConnectedEdges(props.id)[0]

        if (sNode?.sourceNode){
          if (sNode.sourceNode.type == "stock_symbol_node"){
            console.log("get connected node", sNode.sourceNode.data.configuration)

            // When a "Upper" Stock node changes - We show ripple effect the data of the connected Model Node
            if (sNode.sourceNode?.data?.configuration && props.data?.configuration){
              let parentSymbolsOfNode = Object.keys(sNode.sourceNode.data.configuration)
              let copyOfCurrentConfiguration = {...props.data.configuration}
              let newConfiguration = {}
              Object.entries(sNode.sourceNode.data.configuration).forEach(([item]) => {
                if(Object.keys(copyOfCurrentConfiguration).indexOf(item) <= -1){
                  newConfiguration[item] = {}
                } else {
                  if (parentSymbolsOfNode.indexOf(item) > -1){
                    newConfiguration[item] = copyOfCurrentConfiguration[item]
                  }
                }
              })
              // newConfiguration = Object.fromEntries(Object.entries(props.data.configuration).filter(([key]) => parentSymbolsOfNode.indexOf(key) > -1))
              updateNodeData(props.id, { configuration: newConfiguration })
            }
          }
        }
     }, {
    immediate: true,
   deep: true,
 })

</script>

<template>
<div class="w-full node-container">
  <Handle type="target" :position="Position.Top" />
  <div class="mt-4 text-base font-medium p-0">{{ props.label }}</div>
  <div class="flex flex-col space-y-2 px-4">
    <div v-if="Object.keys(props.data.configuration ? props.data.configuration: {}).length " class="flex flex-col items-center justify-center mt-2">
        <hr class="w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
        <span class="material-symbols-outlined mt-2 text-[8em]">conversion_path</span>
        <div class="flex flex-col">
          <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">Connect a</h1>
          <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">Stock Symbol</h1>
          <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">Node</h1>
        </div>
        <hr class="w-full mt-3 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
    </div>
    <div v-else class="flex flex-col items-center justify-center mt-2">
        <hr class="w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
        <span class="material-symbols-outlined mt-2 text-[8em]">compare_arrows</span>
        <div class="flex flex-col">
          <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">Apply a</h1>
          <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">Model to each</h1>
          <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">Symbol</h1>
        </div>
        <hr class="w-full mt-3 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
    </div>
    <div v-for="(sourceConnected) in Object.keys(props.data.configuration ? props.data.configuration: {})" :key="sourceConnected" class="flex flex-row items-center space-y-2">
    <span class="ms-3 text-lg font-medium text-gray-900 dark:text-white pr-3">{{sourceConnected}}</span>
        <button @click="onPressSymbolSearch(sourceConnected)" :id="'modelSelection' + props.id" :data-dropdown-toggle="'modelSelectiondropdownSearch' + props.id + sourceConnected" data-dropdown-placement="right" class="w-full place-content-between text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 z-30" type="button">
            <div class="flex items-center">
              <div :class="Object.keys(props.data.configuration[sourceConnected] ? props.data.configuration[sourceConnected] : {}).length > 0 ? 'grid grid-cols-2 gap-2': 'flex'">
                  <span v-if="Object.values(props.data.configuration[sourceConnected] ? props.data.configuration[sourceConnected] : {}).length == 0" class="flex items-center">
                    Select Model
                  </span>
                  <span v-for="(item) in Object.values(props.data.configuration[sourceConnected] ? props.data.configuration[sourceConnected] : {})" :key="item.symbol" class="flex">
                    {{item.model_seeded_name}}
                  </span>
                </div>
                <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
              </div>
            <div id="tooltip-default" role="tooltip" class="absolute z-30 invisible px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 dark:bg-gray-700">
                Warning: You must select at-least 1 model for this symbol to be shown in your portfolio
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <span v-if="Object.values(props.data.configuration[sourceConnected] ? props.data.configuration[sourceConnected] : {}).length == 0" data-tooltip-target="tooltip-default" class="flex items-center">
                
              <span class="material-symbols-outlined text-yellow-300">warning</span>
              
            </span>
            </button>
              <!-- Dropdown menu -->
              <div :id="'modelSelectiondropdownSearch' + props.id + sourceConnected" class="z-10 w-max hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                    <ul class="w-full max-h-[72em] px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="modelSelection">
                      <div v-if="store.xhrDataStockSearch[sourceConnected]?.data?.length > 0" class="flex w-full flex-col items-center justify-center mt-2">
                          <hr class="w-96 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                          <span class="material-symbols-outlined mt-2 text-[4em]">modeling</span>
                          <div class="flex flex-col">
                            <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">Select Your Models Below</h1>
                          </div>
                          <hr class="w-full mt-3 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                      </div>
                      <div v-if="store.xhrDataStockSearch[sourceConnected]?.data?.length <= 0 && store.xhrDataStockSearch[sourceConnected]?.timestamp" class="flex w-full h-5/6 flex-col items-center justify-center mt-2 overflow-none">
                          <span class="material-symbols-outlined mt-2 text-[8em]">production_quantity_limits</span>
                          <div class="flex flex-col">
                            <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">No Models Found in Database</h1>
                            <h1 class="font-semibold text-center text-base text-gray-500 dark:text-white">for this symbol</h1>
                          </div>
                      </div>
                      <li v-for="(item, index) in getAllItem(sourceConnected, store.xhrDataStockSearch[sourceConnected]?.data)" :key="item.model_id + index" class="my-2">
                        <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                          <button :id="'modelSelectioncheckbox-item-' + index" @click="addSymbolAsSelected(sourceConnected, item)" type="button" value="" class="w-full h-full text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                            <label :for="'checkbox-item-' + index" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{item.model_seeded_name}}</label>
                          
                            <div class="grid grid-cols-3 gap-4 p-6">
                              <div class="flex items-start text-start">
                                <span class="material-symbols-outlined mt-2 text-[2em]">verified</span>
                                  <div class="font-medium dark:text-white">
                                      <div>Win Rate</div>
                                      <div class="text-sm text-gray-500 dark:text-gray-400">{{formatFixedNumber(item.winrate_precent, prepend="", postpend="%", decimal=2)}}</div>
                                  </div>
                              </div>
                              
                              <div class="flex items-start text-start">
                                <span class="material-symbols-outlined mt-2 text-[2em]">verified</span>
                                  <div class="font-medium dark:text-white">
                                      <div>Number of Trades</div>
                                      <div class="text-sm text-gray-500 dark:text-gray-400">{{formatFixedNumber(item.number_of_trades, prepend="", postpend="", decimal=2)}}</div>
                                  </div>
                              </div>
                              
                              <div class="flex items-start text-start">
                                <span class="material-symbols-outlined mt-2 text-[2em]">verified</span>
                                  <div class="font-medium dark:text-white">
                                      <div>Number Of Successful Trades</div>
                                      <div class="text-sm text-gray-500 dark:text-gray-400">{{formatFixedNumber(item.number_of_successful_trades, prepend="", postpend="", decimal=2)}}</div>
                                  </div>
                              </div>
                              
                              <div class="flex items-start text-start">
                                <span class="material-symbols-outlined mt-2 text-[2em]">verified</span>
                                  <div class="font-medium dark:text-white">
                                      <div>Number Of Open Trades</div>
                                      <div class="text-sm text-gray-500 dark:text-gray-400">{{formatFixedNumber(item.number_of_open_trades, prepend="", postpend="", decimal=2)}}</div>
                                  </div>
                              </div>
                              
                              <div class="flex items-start text-start">
                                <span class="material-symbols-outlined mt-2 text-[2em]">verified</span>
                                  <div class="font-medium dark:text-white">
                                      <div>Number Of Failed Trades</div>
                                      <div class="text-sm text-gray-500 dark:text-gray-400">{{formatFixedNumber(item.number_of_failed_trades, prepend="", postpend="", decimal=2)}}</div>
                                  </div>
                              </div>

                              <div class="flex items-start text-start">
                                <span class="material-symbols-outlined mt-2 text-[2em]">verified</span>
                                  <div class="font-medium dark:text-white">
                                      <div>Return Avg. Percent</div>
                                      <div class="text-sm text-gray-500 dark:text-gray-400">{{formatFixedNumber(item.return_avg_precent, prepend="", postpend="%", decimal=2)}}</div>
                                  </div>
                              </div>
                            </div>

                          </button>
                          
                        </div>
                      </li>
                      <div v-if="store.xhrDataStockSearch[sourceConnected] <= 0 && Object.keys(props.data.configuration ? props.data.configuration : {}).length == 0" class="flex flex-col items-center justify-center mt-2">
                          <hr class="w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                          <span class="material-symbols-outlined mt-2" style=" font-size: 5em; ">find_in_page</span>
                          <h1 class="font-semibold text-center text-xl text-gray-900 underline dark:text-white decoration-green-500">Find Stocks/Securities</h1>
                      </div>
                      
                    </ul>
                    
                    <div v-if="Object.keys(props.data.configuration[sourceConnected] ? props.data.configuration[sourceConnected] : {}).length > 0" class="flex flex-col h-full w-full items-center p-3 text-sm font-medium border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:text-green-500">
                        <div class="flex flex-row items-center space-x-2">
                          <label for="ai-picks-checkbox" class="text-2xl font-medium text-gray-900 dark:text-gray-300">Selected</label>
                          <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1v3m5-3v3m5-3v3M1 7h7m1.506 3.429 2.065 2.065M19 7h-2M2 3h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm6 13H6v-2l5.227-5.292a1.46 1.46 0 0 1 2.065 2.065L8 16Z"></path>
                          </svg>
                        </div>
                        <hr class="w-full mt-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                      <!-- <div class="flex items-center h-5">
                            <button id="modelSelectionai-picks-checkbox" :checked="store.isAutoSelectModels" @change="store.isAutoSelectModels = $event.target.checked" aria-describedby="ai-picks-checkbox-text" type="button" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></button>
                        </div>
                        <div class="ml-2 text-sm">
                            <div class="flex flex-row items-center space-x-2">
                              <label for="ai-picks-checkbox" class="font-medium text-gray-900 dark:text-gray-300">Model Selection</label>
                              <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1v3m5-3v3m5-3v3M1 7h7m1.506 3.429 2.065 2.065M19 7h-2M2 3h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm6 13H6v-2l5.227-5.292a1.46 1.46 0 0 1 2.065 2.065L8 16Z"></path>
                              </svg>
                            </div>
                            <p id="modelSelectionai-picks-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">Automatically select your models.</p>
                        </div> -->
  
                        <div class="flex flex-col w-full items-center space-x-2">
                              
                              <ul class="h-full w-full px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="modelSelection">
                                <li v-for="existingItem in Object.keys(props.data.configuration[sourceConnected] ? props.data.configuration[sourceConnected] : {})" :key="'user-selected' +  props.data.configuration[sourceConnected][existingItem].model_id" class="w-full my-2">
                                  <div class="flex items-center w-full rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                    <button :id="'modelSelectionuser-selected-checkbox-item-' + props.data.configuration[sourceConnected][existingItem].model_id" @click="handleUserSelectedSymbol(sourceConnected, props.data.configuration[sourceConnected][existingItem])" type="button" class="w-full h-10 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                      <label :for="'user-selected-checkbox-item-' + props.data.configuration[sourceConnected][existingItem].model_id" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{props.data.configuration[sourceConnected][existingItem].model_seeded_name}}</label>
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                    </div>
                </div>
              </div>
              
    </div>
    <svg 
 viewBox="0 0 2 1" 
 preserveAspectRatio="none">
  <defs>
    <path id="w" 
      d="
      m0 1v-.5 
      q.5.5 1 0
      t1 0 1 0 1 0
      v.5z" />
  </defs>
  <g>
   <use href="#w" y=".0" fill="#2d55aa" />
   <use href="#w" y=".1" fill="#3461c1" />
   <use href="#w" y=".2" fill="#4579e2" />
  </g>
 </svg>
  <Handle type="source" :position="Position.Bottom" />
  </div>
</template>

<style>
use{
  animation:move-forever 6s linear infinite;
  &:nth-child(2){ animation-duration:6.5s; animation-delay:-3.5s; }
  &:nth-child(1){ animation-duration:15s}
}

@keyframes move-forever{
   0%{transform: translate(-2px , 0)}
 100%{transform: translate( 0px , 0)} 
}

.vue-flow__node-stock_model_node {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  min-width: 350px;
  max-width: 850px;
  font-size: 12px;
  text-align: center;
  color: var(--vf-node-text);
  cursor: grab;
  box-sizing: border-box;
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);

  background: var(--vf-node-bg);
  border-color: var(--vf-node-color);
  outline: none;
  border: 1px solid var(--vf-node-color);
  box-shadow: 0 0 0 0.5px var(--vf-box-shadow);
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);
  background: var(--vf-node-bg);
}

.vue-flow__node-stock_model_node > .node-container > .vue-flow__handle-top {
  width: 10px;
  height: 10px;
}

.vue-flow__node-stock_model_node > .node-container > .vue-flow__handle-bottom {
  width: 10px;
  height: 10px;
}
</style>