<template>
  <div class="flex flex-col bg-white">
    <link rel="stylesheet" href="./css/extra.css">
    <link rel="stylesheet" href="./css/SliderAnimation.css">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <!-- Mobile menu button-->
            <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!--
                Icon when menu is closed.

                Heroicon name: outline/menu

                Menu open: "hidden", Menu closed: "block"
              -->
              <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!--
                Icon when menu is open.

                Heroicon name: outline/x

                Menu open: "block", Menu closed: "hidden"
              -->
              <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div class="flex-shrink-0 flex items-center">
              <img v-on:click="handleHome" class="block lg:hidden h-8 w-auto" src="images/logos/barpot_logo_only_w_text_alt.svg" alt="BarPot">
              <img v-on:click="handleHome" class="hidden lg:block h-8 w-auto" src="images/logos/barpot_logo_only_w_text_alt.svg" alt="BarPot">
            </div>
            <div class="hidden sm:block sm:ml-6">
              <div class="flex space-x-4">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <a href="#" class="hidden bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" aria-current="page">Dashboard</a>

                <a href="#" class="hidden text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Team</a>

                <a href="#" class="hidden text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Projects</a>

                <a href="#" class="hidden text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Calendar</a>
              </div>
            </div>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

            <!-- <button class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span class="sr-only">View notifications</span>
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
              </svg>
            </button> -->

            <!-- Profile dropdown -->
            <div class="ml-3 relative">
              <div class="flex flex-row justify-center">
                <button v-on:click="handleDashboard" class="flex mr-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded space-x-1 items-center rounded-lg hover:text-gray-500 bg-gradient-to-br"> 
                  <span>Dashboard</span>
                </button>
                
                <button v-on:click="handleSignout" class="flex bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded space-x-1 items-center rounded-lg hover:text-gray-500 bg-gradient-to-br"> 
                  <span>Sign Out</span>
                </button>
              </div>

              <div class="hidden origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                <!-- Active: "bg-gray-100", Not Active: "" -->
                <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Profile</a>
                <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-1">Settings</a>
                <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div class="sm:hidden" id="mobile-menu">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
          <a href="#" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Dashboard</a>

          <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>

          <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>

          <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a>
        </div>
      </div>
    </nav>

    <section class="flex justify-center">
      <div class="flex items-center my-8">
        <div class="flex sm:flex-col md:flex-row flex-row max-w-screen-xl w-full mx-auto px-4 sm:px-6 items-center md:space-x-24">
          <div class="rounded overflow-hidden shadow-lg">
            <div v-if="store.userData.is_premium" class="flex flex-col bg-yellow-100 border border-yellow-400 text-blue-700 text-center px-4 py-3 rounded relative my-4" role="alert">
              <strong class="font-bold">You Are a Premium Member!</strong>
              <div v-if="store.userData.subscription_status == 'subscription.cancelled'" class="flex flex-col">
                <hr class="divide-y divide-dashed my-2">
                <strong class="font-bold text-red-500 underline">Subscription <p class="font-bold text-xl">Cancelled</p> Time Left:</strong>
                <strong class="font-bold text-red-500">{{store.userData.subscription_duration_left}}</strong>
              </div>
              <div v-if="store.userData.subscription_status == 'subscription.active'" class="flex flex-col">
                <hr class="divide-y divide-dashed my-2">
                <strong class="font-bold text-black-500 underline">Subscription: <p class="font-bold text-xl">{{store.userData.subscription_type.name}}</p></strong>
                <strong class="hidden font-bold text-green-500">Next Billing: {{store.userData.subscription_duration_left}}</strong>
              </div>
              <div v-if="store.userData.subscription_status == 'subscription.null'" class="flex flex-col">
                <hr class="divide-y divide-dashed my-2">
                <strong class="font-bold text-black-500 underline">Subscription: <p class="font-bold text-xl">FREE</p></strong>
              </div>
            </div>

            <div v-if="!store.userData.is_premium" class="flex flex-col bg-yellow-100 border border-yellow-400 text-blue-700 text-center px-4 py-3 rounded relative my-4" role="alert">
              <strong class="font-bold">You Are a FREE Member!</strong>
              <div v-if="store.userData.subscription_status == 'subscription.null'" class="flex flex-col">
                <hr class="divide-y divide-dashed my-2">
                <strong class="font-bold text-black-500 underline">Subscription: <p class="font-bold text-xl">FREE</p></strong>
              </div>
            </div>
          <h1 class="font-medium leading-tight text-2xl mt-0 mb-2 text-center underline">My Account</h1>
          <div class="px-6 py-4">
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <span>Username</span>
              <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i class="fas fa-lock"></i>
              </span>
              <input type="text" disabled :placeholder="store.userData.info.username" class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white text-sm focus:outline-none focus:ring w-full pl-10"/>
            </div>
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <span>Email</span>
              <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i class="fas fa-lock"></i>
              </span>
              <input type="text" disabled :placeholder="store.userData.info.email" class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white text-sm focus:outline-none focus:ring w-full pl-10"/>
            </div>
            
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <span>Phone Number</span>
              <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i class="fas fa-lock"></i>
              </span>
              <div class="flex space-x-2">
              <input type="phone" id="user_phone_number" :value="store.userData.info.phone_number" :placeholder="store.userData.info.phone_number" class="px-3 py-3 placeholder-slate-700 text-slate-700 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pl-10"/>
            
    <select id="phone_number_user_countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    <option>Choose a country</option>
    <option value="AF">Afghanistan</option>
        <option value="AX">Aland Islands</option>
        <option value="AL">Albania</option>
        <option value="DZ">Algeria</option>
        <option value="AS">American Samoa</option>
        <option value="AD">Andorra</option>
        <option value="AO">Angola</option>
        <option value="AI">Anguilla</option>
        <option value="AQ">Antarctica</option>
        <option value="AG">Antigua and Barbuda</option>
        <option value="AR">Argentina</option>
        <option value="AM">Armenia</option>
        <option value="AW">Aruba</option>
        <option value="AU">Australia</option>
        <option value="AT">Austria</option>
        <option value="AZ">Azerbaijan</option>
        <option value="BS">Bahamas</option>
        <option value="BH">Bahrain</option>
        <option value="BD">Bangladesh</option>
        <option value="BB">Barbados</option>
        <option value="BY">Belarus</option>
        <option value="BE">Belgium</option>
        <option value="BZ">Belize</option>
        <option value="BJ">Benin</option>
        <option value="BM">Bermuda</option>
        <option value="BT">Bhutan</option>
        <option value="BO">Bolivia</option>
        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
        <option value="BA">Bosnia and Herzegovina</option>
        <option value="BW">Botswana</option>
        <option value="BV">Bouvet Island</option>
        <option value="BR">Brazil</option>
        <option value="IO">British Indian Ocean Territory</option>
        <option value="BN">Brunei Darussalam</option>
        <option value="BG">Bulgaria</option>
        <option value="BF">Burkina Faso</option>
        <option value="BI">Burundi</option>
        <option value="KH">Cambodia</option>
        <option value="CM">Cameroon</option>
        <option value="CA">Canada</option>
        <option value="CV">Cape Verde</option>
        <option value="KY">Cayman Islands</option>
        <option value="CF">Central African Republic</option>
        <option value="TD">Chad</option>
        <option value="CL">Chile</option>
        <option value="CN">China</option>
        <option value="CX">Christmas Island</option>
        <option value="CC">Cocos (Keeling) Islands</option>
        <option value="CO">Colombia</option>
        <option value="KM">Comoros</option>
        <option value="CG">Congo</option>
        <option value="CD">Congo, Democratic Republic of the Congo</option>
        <option value="CK">Cook Islands</option>
        <option value="CR">Costa Rica</option>
        <option value="CI">Cote D'Ivoire</option>
        <option value="HR">Croatia</option>
        <option value="CU">Cuba</option>
        <option value="CW">Curacao</option>
        <option value="CY">Cyprus</option>
        <option value="CZ">Czech Republic</option>
        <option value="DK">Denmark</option>
        <option value="DJ">Djibouti</option>
        <option value="DM">Dominica</option>
        <option value="DO">Dominican Republic</option>
        <option value="EC">Ecuador</option>
        <option value="EG">Egypt</option>
        <option value="SV">El Salvador</option>
        <option value="GQ">Equatorial Guinea</option>
        <option value="ER">Eritrea</option>
        <option value="EE">Estonia</option>
        <option value="ET">Ethiopia</option>
        <option value="FK">Falkland Islands (Malvinas)</option>
        <option value="FO">Faroe Islands</option>
        <option value="FJ">Fiji</option>
        <option value="FI">Finland</option>
        <option value="FR">France</option>
        <option value="GF">French Guiana</option>
        <option value="PF">French Polynesia</option>
        <option value="TF">French Southern Territories</option>
        <option value="GA">Gabon</option>
        <option value="GM">Gambia</option>
        <option value="GE">Georgia</option>
        <option value="DE">Germany</option>
        <option value="GH">Ghana</option>
        <option value="GI">Gibraltar</option>
        <option value="GR">Greece</option>
        <option value="GL">Greenland</option>
        <option value="GD">Grenada</option>
        <option value="GP">Guadeloupe</option>
        <option value="GU">Guam</option>
        <option value="GT">Guatemala</option>
        <option value="GG">Guernsey</option>
        <option value="GN">Guinea</option>
        <option value="GW">Guinea-Bissau</option>
        <option value="GY">Guyana</option>
        <option value="HT">Haiti</option>
        <option value="HM">Heard Island and Mcdonald Islands</option>
        <option value="VA">Holy See (Vatican City State)</option>
        <option value="HN">Honduras</option>
        <option value="HK">Hong Kong</option>
        <option value="HU">Hungary</option>
        <option value="IS">Iceland</option>
        <option value="IN">India</option>
        <option value="ID">Indonesia</option>
        <option value="IR">Iran, Islamic Republic of</option>
        <option value="IQ">Iraq</option>
        <option value="IE">Ireland</option>
        <option value="IM">Isle of Man</option>
        <option value="IL">Israel</option>
        <option value="IT">Italy</option>
        <option value="JM">Jamaica</option>
        <option value="JP">Japan</option>
        <option value="JE">Jersey</option>
        <option value="JO">Jordan</option>
        <option value="KZ">Kazakhstan</option>
        <option value="KE">Kenya</option>
        <option value="KI">Kiribati</option>
        <option value="KP">Korea, Democratic People's Republic of</option>
        <option value="KR">Korea, Republic of</option>
        <option value="XK">Kosovo</option>
        <option value="KW">Kuwait</option>
        <option value="KG">Kyrgyzstan</option>
        <option value="LA">Lao People's Democratic Republic</option>
        <option value="LV">Latvia</option>
        <option value="LB">Lebanon</option>
        <option value="LS">Lesotho</option>
        <option value="LR">Liberia</option>
        <option value="LY">Libyan Arab Jamahiriya</option>
        <option value="LI">Liechtenstein</option>
        <option value="LT">Lithuania</option>
        <option value="LU">Luxembourg</option>
        <option value="MO">Macao</option>
        <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
        <option value="MG">Madagascar</option>
        <option value="MW">Malawi</option>
        <option value="MY">Malaysia</option>
        <option value="MV">Maldives</option>
        <option value="ML">Mali</option>
        <option value="MT">Malta</option>
        <option value="MH">Marshall Islands</option>
        <option value="MQ">Martinique</option>
        <option value="MR">Mauritania</option>
        <option value="MU">Mauritius</option>
        <option value="YT">Mayotte</option>
        <option value="MX">Mexico</option>
        <option value="FM">Micronesia, Federated States of</option>
        <option value="MD">Moldova, Republic of</option>
        <option value="MC">Monaco</option>
        <option value="MN">Mongolia</option>
        <option value="ME">Montenegro</option>
        <option value="MS">Montserrat</option>
        <option value="MA">Morocco</option>
        <option value="MZ">Mozambique</option>
        <option value="MM">Myanmar</option>
        <option value="NA">Namibia</option>
        <option value="NR">Nauru</option>
        <option value="NP">Nepal</option>
        <option value="NL">Netherlands</option>
        <option value="AN">Netherlands Antilles</option>
        <option value="NC">New Caledonia</option>
        <option value="NZ">New Zealand</option>
        <option value="NI">Nicaragua</option>
        <option value="NE">Niger</option>
        <option value="NG">Nigeria</option>
        <option value="NU">Niue</option>
        <option value="NF">Norfolk Island</option>
        <option value="MP">Northern Mariana Islands</option>
        <option value="NO">Norway</option>
        <option value="OM">Oman</option>
        <option value="PK">Pakistan</option>
        <option value="PW">Palau</option>
        <option value="PS">Palestinian Territory, Occupied</option>
        <option value="PA">Panama</option>
        <option value="PG">Papua New Guinea</option>
        <option value="PY">Paraguay</option>
        <option value="PE">Peru</option>
        <option value="PH">Philippines</option>
        <option value="PN">Pitcairn</option>
        <option value="PL">Poland</option>
        <option value="PT">Portugal</option>
        <option value="PR">Puerto Rico</option>
        <option value="QA">Qatar</option>
        <option value="RE">Reunion</option>
        <option value="RO">Romania</option>
        <option value="RU">Russian Federation</option>
        <option value="RW">Rwanda</option>
        <option value="BL">Saint Barthelemy</option>
        <option value="SH">Saint Helena</option>
        <option value="KN">Saint Kitts and Nevis</option>
        <option value="LC">Saint Lucia</option>
        <option value="MF">Saint Martin</option>
        <option value="PM">Saint Pierre and Miquelon</option>
        <option value="VC">Saint Vincent and the Grenadines</option>
        <option value="WS">Samoa</option>
        <option value="SM">San Marino</option>
        <option value="ST">Sao Tome and Principe</option>
        <option value="SA">Saudi Arabia</option>
        <option value="SN">Senegal</option>
        <option value="RS">Serbia</option>
        <option value="CS">Serbia and Montenegro</option>
        <option value="SC">Seychelles</option>
        <option value="SL">Sierra Leone</option>
        <option value="SG">Singapore</option>
        <option value="SX">Sint Maarten</option>
        <option value="SK">Slovakia</option>
        <option value="SI">Slovenia</option>
        <option value="SB">Solomon Islands</option>
        <option value="SO">Somalia</option>
        <option value="ZA">South Africa</option>
        <option value="GS">South Georgia and the South Sandwich Islands</option>
        <option value="SS">South Sudan</option>
        <option value="ES">Spain</option>
        <option value="LK">Sri Lanka</option>
        <option value="SD">Sudan</option>
        <option value="SR">Suriname</option>
        <option value="SJ">Svalbard and Jan Mayen</option>
        <option value="SZ">Swaziland</option>
        <option value="SE">Sweden</option>
        <option value="CH">Switzerland</option>
        <option value="SY">Syrian Arab Republic</option>
        <option value="TW">Taiwan, Province of China</option>
        <option value="TJ">Tajikistan</option>
        <option value="TZ">Tanzania, United Republic of</option>
        <option value="TH">Thailand</option>
        <option value="TL">Timor-Leste</option>
        <option value="TG">Togo</option>
        <option value="TK">Tokelau</option>
        <option value="TO">Tonga</option>
        <option value="TT">Trinidad and Tobago</option>
        <option value="TN">Tunisia</option>
        <option value="TR">Turkey</option>
        <option value="TM">Turkmenistan</option>
        <option value="TC">Turks and Caicos Islands</option>
        <option value="TV">Tuvalu</option>
        <option value="UG">Uganda</option>
        <option value="UA">Ukraine</option>
        <option value="AE">United Arab Emirates</option>
        <option value="GB">United Kingdom</option>
        <option value="US">United States</option>
        <option value="UM">United States Minor Outlying Islands</option>
        <option value="UY">Uruguay</option>
        <option value="UZ">Uzbekistan</option>
        <option value="VU">Vanuatu</option>
        <option value="VE">Venezuela</option>
        <option value="VN">Viet Nam</option>
        <option value="VG">Virgin Islands, British</option>
        <option value="VI">Virgin Islands, U.s.</option>
        <option value="WF">Wallis and Futuna</option>
        <option value="EH">Western Sahara</option>
        <option value="YE">Yemen</option>
        <option value="ZM">Zambia</option>
        <option value="ZW">Zimbabwe</option>
    </select>
    </div>
            </div>

            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <div class="flex mt-4">
                  <div class="flex items-center h-5">
                      <input id="user-allow-phone-alerts-checkbox" aria-describedby="user-allow-phone-alerts-checkbox-text" type="checkbox" :checked="store.userData.info.allow_phone_number_alerts" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  </div>
                  <div class="ml-2 text-sm">
                      <label for="user-allow-phone-alerts-checkbox" class="font-medium text-gray-900 dark:text-gray-300">Allow Alerts via. Text Message</label>
                      <p id="user-allow-phone-alerts-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">Enabling this will allow Barpot to send alerts via. Text Messages <a href="/terms-and-conditions" class="text-blue-600 dark:text-blue-500 hover:underline">Terms and Conditions</a> & <a href="/disclaimer" class="text-blue-600 dark:text-blue-500 hover:underline">Disclaimer</a>.</p>
                  </div>
              </div>
            </div>
            
            <div class="flex justify-center mb-24">
              <button v-on:click="updateUserAccount" class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">Update Account</button>
            </div>
            
            <hr class="divide-y divide-dashed my-2">
            <hr class="divide-y divide-dashed my-2">
            
            <h1 v-if="store.userData.subscription_status == 'subscription.trial' || store.userData.subscription_status == 'subscription.null'" class="text-md italic font-normal text-red-500 dark:text-red-300 text-center">You are not a premium subscriber, select a plan below to get started!</h1>
            
            <h1 v-if="store.userData.subscription_status == 'subscription.trial' || store.userData.subscription_status == 'subscription.null'" class="font-medium leading-tight text-2xl mt-0 mb-2 text-center underline">Select a Subscription Plan</h1>
            <h1 v-if="store.userData.subscription_status == 'subscription.active'" class="font-medium leading-tight text-2xl mt-0 mb-2 text-center underline">Subscription Information</h1>
            <div class="flex justify-center">
              <stripe-checkout
                ref="checkoutRef"
                :pk="store.publishableKey"
                :session-id="store.sessionId"
              />
              <div class="flex space-x-4">
                <div v-if="store.userData.subscription_status == 'subscription.trial' || store.userData.subscription_status == 'subscription.null'" class="flex flex-col space-y-2">
                  <button v-for="item in store.userData.subscription_types_allowed" :key="item.level" v-on:click="getPaymentSession(item.subscription_price_id)" class="flex">
                    <TraderPrice v-if="item.type == 'barpot_trader_tier'">
                      <div class="flex my-4 justify-center">
                        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">Upgrade to: {{item.name}}</button>
                      </div>
                    </TraderPrice>      
                  </button>
                </div>
                <button v-if="store.userData.subscription_status == 'subscription.active'" v-on:click="cancelUserSubscription" class="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded">Cancel Subscription</button>
                <button v-if="store.userData.subscription_status == 'subscription.cancelled'" v-on:click="reactivateUserSubscription" class="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded">Reactivate</button>
              </div>
          </div>
          </div>
        
        </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { StripeCheckout } from '@james090500/vue-stripe';
import TraderPrice from './components/pricing/TraderPrice.vue';
import { reactive } from 'vue'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const store = reactive({
  loading: true,
  sessionId: "",
  publishableKey: "",
  userData : {
      "info": {
        "username": "...",
        "email": "...",
        "phone_number": "...",
        "allow_phone_number_alerts": null
      }
    }
})
export default {
  name: 'AccountPage',
  components: {
    StripeCheckout,
    TraderPrice
  },
  data () {
    return {store};
  },
  mounted(){
      this.refreshToken();
      this.getUser();
      this.getPaymentConfig();
  },
  methods: {

        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
              store.userData = data
              store.loading = false
              try {
                document.getElementById("phone_number_user_countries").querySelector(`option[value=${store.userData.info.phone_number_country_code}]`).selected = true
              } catch (error) {
                console.log("phone_number_user_countries : ERROR", error)
              }
            })
            .catch(error => console.log('error', error));
        },
        async getPaymentConfig(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/payments/config`, requestOptions)
            .then(response => response.json())
            .then(data => {
              store.publishableKey = data.publicKey
            })
            .catch(error => console.log('error', error));
        },
        async getPaymentSession(subscription_price_id){
          if(store.userData.is_premium){
            alert("You Are a Premium Member, You don't need to Re-Subscribe!")
            return;
          }
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          $toast.open({
            message: "Loading your payment page...",
            type: 'info',
            duration: 10000,
            position: 'bottom'
          });

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/payments/create-checkout-session?subscription_price_id=${subscription_price_id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
              store.sessionId = data.sessionId
              store.publishableKey = data.publicKey
              this.$refs.checkoutRef.redirectToCheckout();
            })
            .catch(error => console.log('error', error));
        },
        async updateUserAccount(){
          if (store.loading == true){
            alert("Please wait until page has loaded.");
            return;
          }
          
          let data = {
            user: {
              phone_number: document.getElementById("user_phone_number").value,
              phone_number_user_country: document.getElementById("phone_number_user_countries").value,
              allow_phone_number_alerts: document.getElementById("user-allow-phone-alerts-checkbox").checked
            }
          };

          var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/account_update`, requestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.status == "ok"){
                alert("Your account was updated")
                window.location.reload()
              }
              if (data.status == "error.phone_number_invalid"){
                alert(data.message)
              }
            })
            .catch(error => console.log('error', error));
        },
        async cancelUserSubscription(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/payments/cancel_subscription`, requestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.subscription_status == "subscription.cancelled"){
                alert("Your subscription has been cancelled.")
                window.location.reload()
              }
            })
            .catch(error => console.log('error', error));
        },
        async reactivateUserSubscription(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/payments/reactivate_subscription`, requestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.subscription_status == "subscription.active"){
                alert("Your subscription has been Reactivated.")
                window.location.reload()
              }
            })
            .catch(error => console.log('error', error));
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        handleDashboard(){
          window.location = "/dashboard"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
    }
}

</script>

<style>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
</style>