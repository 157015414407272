<script>
import { formatValue, PolygonType , dispose, init, version as klinechartsVersion, registerIndicator } from "klinecharts";
import { FingerprintSpinner } from 'epic-spinners';
import { OrbitSpinner } from 'epic-spinners'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
// import HomeReportingSlider from '../components/HomeReportingSlider.vue'

const $toast = useToast();

let currPath = new URL(window.location.href);
export default {
  name: "MainChart",
  components: { FingerprintSpinner, OrbitSpinner},
  props: ['propSymbol', 'enableDataFetch', 'propData'],
  watch: {
    propData: function(newVal, oldVal) { // watch it
      console.log('Prop propData changed: ', newVal, ' | was: ', oldVal)
      if(this.propData !== null){
        this.doChartPopulation(this.propData)
        if(this.propData?.data?.main?.data.length <= 0){
          this.isEmptyData = true
        } else {
          this.isEmptyData = false
        }
      }
    },
  },
  data: function () {
    
    let paramSymbol = this.propSymbol // currPath.searchParams.get("symbol");
    let paramStockSymbol = currPath.searchParams.get("symbol");
    let paramTimestampFrom = currPath.searchParams.get("timestamp_from");
    let paramTimestampTo = currPath.searchParams.get("timestamp_to");
    let paramDiscordClientId = currPath.searchParams.get("discord_client_id");
    let paramIsDev = currPath.searchParams.get("dev") == "true" ? true : false;
    let paramAccessToken = currPath.searchParams.get("access_token");
    let paramStockModelId = currPath.searchParams.get("stock_model_id");

    return {
      mainIndicators: ["MA", "EMA", "SAR"],
      subIndicators: ["VOL", "MACD", "KDJ"],
      userParams: {
        paramDiscordClientId,
        paramIsDev,
        paramStockSymbol,
        paramSymbol,
        paramTimestampFrom,
        paramTimestampTo,
        paramAccessToken,
        paramStockModelId
      },
      xhrData: null,
      xhrIsProcessing: false,
    };
  },
  mounted: function () {
    this.chart = init("indicator-k-line", {
                      timezone: 'GMT'
                    });

    if (this.enableDataFetch == true){
      console.log("Using Explicit DataFetch", this.enableDataFetch)
      this.populateChart(this.userParams.paramSymbol, null, null, this.userParams.paramDiscordClientId, this.userParams.paramAccessToken);
    } else {
      if (window.location.href.indexOf("/stock") >= 1 || window.location.href.indexOf("live_chart") >= 1){
        console.log("Dont Auto Refresh")
      } else {
      let intervalIsProcessNode = setInterval(() => {
        if (this.xhrData !== null){
          if (!this.xhrData.model_compute.is_processed){
            console.log("Need Process")
            if (this.enableDataFetch == true){
              this.populateChart(this.userParams.paramSymbol, null, null, this.userParams.paramDiscordClientId, this.userParams.paramAccessToken);
            }
          } else {
            clearInterval(intervalIsProcessNode)
          }
          }
        }, 10000);
      }
    }
    // this.chart.applyNewData(generatedDataList());
  },
  methods: {
    setMainIndicator: function (name) {
      this.chart.createIndicator(name, false, { id: "candle_pane" });
    },
    setSubIndicator: function (name) {
      this.chart.createIndicator(name, false, { id: this.paneId });
    },
    navToHome(){
      window.location = "/product/plays_stocks"
    },
    copyNavLink(symbol){
      fetch(`https://barstool-backend-c6rnmqev2q-ue.a.run.app/api/tmp_free_view?symbol=${symbol}`, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            
        },
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
          navigator.clipboard.writeText(data.url).then(function() {
          $toast.open({
            message: symbol + " - Link Copied, Share it with the world!" + ' 🚀',
            type: 'success',
            duration: 10000,
            position: 'bottom'
            // all of other options may go here
          });
      }, function() {
          console.log('Copy error')
      });
      });
    },
    zip: function(arrays) {
        return arrays[0].map(function(_,i){
            return arrays.map(function(array){return array[i]})
        });
    },
    doChartPopulation(resp_data){
      console.log("Chart V_ ", klinechartsVersion())
      // cache the data to memory
                    this.xhrData = resp_data;
                    // this.chartTitleText = `${symbol.toUpperCase()} ~ Win-Rate: ${data.backtest_results['Win Rate [%]']}% ~ Avg Return: ${data.backtest_results['Return [%]']}%]`
                    
                    // let STOCK_DATA = {
                    //     timestamp: Object.values(data.data.t).reverse(),
                    //     open: Object.values(data.data.o).reverse(),
                    //     high: Object.values(data.data.h).reverse(),
                    //     low: Object.values(data.data.l).reverse(),
                    //     close: Object.values(data.data.c).reverse(),
                    //     volume: Object.values(data.data.v).reverse()
                    // }

                    let graphMappedStockData = []
                    let timeValues = Object.values(resp_data.data.t)
                    for (let idx = timeValues.length - 1; idx > 0; idx--) {
                      graphMappedStockData.push(
                        {
                          timestamp: resp_data.data.t[idx],
                          open: resp_data.data.o[idx],
                          high: resp_data.data.h[idx],
                          low: resp_data.data.l[idx],
                          close: resp_data.data.c[idx],
                          volume: resp_data.data.v[idx]
                        }
                      )
                    }

                    // End of block
                    this.xhrIsProcessing = false;

                    // console.log(zip)
                    // console.log(graphMappedStockData)
                    this.chart.applyNewData(graphMappedStockData);
                    console.log("Applied Data to Chart")
                    const fruits = [
                      "🍏",
                      "🍎",
                      "🍐",
                      "🍊",
                      "🍋",
                      "🍌",
                      "🍉",
                      "🍇",
                      "🍓",
                      "🍈",
                      "🍒",
                      "🍑",
                      "🍍",
                      "🥥",
                      "🥝",
                      "🥭",
                      "🥑",
                      "🍏",
                    ];
                    registerIndicator({
                      name: "MOMENTUM_ENTRY_POINT",
                      shortName: "",
                      figures: [{ key: "momentum_entry_point" }],
                      calc: (kLineDataList) => {
                        // console.log("MOMENTUM_ENTRY_POINT: List Agg", this.xhrData.signals.buy, kLineDataList)
                        let _r = {}
                        
                        Object.keys(this.xhrData.signals.buy).map((alert) => {
                          _r[parseInt(this.xhrData.signals.buy[alert].entrytime_as_epoch * 1000)] = {
                            emoji: this.xhrData.signals.buy[alert].entrytime_as_epoch,
                            _alert: this.xhrData.signals.buy[alert],
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })
                        // console.log("MOMENTUM_ENTRY_POINT: Calc GEN Data: ", _r)

                        let _return_data = kLineDataList.map((kLineData) => {
                          let _exist = Object.keys(_r).indexOf(kLineData.timestamp.toString()) > -1
                          return {
                            emoji: kLineData.close,
                            exist: _exist,
                            _alert: _exist ? _r[kLineData.timestamp.toString()]._alert: null,
                            text: _exist ? fruits[Math.floor(Math.random() * 17)]: "",
                          }
                        });
                        // console.log("MOMENTUM_ENTRY_POINT: Calc Return Data: ", _return_data)
                        return _return_data
                      },
                      draw: ({ ctx, barSpace, visibleRange, indicator, xAxis, yAxis }) => {
                        console.log("Activate Draw: MOMENTUM_ENTRY_POINT", barSpace)
                        const fillTextMultiLine = (ctx, text, x, y, max_w=20) => {
                            var lineHeight = ctx.measureText(text).width;
                            var lines = text.split("\n");
                            for (var i = 0; i < lines.length; ++i) {
                                ctx.fillText(lines[i].trim(), x, y, max_w);
                                y += lineHeight;
                            }
                        }

                        const position_sentimentFormatting = (c, prepend="") => {
                          let covert = {"hold":"HOLD", "strong_buy":"STRONG BUY", "buy":"BUY"}
                          return prepend + covert[c]
                        }
                        const Numbetween = (t, a, b) => {
                          var min = Math.min.apply(Math, [a, b]),
                            max = Math.max.apply(Math, [a, b]);
                          return t >= min && t <= max;
                        };
                        // const drawBars = (ctx, x, y) => {
                        //   const drawBar = (ctx, upperLeftCornerX, upperLeftCornerY, width, height,color) => {
                        //       ctx.fillStyle=color;
                        //       console.log(upperLeftCornerX, upperLeftCornerY, width,height)
                        //       ctx.fillRect(upperLeftCornerX, upperLeftCornerY, width,height);
                        //       ctx.fill();

                        //   }
                        //   // var canvasActualHeight = 10
                        //   // var canvasActualWidth = 10
                        //   var barIndex = 0;
                        //   // var numberOfBars = 4
                        //   var barSize = 100
                        //   let colors = ["#a55ca5", "#67b6c7", "#bccd7a", "#eb9743"]
                        //   var values = [12, 14, 15, 12]
                        //   for (let val of values) {
                        //     console.log(val)
                        //     var barHeight = Math.round((10 * barIndex));
                        //     console.log(barHeight);
                            
                        //     drawBar(
                        //       ctx,
                        //       x + (barIndex * 4),
                        //       y,
                        //       barSize,
                        //       barHeight + (barIndex * 4),
                        //       colors[barIndex]
                        //     );
                        //     console.log(barIndex)
                        //     barIndex++;
                        //   }
                        // }
                        const { from, to } = visibleRange;
                        ctx.font = `Helvetica Neue`;
                        ctx.textAlign = "center";
                        const result = indicator.result;
                        console.log("Start Draw: MOMENTUM_ENTRY_POINT")
                        for (let i = from; i < to; i++) {
                          const data = result[i];
                          const x = xAxis.convertToPixel(i);
                          const y = yAxis.convertToPixel(data.emoji);
                          if (data.exist){

                            let colorContainingBox = "rgba(188,188,188,1)"
                            if (data._alert.position_sentiment == "strong_buy"){
                              colorContainingBox = "rgba(140,229,158,0.8)"
                            } else if (data._alert.position_sentiment == "buy"){
                              colorContainingBox = "rgba(85,178,203,1)"
                            } else if(data._alert.position_sentiment == "hold"){
                              colorContainingBox = "rgba(255,255,102,0.8)"
                            }

                            let t_color_map_code = "#71aa00"
                            // '#71aa00'
                            let barCover = 0
                            if(Numbetween(data._alert.neural_model_section_confidence, 90, 100)){
                            barCover = 6
                            t_color_map_code = "#39aa00"
                            }
                            if(Numbetween(data._alert.neural_model_section_confidence, 80, 90)){
                            barCover = 5
                            t_color_map_code = "#39aa00"
                            }
                            if(Numbetween(data._alert.neural_model_section_confidence, 50, 80)){
                            barCover = 4
                            t_color_map_code = "#ffd966"
                            }
                            if(Numbetween(data._alert.neural_model_section_confidence, 30, 50)){
                            barCover = 3
                            t_color_map_code = "#bc5008"
                            }
                            if(Numbetween(data._alert.neural_model_section_confidence, 20, 30)){
                            barCover = 3
                            t_color_map_code = "#bc5008"
                            }
                            if(Numbetween(data._alert.neural_model_section_confidence, -1, 20)){
                            barCover = 2
                            t_color_map_code = "#aa2200"
                            }

                            // Tag Based
                            if (data._alert._day_bar_pos <= 0 || data._alert.neural_model_section_confidence > 80){
                              let side = 1
                              ctx.font = this.new_font
                              let textToRender = `${position_sentimentFormatting(data._alert.position_sentiment)}\n
                                                  Confidence: ${data._alert.dynamic_model_metrics.winrate_precent}%\n
                                                  Return %: ${data._alert.returnpct}\n
                                                  Profit Gain Precent %: ${data._alert.profit_gain_precent_threshold}\n
                                                  Neural Model Winrate Precent %: ${data._alert.neural_model_section_confidence}`
                              textToRender = textToRender.replace(/ +/g,' ')
                              let aboveBarInitHeight = 70
                              let aboveBarInitHeight_gdd = 70
                              // Draw the containing box
                              ctx.fillStyle = colorContainingBox
                              ctx.beginPath()
                              ctx.moveTo(x, y - aboveBarInitHeight_gdd)

                              let height_top = 30
                              let bottom_x = 20
                              let top_x = 20
                              ctx.lineTo(x - bottom_x * side, y - 20 - aboveBarInitHeight_gdd)
                              ctx.lineTo(x - (top_x), y - 30 - height_top - aboveBarInitHeight_gdd)
                              ctx.lineTo(x + (top_x), y - 30 - height_top - aboveBarInitHeight_gdd)
                              ctx.lineTo(x + bottom_x * side, y - 20 - aboveBarInitHeight_gdd)
                              ctx.closePath()
                              // ctx.fill()
                              ctx.lineWidth = 1;
                              ctx.strokeStyle = '#5B5B5B';
                              ctx.stroke();
                                                            



                              ctx.fillStyle = 'grey';
                              // const randomItem = (arr) => {
                              //   return arr[Math.floor((Math.random()*arr.length))];
                              // }
                              let bars_offsetX = 20
                              let bars_offsetY = 25
                              let colormap = ['#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB']


                              // console.log(barCover)
                              for (let br of Array(barCover).keys() ){
                                // console.log(br)
                                colormap[br] = t_color_map_code;
                              }
                              // console.log(colormap)

                              let count = 0
                              for(let i = 0; i < 30; i+=5){
                                  // console.log(colormap[count], count)
                                  ctx.fillStyle = colormap[count];
                                  count = (count + 1)
                                  ctx.fillRect((x + i) - bars_offsetX , y - bars_offsetY * side - aboveBarInitHeight,4.5,-1 * i);
                              }
                              // Draw the Text
                              if (data._alert.position_sentiment == "strong_buy"){
                                ctx.fillStyle = "rgba(141,125,202,0.8)"
                              } else if (data._alert.position_sentiment == "buy"){
                                ctx.fillStyle = "rgba(255,255,255,0.8)"
                              } else if(data._alert.position_sentiment == "hold"){
                                ctx.fillStyle = "rgba(141,125,202,0.8)"
                              }
                              
                              ctx.font = "18px Roboto";
                              if (localStorage.getItem("dev") == "1"){
                                ctx.fillStyle = "rgba(0,0,0,1)"
                                // fillTextMultiLine(ctx, textToRender, x + 10 * side, y + 0 * side - aboveBarInitHeight)
                                // fillTextMultiLine(ctx, data._alert.neural_model_section_confidence.toFixed(2).toString() + "%", x - 20, y - aboveBarInitHeight - 100)
                                fillTextMultiLine(ctx, "BUY", x - 10, y - aboveBarInitHeight - 80)
                                ctx.fillStyle = colorContainingBox
                              } else {
                                fillTextMultiLine(ctx, "BUY", x - 10, y - aboveBarInitHeight - 80)
                              }
                              ctx.fillStyle = this.label_color
                              ctx.textAlign = side < 0 ? 'right' : 'left'

                              // Draw the legs
                              ctx.beginPath()
                              ctx.lineWidth = 1
                              ctx.moveTo(x, y)
                              ctx.lineTo(x, y - aboveBarInitHeight)
                              ctx.stroke();
                            } else {
                              ctx.beginPath();
                              ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
                              ctx.fillStyle = t_color_map_code;
                              ctx.fill();
                              // ctx.fillText(data.text, x, y + 50);
                            }
                          }
                        }
                        return false;
                      },
                    });
                    
                    registerIndicator({
                      name: "MOMENTUM_EXIT_POINT",
                      shortName: "",
                      figures: [{ key: "momentum_exit_point" }],
                      calc: (kLineDataList) => {
                        // console.log(this.xhrData.signals.sell, kLineDataList)
                        let _r = {}
                        
                        Object.keys(this.xhrData.signals.sell).map((alert) => {
                          _r[parseInt(this.xhrData.signals.sell[alert].exittime_as_epoch * 1000)] = {
                            emoji: this.xhrData.signals.sell[alert].exittime_as_epoch,
                            _alert: this.xhrData.signals.sell[alert],
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })

                        return kLineDataList.map((kLineData) => {
                          let _exist = Object.keys(_r).indexOf(kLineData.timestamp.toString()) > -1
                          return {
                            emoji: kLineData.close,
                            exist: _exist,
                            _alert: _exist ? _r[kLineData.timestamp.toString()]._alert: null,
                            text: _exist ? fruits[Math.floor(Math.random() * 17)]: "",
                          }
                        });
                      },
                      draw: ({ ctx, barSpace, visibleRange, indicator, xAxis, yAxis }) => {
                        const fillTextMultiLine = (ctx, text, x, y) => {
                            var lineHeight = ctx.measureText("M").width * 1.2;
                            var lines = text.split("\n");
                            for (var i = 0; i < lines.length; ++i) {
                                ctx.fillText(lines[i], x, y);
                                y += lineHeight;
                            }
                        }
                        const { from, to } = visibleRange;
                        ctx.font = `${barSpace.gapBar * 4}px Helvetica Neue`;
                        ctx.textAlign = "center";
                        const result = indicator.result;
                        for (let i = from; i < to; i++) {
                          const data = result[i];
                          const x = xAxis.convertToPixel(i);
                          const y = yAxis.convertToPixel(data.emoji);

                          if (data.exist){
                            if (data._alert._day_bar_pos <= 0){
                            let side = 1
                            ctx.font = this.new_font
                            let w = ctx.measureText("STOP LOSS").width + 50
                            let aboveBarInitHeight = 0
                            ctx.fillStyle = "rgba(244,67,54,0.8)"
                            ctx.beginPath()
                            ctx.moveTo(x, y - aboveBarInitHeight)
                            ctx.lineTo(x + 10 * side, y - 10 * side - aboveBarInitHeight)
                            ctx.lineTo(x + (w + 10) * side, y - 10 * side - aboveBarInitHeight)
                            ctx.lineTo(x + (w + 10) * side, y + 10 * side - aboveBarInitHeight)
                            ctx.lineTo(x + 10 * side, y + 10 * side - aboveBarInitHeight)

                            
                            ctx.closePath()
                            ctx.fill()
                            
                            ctx.fillStyle = "rgba(255,255,255,0.8)"
                            ctx.font = "16px Arial";
                            fillTextMultiLine(ctx, "STOP LOSS", x + 30 * side, y + 0 * side - aboveBarInitHeight)

                            ctx.fillStyle = this.label_color
                            ctx.textAlign = side < 0 ? 'right' : 'left'

                            ctx.beginPath()
                            ctx.lineWidth = 1
                            ctx.fillStyle = "rgba(111,11,158,0.8)"
                            ctx.moveTo(x, y)
                            ctx.lineTo(x, y - aboveBarInitHeight)
                            ctx.stroke();
                            // ctx.fillText(data.text, x, y + 50);
                            } else {
                              ctx.beginPath();
                              ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
                              ctx.fillStyle = "rgba(197,48,48,0.7)";
                              ctx.fill();
                              // ctx.fillText(data.text, x, y + 50);
                            }
                          }
                        }
                        return false;
                      },
                    });
                    
                    registerIndicator({
                      name: "MODULE_HIGH_LOWS_PEAKS",
                      shortName: "",
                      figures: [{ key: "momentum_exit_point" }],
                      calc: (kLineDataList) => {
                        // console.log(this.xhrData.signals.sell, kLineDataList)
                        let _r = {}
                        
                        Object.keys(this.xhrData.modules.high_lows_peaks.highs.c).map((alert) => {
                          // console.log(alert, parseInt(this.xhrData.data.t[alert]))
                          _r[parseInt(this.xhrData.data.t[alert])] = {
                            emoji: this.xhrData.data.t[alert],
                            _alert: {"type": "highs"},
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })

                        Object.keys(this.xhrData.modules.high_lows_peaks.lows.c).map((alert) => {
                          // console.log(alert, parseInt(this.xhrData.data.t[alert]))
                          _r[parseInt(this.xhrData.data.t[alert])] = {
                            emoji: this.xhrData.data.t[alert],
                            _alert: {"type": "lows"},
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })
                        console.log(_r)
                        return kLineDataList.map((kLineData) => {
                          // console.log(Object.keys(_r)[0], kLineData.timestamp.toString())
                          let _exist = Object.keys(_r).indexOf(kLineData.timestamp.toString()) > -1
                          return {
                            emoji: kLineData.close,
                            exist: _exist,
                            _alert: _exist ? _r[kLineData.timestamp.toString()]._alert: null,
                          }
                        });
                      },
                      draw: ({ ctx, barSpace, visibleRange, indicator, xAxis, yAxis }) => {
                        const { from, to } = visibleRange;
                        ctx.font = `${barSpace.gapBar * 4}px Helvetica Neue`;
                        ctx.textAlign = "center";
                        const result = indicator.result;
                        for (let i = from; i < to; i++) {
                          const data = result[i];
                          const x = xAxis.convertToPixel(i);
                          const y = yAxis.convertToPixel(data.emoji);

                          if (data.exist == true){
                            ctx.font = this.new_font
                            let aboveBarInitHeight = 0
                            
                            if (data._alert["type"] == "highs"){
                              ctx.strokeStyle  = "rgba(56,161,105,1)"
                            }
                            if (data._alert["type"] == "lows"){
                              ctx.strokeStyle  = "rgba(244,67,54,1)"
                            }
                            console.log(data._alert["type"], ctx.strokeStyle )
                            ctx.beginPath();
                            // Set our fancy properties
                            ctx.lineWidth = '2';
                            ctx.lineCap = 'round';
                            ctx.moveTo(x, y + aboveBarInitHeight);
                            ctx.lineTo(x + 50, y + aboveBarInitHeight);
                            ctx.stroke();
                            }
                        }
                        return false;
                      },
                    });

                    registerIndicator({
                      name: "MODULE_OPTION_MARKER",
                      shortName: "",
                      figures: [{ key: "momentum_exit_point" }],
                      calc: (kLineDataList) => {
                        // console.log(this.xhrData.signals.sell, kLineDataList)
                        let _r = {}
                        
                        Object.keys(this.xhrData.modules.options_marker.calls.c).map((alert) => {
                          // console.log(alert, parseInt(this.xhrData.data.t[alert]))
                          _r[parseInt(this.xhrData.data.t[alert])] = {
                            emoji: this.xhrData.data.t[alert],
                            _alert: {"type": "calls"},
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })

                        Object.keys(this.xhrData.modules.options_marker.puts.c).map((alert) => {
                          // console.log(alert, parseInt(this.xhrData.data.t[alert]))
                          _r[parseInt(this.xhrData.data.t[alert])] = {
                            emoji: this.xhrData.data.t[alert],
                            _alert: {"type": "puts"},
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })
                        return kLineDataList.map((kLineData) => {
                          // console.log(Object.keys(_r)[0], kLineData.timestamp.toString())
                          let _exist = Object.keys(_r).indexOf(kLineData.timestamp.toString()) > -1
                          return {
                            emoji: kLineData.close,
                            exist: _exist,
                            _alert: _exist ? _r[kLineData.timestamp.toString()]._alert: null,
                          }
                        });
                      },
                      draw: ({ ctx, barSpace, visibleRange, indicator, xAxis, yAxis }) => {
                        const { from, to } = visibleRange;
                        const fillTextMultiLine = (ctx, text, x, y) => {
                            var lineHeight = ctx.measureText("M").width * 0.7;
                            var lines = text.split("\n");
                            for (var i = 0; i < lines.length; ++i) {
                                ctx.fillText(lines[i].trim(), x, y);
                                y += lineHeight;
                            }
                        }
                        ctx.font = `${barSpace.gapBar * 4}px Helvetica Neue`;
                        ctx.textAlign = "center";
                        const result = indicator.result;
                        for (let i = from; i < to; i++) {
                          const data = result[i];
                          const x = xAxis.convertToPixel(i);
                          const y = yAxis.convertToPixel(data.emoji);

                          if (data.exist == true){
                            ctx.font = this.new_font
                            let aboveBarInitHeight = 0
                            let textToRender = "C"
                            
                            if (data._alert["type"] == "calls"){
                              ctx.fillStyle  = "rgba(56,161,105,1)"
                              textToRender = "CALLS"
                            }
                            if (data._alert["type"] == "puts"){
                              ctx.fillStyle  = "rgba(244,67,54,1)"
                              textToRender = "PUTS"
                            }
                            console.log(data._alert["type"], ctx.strokeStyle )
                            
                            let side = 1
                              ctx.font = this.new_font
                              let w = ctx.measureText(textToRender).width + 50

                              // Draw the containing box
                              ctx.beginPath()
                              ctx.moveTo(x, y - aboveBarInitHeight)
                              ctx.lineTo(x + 10 * side, y - 10 * side - aboveBarInitHeight)
                              ctx.lineTo(x + (w + 10) * side, y - 10 * side - aboveBarInitHeight)
                              ctx.lineTo(x + (w + 10) * side, y + 10 * side - aboveBarInitHeight)
                              ctx.lineTo(x + 10 * side, y + 10 * side - aboveBarInitHeight)
                              ctx.closePath()
                              ctx.fill()
                              
                              ctx.font = "16px Arial";
                              ctx.fillStyle = "#FFFFFF"
                              ctx.textAlign = side < 0 ? 'right' : 'left'
                              fillTextMultiLine(ctx, textToRender, x + 30 * side, y + 0 * side - aboveBarInitHeight)
                              
                              // Draw the legs
                              ctx.beginPath()
                              ctx.lineWidth = 1
                              ctx.moveTo(x, y)
                              ctx.lineTo(x, y - aboveBarInitHeight)
                              ctx.stroke();

                            }
                        }
                        return false;
                      },
                    });

                    const zip = (a, b) => a.map((k, i) => [k, b[i]]);
                    
                    registerIndicator({
                      name: 'BP_SMA',
                      shortName: 'Barpot SMA',
                      calcParams: [],
                      precision: 2,
                      shouldOhlc: true,
                      figures: [
                        { key: 'ShortSMA', title: 'ShortSMA: ', type: 'line' },
                        { key: 'LongSMA', title: 'LongSMA: ', type: 'line' },
                      ],
                      calc: () => {
                        return zip(Object.values(this.xhrData.data.LongSMA).reverse(), Object.values(this.xhrData.data.ShortSMA).reverse()).map((kLineData) => {
                          const ma = {}
                          ma["LongSMA"] = kLineData[0]
                          ma["ShortSMA"] = kLineData[1]
                          return ma
                        })
                      }
                    })

                    registerIndicator({
                      name: 'BP_MACD',
                      shortName: 'Barpot MACD',
                      calcParams: [],
                      shouldOhlc: true,
                      figures: [
                        { key: 'dif', title: 'DIF: ', type: 'line' },
                        { key: 'dea', title: 'DEA: ', type: 'line' },
                        {
                          key: 'macd',
                          title: 'MACD: ',
                          type: 'bar',
                          baseValue: 0,
                          styles: (data, indicator, defaultStyles) => {
                            const { prev, current } = data
                            const prevMacd = prev.indicatorData?.macd ?? Number.MIN_SAFE_INTEGER
                            const currentMacd = current.indicatorData?.macd ?? Number.MIN_SAFE_INTEGER
                            let color = ""
                            if (currentMacd > 0) {
                              color = formatValue(indicator.styles, 'bars[0].upColor', (defaultStyles.bars)[0].upColor)
                            } else if (currentMacd < 0) {
                              color = formatValue(indicator.styles, 'bars[0].downColor', (defaultStyles.bars)[0].downColor)
                            } else {
                              color = formatValue(indicator.styles, 'bars[0].noChangeColor', (defaultStyles.bars)[0].noChangeColor)
                            }
                            const style = prevMacd < currentMacd ? PolygonType.Stroke : PolygonType.Fill
                            return { style, color, borderColor: color }
                          }
                        }
                      ],
                      calc: () => {
                        return zip(Object.values(this.xhrData.data.macd_values).reverse(), Object.values(this.xhrData.data.macd_signal).reverse(), Object.values(this.xhrData.data.macd_hist).reverse()).map((kLineData) => {
                          const tt = {}
                          tt["dif"] = kLineData[0]
                          tt["dea"] = kLineData[1]
                          tt["macd"] = kLineData[2]
                          return tt
                        })
                      }
                    })

                    registerIndicator({
                      name: "MOMENTUM_ENTRY_BLOCKS_PROFIT_ZONE",
                      shortName: "",
                      figures: [{ key: "momentum_entry_blocks_profit_zone" }],
                      calc: (kLineDataList) => {
                        // console.log(this.xhrData.signals.sell, kLineDataList)
                        let _r = {}
                        
                        Object.keys(this.xhrData.signals.sell).map((alert) => {
                          _r[parseInt(this.xhrData.signals.sell[alert].exittime_as_epoch * 1000)] = {
                            emoji: this.xhrData.signals.sell[alert],
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })

                        return kLineDataList.map((kLineData) => {
                          let _exist = Object.keys(_r).indexOf(kLineData.timestamp.toString()) > -1
                          return {
                            emoji: kLineData.close,
                            sig: _r[kLineData.timestamp.toString()],
                            exist: _exist,
                            text: _exist ? fruits[Math.floor(Math.random() * 17)]: "",
                          }
                        });
                      },
                      draw: ({ ctx, barSpace, visibleRange, indicator, xAxis, yAxis }) => {

                        const { from, to } = visibleRange;
                        ctx.font = `${barSpace.gapBar * 4}px Helvetica Neue`;
                        ctx.textAlign = "center";
                        const result = indicator.result;
                        for (let i = from; i < to; i++) {
                          const data = result[i];

                          if (data.exist){
                            console.log(data)
                          ctx.font = this.new_font

                          ctx.lineWidth = 60
                              ctx.strokeStyle = "#ff4c7c"
                              ctx.fillStyle = "rgba(255,232,166,0.3)"
                              ctx.beginPath()
                              const xTop = xAxis.convertToPixel(i);
                              const yTop = yAxis.convertToPixel(data.emoji);
                              ctx.rect(xTop, yTop, 60, 100);
                              ctx.fill()

                          }
                        }
                        return false;
                      },
                    });
                    
                    registerIndicator({
                      name: "COMPOSER_ENTRY_BLOCKS_PROFIT_ZONE",
                      shortName: "",
                      figures: [{ key: "composer_entry_blocks_profit_zone" }],
                      calc: (kLineDataList) => {
                        // console.log(this.xhrData.signals.buy, kLineDataList)
                        let _r = {}
                        let activeDataPoint = this.xhrData.backtest_results_model_based.orderbook
                        Object.keys(activeDataPoint).map((alert) => {
                          _r[parseInt(activeDataPoint[alert].entrytime * 1000)] = {
                            emoji: activeDataPoint[alert].entrytime,
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })

                        return kLineDataList.map((kLineData) => {
                          let _exist = Object.keys(_r).indexOf(kLineData.timestamp.toString()) > -1
                          return {
                            emoji: kLineData.close,
                            exist: _exist,
                            text: _exist ? fruits[Math.floor(Math.random() * 17)]: "",
                          }
                        });
                      },
                      draw: ({ ctx, visibleRange, indicator, xAxis, yAxis }) => {
                        const { from, to } = visibleRange;
                        const result = indicator.result;
                        for (let i = from; i < to; i++) {
                          const data = result[i];
                          if (data.exist){
                              ctx.lineWidth = 60
                              ctx.strokeStyle = "#ff4c7c"
                              ctx.fillStyle = "rgba(255,139,148,0.3)"
                              ctx.beginPath()
                              const xTop = xAxis.convertToPixel(i);
                              const yTop = yAxis.convertToPixel(data.emoji);
                              ctx.rect(xTop - 15, yTop, 30, -100);
                              ctx.fill()
                              
                              ctx.beginPath()
                              ctx.fillStyle = "rgba(17,114,58,0.3)"
                              const xBottom = xAxis.convertToPixel(i);
                              const yBottom = yAxis.convertToPixel(data.emoji);
                              ctx.rect(xBottom - 15, yBottom, 30, 100);
                              ctx.fill()
                          }
                        }
                          
                        return false;
                      },
                    });

                    registerIndicator({
                      name: "COMPOSER_MOMENTUM_ENTRY_POINT",
                      shortName: "",
                      figures: [{ key: "composer_momentum_entry_point" }],
                      calc: (kLineDataList) => {
                        // console.log(this.xhrData.signals.buy, kLineDataList)
                        let _r = {}
                        let activeDataPoint = this.xhrData.backtest_results_model_based.orderbook
                        Object.keys(activeDataPoint).map((alert) => {
                          _r[parseInt(activeDataPoint[alert].entrytime * 1000)] = {
                            emoji: activeDataPoint[alert].entrytime,
                            text: fruits[Math.floor(Math.random() * 17)],
                          }
                        })

                        return kLineDataList.map((kLineData) => {
                          let _exist = Object.keys(_r).indexOf(kLineData.timestamp.toString()) > -1
                          return {
                            emoji: kLineData.close,
                            exist: _exist,
                            text: _exist ? fruits[Math.floor(Math.random() * 17)]: "",
                          }
                        });
                      },
                      draw: ({ ctx, barSpace, visibleRange, indicator, xAxis, yAxis }) => {
                        const fillTextMultiLine = (ctx, text, x, y) => {
                            var lineHeight = ctx.measureText("M").width * 1.2;
                            var lines = text.split("\n");
                            for (var i = 0; i < lines.length; ++i) {
                                ctx.fillText(lines[i], x, y);
                                y += lineHeight;
                            }
                        }
                        const { from, to } = visibleRange;
                        ctx.font = `${barSpace.gapBar * 4}px Helvetica Neue`;
                        ctx.textAlign = "center";
                        const result = indicator.result;
                        for (let i = from; i < to; i++) {
                          const data = result[i];
                          const x = xAxis.convertToPixel(i);
                          const y = yAxis.convertToPixel(data.emoji);

                          if (data.exist){
                          let side = 1
                          ctx.font = this.new_font
                          let w = ctx.measureText("buy").width + 50
                          let aboveBarInitHeight = 120
                          ctx.fillStyle = "rgba(140,229,158,0.8)"
                          ctx.beginPath()
                          ctx.moveTo(x, y - aboveBarInitHeight)
                          ctx.lineTo(x + 10 * side, y - 10 * side - aboveBarInitHeight)
                          ctx.lineTo(x + (w + 10) * side, y - 10 * side - aboveBarInitHeight)
                          ctx.lineTo(x + (w + 10) * side, y + 10 * side - aboveBarInitHeight)
                          ctx.lineTo(x + 10 * side, y + 10 * side - aboveBarInitHeight)

                          
                          ctx.closePath()
                          ctx.fill()
                          
                          ctx.fillStyle = "rgba(141,125,202,0.8)"
                          ctx.font = "16px Arial";
                          fillTextMultiLine(ctx, "Buy", x + 30 * side, y + 0 * side - aboveBarInitHeight)

                          ctx.fillStyle = this.label_color
                          ctx.textAlign = side < 0 ? 'right' : 'left'

                          ctx.beginPath()
                          ctx.lineWidth = 1
                          ctx.fillStyle = "rgba(111,11,158,0.8)"
                          ctx.moveTo(x, y)
                          ctx.lineTo(x, y - aboveBarInitHeight)
                          ctx.stroke();
                          // ctx.fillText(data.text, x, y + 50);
                          }
                        }
                        return false;
                      },
                    });

                    this.paneId = this.chart.createIndicator("MACD", true, { id: this.paneId });

                    if (!this.xhrData.model_compute.is_model_computed){
                      console.log("CREATEINDICATOR: MOMENTUM_ENTRY_POINT")
                      this.chart.createIndicator("MOMENTUM_ENTRY_POINT", true, { id: "candle_pane" });
                      // this.chart.createIndicator("MOMENTUM_ENTRY_BLOCKS_PROFIT_ZONE", true, { id: "candle_pane" });
                    }
                    if (this.xhrData.model_compute.is_model_computed){
                      this.chart.createIndicator("COMPOSER_MOMENTUM_ENTRY_POINT", true, { id: "candle_pane" });
                      // this.chart.createIndicator("COMPOSER_ENTRY_BLOCKS_PROFIT_ZONE", true, { id: "candle_pane" });
                    }
                    // this.chart.createIndicator("MOMENTUM_ENTRY_BLOCKS", true, { id: "candle_pane" });
                    this.chart.createIndicator("BP_SMA", true, { id: "candle_pane" });
                    // End
    },
    async populateChart(symbol, timestamp_from, timestamp_to, discord_client_id, access_token){

            let urlBuild = new URL(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/ticker`)
            urlBuild.searchParams.set("symbol", this.propSymbol)
            urlBuild.searchParams.set("timestamp_from", timestamp_from)
            urlBuild.searchParams.set("timestamp_to", timestamp_to)
            urlBuild.searchParams.set("discord_client_id", discord_client_id)
            urlBuild.searchParams.set("access_token", access_token)
            urlBuild.searchParams.set("stock_model_id", this.userParams.paramStockModelId)
            await fetch(urlBuild.toString(), {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
                },
                body: JSON.stringify({}),
                }).then(response => {
                    if(response.status == 401 || response.status == 403){
                        alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                        window.location = "/dashboard"
                        return null
                    }
                    return response.json()
                })
                .then(resp_data => {
                  this.chartTitleText = `${symbol.toUpperCase()}`;
                    console.log("Rest INIT doChartPopulation")
                    this.doChartPopulation(resp_data)
                });

      }
  },
  unmounted: function () {
    dispose("indicator-k-line");
  },
};
</script>

<template>
  <div class="k-line-chart-container">
    <div class="flex flex-row items-center text-center justify-between mb-1">
      <div class="flex flex-row items-center text-center divide-x">
        <div class="flex flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center" v-on:click="navToHome()">
            <img v-on:click="handleHome" class="block lg:hidden h-8 w-auto" src="/images/logos/barpot_logo_only_w_text.svg" alt="BarPot">
          </div>
        </div>
        <div class="flex flex items-center justify-center">
          <h3 class="k-line-chart-title uppercase px-4"><span class="bg-green-100 text-green-800 text-sm font-medium mr-2 p-2 rounded dark:bg-green-900 dark:text-green-300">${{ this.propSymbol }}</span></h3>
        </div>
      </div>
      <!-- <div class="flex flex-row items-center text-center w-6/12	overflow-x-hidden	">
        <HomeReportingSlider />
      </div> -->
      <div class="flex flex-row items-center text-center">
        <button
          type="button"
          data-te-ripple-init
          data-te-ripple-color="light"
          class="flex items-center text-center inline-block rounded p-2 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
          style="background-color: #1877f2"
          v-on:click="copyNavLink(this.propSymbol)">
            <span style="padding-right: 10px">Share Link: </span>
            <span class="material-symbols-outlined">
              share
            </span>
          </button>
        </div>
    </div>
    <div v-if="this.xhrData == null" class="flex flex-col h-screen justify-center text-center items-center">
        <fingerprint-spinner
            :animation-duration="2000"
            :size="160"
            color="#0051d8"
        />
        <hr class="my-4"/>
        <p class="mt-2 text-center text-2xl text-gray-600">Loading<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></p>
    </div>
    <div v-if="this.xhrData != null" class="flex flex-col">
      <div v-if="this.xhrData.model_compute.is_model_computed == true && this.xhrData.model_compute.is_processing == false && this.xhrData.model_compute.is_processed == false" class="flex flex-col mt-48  justify-center text-center items-center">
        <orbit-spinner
          :animation-duration="1200"
          :size="160"
          color="#ff1d5e"
        />
        <p class="mt-2 text-center text-2xl text-gray-600">Your Model Exploration is Queued<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></p>
      </div>
      <div v-if="this.xhrData.model_compute.is_model_computed == true && this.xhrData.model_compute.is_processing == true" class="flex flex-col mt-48 justify-center text-center items-center">
        <orbit-spinner
          :animation-duration="1200"
          :size="160"
          color="#48bb78"
        />
        <p class="mt-2 text-center text-2xl text-gray-600 mt-8">Your Model Exploration is Processing<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></p>
        <p class="mt-2 text-center text-2xl text-gray-600"><span class="underline">Typically Takes a Few Minutes</span></p>
      </div>
    </div>
    <div id="indicator-k-line" class="k-line-chart" />
    <div class="k-line-chart-menu-container hidden">
      <span style="padding-right: 10px">{{this.propSymbol}}</span>
      <button
        v-for="type in mainIndicators"
        :key="type"
        v-on:click="setMainIndicator(type)"
      >
        {{ type }}
      </button>
      <button v-on:click="setMainIndicator('EMOJI')">EMOJI</button>
      <button v-on:click="setMainIndicator('BLOCKS')">BLOCKS</button>
      <span style="padding-right: 10px; padding-left: 12px">副图指标</span>
      <button
        v-for="type in subIndicators"
        :key="type"
        v-on:click="setSubIndicator(type)"
      >
        {{ type }}
      </button>
      <button v-on:click="setSubIndicator('EMOJI')">自定义</button>
    </div>
  <slot></slot>
  </div>
</template>

<style>
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  width: 98%;
  height: 98vh;
  padding: 16px 6px 16px 16px;
}
.k-line-chart-title {
  margin: 0;
  color: #252525;
}
.k-line-chart {
  display: flex;
  flex: 1;
}
.k-line-chart-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #606060;
}
.k-line-chart-menu-container button {
  cursor: pointer;
  background-color: #1677ff;
  border-radius: 2px;
  margin-right: 8px;
  height: 24px;
  line-height: 26px;
  padding: 0 6px;
  font-size: 12px;
  color: #fff;
  border: none;
  outline: none;
}
</style>