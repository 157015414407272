<script setup>
import {defineProps } from 'vue';

import { Handle, Position } from '@vue-flow/core'
import { NodeResizer } from '@vue-flow/node-resizer'

const props = defineProps(['label'])
// const toProperCase = (_string) => {
//     return _string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
// };

// const labelTitlized = toProperCase(props.label.split("-").join(" "))
</script>

<template>
<div>
  <NodeResizer min-width="300" min-height="300" />

  <div style="padding: 10px">{{ props.label }}</div>
  <Handle type="source" :position="Position.Bottom" />
  </div>
</template>

<style>
.vue-flow__handle-left {
  display: none;
}

.vue-flow__handle-right {
  display: none;
}
</style>
