<script>
import { dispose, init, registerIndicator, utils } from "klinecharts";
import { FingerprintSpinner } from 'epic-spinners';
import chartTimePeriodSelectors from '../components/chartTimePeriodSelectors'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

let currPath = new URL(window.location.href);
export default {
  name: "BacktestChart",
  components: { FingerprintSpinner, chartTimePeriodSelectors},
  props: ['propData', 'activeTab'],
  watch: {
    propData: function(newVal, oldVal) { // watch it
      console.log('Prop propData changed: ', newVal, ' | was: ', oldVal)
      if(this.propData !== null){
        // MAIN DATA MAPPINGS
        this.xhrData = this.propData;
        this.chart = init("indicator-k-line", {
          timezone: 'Pacific/Tongatapu'
        });

        this.doChartPopulation(this.propData)
        if(this.propData?.data?.main?.data.length <= 0){
          this.isEmptyData = true
        } else {
          this.isEmptyData = false
        }
      }
    },
    activeTab: function(newVal, oldVal) { // watch it
      console.log('Prop activeTab changed: ', newVal, ' | was: ', oldVal)
      if(this.activeTab !== null){
        this.prepChartBeforeApply()
        this.doChartPopulation(this.propData)
      }
    },
  },
  data: function () {
    
    let paramSymbol = this.propData // currPath.searchParams.get("symbol");
    let paramStockSymbol = currPath.searchParams.get("symbol");
    let paramTimestampFrom = currPath.searchParams.get("timestamp_from");
    let paramTimestampTo = currPath.searchParams.get("timestamp_to");
    let paramDiscordClientId = currPath.searchParams.get("discord_client_id");
    let paramIsDev = currPath.searchParams.get("dev") == "true" ? true : false;
    let paramAccessToken = currPath.searchParams.get("access_token");
    let paramStockModelId = currPath.searchParams.get("stock_model_id");

    return {
      mainIndicators: ["MA", "EMA", "SAR"],
      subIndicators: ["VOL", "MACD", "KDJ"],
      userParams: {
        paramDiscordClientId,
        paramIsDev,
        paramStockSymbol,
        paramSymbol,
        paramTimestampFrom,
        paramTimestampTo,
        paramAccessToken,
        paramStockModelId
      },
      xhrData: null,
      xhrIsProcessing: false,
      isEmptyData: false,
      defaultTimePeriodConfiguration: {
        "14D": {value: "14d"},
        "1M": {value: "1m"},
        "3M": {value: "3m"},
        "YTD": {value: "ytd"}
      }
    };
  },
  mounted: function () {
    
    // this.getPortfolio();

  },
  methods: {
    prepChartBeforeApply(){
      this.chart.removeIndicator("candle_pane", "BP_PAPER_SMA")
      this.chart.removeIndicator("candle_pane", "BP_SMA")
    },
    setMainIndicator: function (name) {
      this.chart.createIndicator(name, false, { id: "candle_pane" });
    },
    setSubIndicator: function (name) {
      this.chart.createIndicator(name, false, { id: "oscillator_pane" });
    },
    navToHome(){
      window.location = "/product/plays_stocks"
    },
    copyNavLink(symbol){
      fetch(`https://barstool-backend-c6rnmqev2q-ue.a.run.app/api/tmp_free_view?symbol=${symbol}`, {
        method: 'GET', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
            
        },
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
          navigator.clipboard.writeText(data.url).then(function() {
          $toast.open({
            message: symbol + " - Link Copied, Share it with the world!" + ' 🚀',
            type: 'success',
            duration: 10000,
            position: 'bottom'
            // all of other options may go here
          });
      }, function() {
          console.log('Copy error')
      });
      });
    },
    chartInitAsPaperTradingFlow(resp_data){
      return resp_data.investment.trade_journal.analysis.sell_percent_return_change.cumulative_pct_timestamp.map((i, idx) => {
                      return {
                        timestamp: resp_data.investment.trade_journal.analysis.sell_percent_return_change.cumulative_pct_timestamp[idx] * 1000,
                        open: 0,
                        high: 0,
                        low: 0,
                        close: 0,
                        volume: (resp_data.investment.trade_journal.analysis.sell_percent_return_change.cumulative_pct)[idx]
                      }
                  })
    },
    chartRegistersIndicatorPaperTrading(resp_data){
      registerIndicator({
                    name: 'BP_PAPER_SMA',
                    shortName: "📄 Paper Trading" + " :",
                    calcParams: [],
                    precision: 3,
                    shouldOhlc: true,
                    figures: [
                      { key: 'Current', title: "Current: ", type: 'line'},
                      ...Object.keys(resp_data.data.benchmarks).map((item) => {
                          return {key: item, title: resp_data.data.benchmarks[item].name + ": ", type: 'line' }
                        }
                        )
                    ],
                    calc: () => {
                      
                      let renderdata = []
                      
                      // For BEnch MArks
                      // Object.keys(resp_data.data.benchmarks).map((item) => {
                      //     resp_data.data.benchmarks[item].data.map((nItem, idx) => {
                      //       renderdata.push({[item]: nItem, "Current": resp_data.data.main.data[idx]})
                      //     })
                      // })
                      // cumulative_pct || pct_values
                      resp_data.investment.trade_journal.analysis.sell_percent_return_change.cumulative_pct.map((mitem) => {
                          renderdata.push({"Current": mitem})
                      })
                      return renderdata
                    }
                  })
      return
    },
    chartInitAsBacktestFlow(resp_data){
      if (resp_data.data.main.timestamp.length <= 0){
        return
      }
      return resp_data.data.main.timestamp.map((i, idx) => {
                      return {
                        timestamp: resp_data.data.main.timestamp[idx],
                        open: 0,
                        high: 0,
                        low: 0,
                        close: 0,
                        volume: (resp_data.agg.portfolio[resp_data.id].cumulative_return_pct_raw)[idx]
                      }
                    
                  })
    },
    chartRegistersIndicatorBacktest(resp_data){
      registerIndicator({
                    name: 'BP_SMA',
                    shortName: "👾 Backtest - " + resp_data.name + " :",
                    calcParams: [],
                    precision: 3,
                    shouldOhlc: true,
                    figures: [
                      { key: 'Current', title: "Current: ", type: 'line'},
                      ...Object.keys(resp_data.data.benchmarks).map((item) => {
                          return {key: item, title: resp_data.data.benchmarks[item].name + ": ", type: 'line' }
                        }
                        )
                    ],
                    calc: () => {
                      
                      let renderdata = []
                      
                      // For BEnch MArks
                      // Object.keys(resp_data.data.benchmarks).map((item) => {
                      //     resp_data.data.benchmarks[item].data.map((nItem, idx) => {
                      //       renderdata.push({[item]: nItem, "Current": resp_data.data.main.data[idx]})
                      //     })
                      // })

                      resp_data.data.main.data.map((mitem) => {
                        renderdata.push({"Current": mitem})
                      })
                      // console.log("FINAL:", renderdata )
                      return renderdata
                    }
                  })
      return
    },
    doChartPopulation(resp_data){
      
      // cache the data to memory
      this.chartTitleText = "AAAA"// `${symbol.toUpperCase()}`;

        if (this.activeTab.id == 'backtest'){
          console.log("backtest")
          this.chart.applyNewData(this.chartInitAsBacktestFlow(resp_data));  
          this.chartRegistersIndicatorBacktest(resp_data)
          
          // Apply "backtest" Indicators
          this.chart.createIndicator("BP_SMA", false, { id: "candle_pane" });
        }
        if (this.activeTab.id == 'paper_trading'){
          console.log("paper_trading")
          this.chart.applyNewData(this.chartInitAsPaperTradingFlow(resp_data));
          this.chartRegistersIndicatorPaperTrading(resp_data)
          // Apply "paper_trading" Indicators
          this.chart.createIndicator("BP_PAPER_SMA", false, { id: "candle_pane" });
        }
                 

      // General Indicators
      registerIndicator({
        name: 'TRD_VOL',
        shortName: 'P/L Oscillator',
        // series: IndicatorSeries.Volume,
        calcParams: [],// [5, 10, 20],
        shouldFormatBigNumber: true,
        precision: 1,
        minValue: 0,
        figures: [
          // { key: 'ma1', title: 'MA5: ', type: 'line' },
          // { key: 'ma2', title: 'MA10: ', type: 'line' },
          // { key: 'ma3', title: 'MA20: ', type: 'line' },
          {
            key: 'volume',
            title: 'RETURN: ',
            type: 'bar',
            baseValue: 0,
            styles: (data) => {
              const kLineData = data.current.kLineData
              let color
              if (!kLineData){
                return "#F5554A"
              }
              if (kLineData.volume >= 3) {
                color = "#17742b"
              } else if (kLineData.volume > 0 && kLineData.volume < 3) {
                color = "#A2C7AA"
              } else if (kLineData.volume <= 0) {
                color = "#F5554A"
              }
              return { color }
            }
          }
        ],
        regenerateFigures: (params) => {
          const figures = params.map((p, i) => {
            return { key: `ma${i + 1}`, title: `MA${p}: `, type: 'line' }
          })
          figures.push({
            key: 'volume',
            title: 'RETURN: ',
            type: 'bar',
            baseValue: 0,
            styles: (data, indicator, defaultStyles) => {
              const kLineData = data.current.kLineData
              let color;
              if (kLineData.close > kLineData.open) {
                color = utils.formatValue(indicator.styles, 'bars[0].upColor', (defaultStyles.bars)[0].upColor)
              } else if (kLineData.close < kLineData.open) {
                color = utils.formatValue(indicator.styles, 'bars[0].downColor', (defaultStyles.bars)[0].downColor)
              } else {
                color = utils.formatValue(indicator.styles, 'bars[0].noChangeColor', (defaultStyles.bars)[0].noChangeColor)
              }
              return { color }
            }
          })
          return figures
        },
        calc: (dataList, indicator) => {
          const { calcParams: params, figures } = indicator
          const volSums = []
          return dataList.map((kLineData, i) => {
            const volume = kLineData.volume ?? 0
            const vol = { volume }
            params.forEach((p, index) => {
              volSums[index] = (volSums[index] ?? 0) + volume
              if (i >= p - 1) {
                vol[figures[index].key] = volSums[index] / p
                volSums[index] -= (dataList[i - (p - 1)].volume ?? 0)
              }
            })
            return vol
          })
        }
      })

      // Apply "oscillator_pane" Indicators
      this.chart.createIndicator("TRD_VOL", true, { id: "oscillator_pane" });

      // this.chart.zoomAtTimestamp(19, resp_data.data.main.timestamp.slice(-1));
    },
    async getPortfolio(){

          let urlBuild = new URL(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/portfolio_item_aggregate`)
          urlBuild.searchParams.set("portfolio_id", currPath.searchParams.get("portfolio_id"))
          let headers = {
                    'Content-Type': 'application/json',
                }
          
          if (localStorage.getItem("barpot_jwt_access") !== undefined && localStorage.getItem("barpot_jwt_access") !== 'undefined'){
            headers['Authorization'] = "Bearer " + localStorage.getItem("barpot_jwt_access")
          }
          await fetch(urlBuild.toString(), {
              method: 'GET', // or 'PUT'
              headers: headers
              }).then(response => {
                  // if(response.status == 401 || response.status == 403){
                  //     alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                  //     window.location = "/dashboard"
                  //     return null
                  // }
                  return response.json()
              })
              .then(resp_data => {
                  this.doChartPopulation(resp_data)
              });
    }
  },
  unmounted: function () {
    dispose("indicator-k-line");
  },
};
</script>

<template>
  <div class="k-line-chart-container">
    <div class="flex 2xl:flex-row xl:flex-row lg:flex-row sm:flex-col items-center justify-between">

        <div date-rangepicker class="flex items-center 2xl:flex-row xl:flex-row lg:flex-row sm:flex-col">
          <span data-tooltip-target="period-tooltip" data-v-2e83651c="" class="mr-2 text-base font-medium tracking-tight text-gray-900 dark:text-white">Period<span data-v-2e83651c="" class="material-symbols-outlined text-sm mx-0.5">info</span>:</span>
      <div data-v-2e83651c="" id="period-tooltip" role="tooltip" class="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-0 invisible" data-popper-placement="top" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(367.879px, -1273.94px, 0px);"> Period Goes here <div data-v-2e83651c="" class="tooltip-arrow" data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(44.8485px, 0px, 0px);"></div></div>
          <div class="relative">
            <div name="start" class="relative h-10 w-full min-w-[200px]">
              <input text="text" :value="new Date(this.xhrData?.agg?.portfolio[this.xhrData?.id].time_period?.start_epoch * 1000).toLocaleString()" class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" placeholder=" "><label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"> Start Date </label></div>
          </div>
          <span class="mx-4 text-gray-500">to</span>
          <div class="relative">
            <div name="end" class="relative h-10 w-full min-w-[200px]"><input text="text" :value="new Date(this.xhrData?.agg?.portfolio[this.xhrData?.id].time_period?.end_epoch * 1000).toLocaleString()" class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" placeholder=" "><label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"> End Date </label></div>
          </div>
        </div>
        <div class="w-72 xl2:mt-0 xl:mt-0 lg:mt-0 sm:mt-3">
          <chartTimePeriodSelectors
          :defaultData="this.defaultTimePeriodConfiguration"
          :respTimePeriodConfig="this.xhrData?.agg?.portfolio[this.xhrData?.id].time_period"/>
        </div>
        <div class="w-72 xl2:mt-0 xl:mt-0 lg:mt-0 sm:mt-3">
          <div class="relative h-10 w-full min-w-[200px]">
            <input
              :value="'$' + new Number(this.xhrData?.simulation?.initial_investment).toLocaleString()"
              class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder=" "
            />
            <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Simulated Initial investment
            </label>
          </div>
        </div>
    </div>
<hr class="w-full border-solid border border-gray my-2">
    <div v-if="this.isEmptyData == true" class="flex flex-col h-screen justify-center text-center items-center">
        <p class="p-2 mt-2 text-center text-4xl text-transparent bg-clip-text bg-gradient-to-r to-lime-600 from-sky-400">No data was found using your filters.</p>
        <p class="p-2 mt-2 text-center text-4xl text-transparent bg-clip-text bg-gradient-to-r to-lime-600 from-sky-400">Please update your parameters and try again.</p>

    </div>
    <div v-if="this.xhrData == null" class="flex flex-col h-screen justify-center text-center items-center">
        <fingerprint-spinner
            :animation-duration="2000"
            :size="160"
            color="#0051d8"
        />
        <hr class="my-4"/>
        <p class="mt-2 text-center text-2xl text-gray-600">Loading<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></p>
    </div>
    <div class="flex justify-end z-30 hidden">
    <button id="dropdownToggleButton" data-dropdown-toggle="dropdownToggle" data-dropdown-placement="top" class="flex items-center w-fit mr-16 absolute shadow-[0_4px_14px_0_rgb(0,118,255,39%)] hover:shadow-[0_6px_20px_rgba(0,118,255,23%)] hover:bg-[rgba(0,118,255,1)] px-8 py-2 bg-[rgba(0,118,255,0.7)] rounded-md text-white font-light transition duration-200 ease-linear">
                    Filter <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                                  </svg>
                  </button>

                  <!-- Dropdown menu -->
                <div id="dropdownToggle" class="z-30 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-fit dark:bg-gray-700 dark:divide-gray-600">
                    <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownToggleButton">
                      <li v-for="strategyId in [{'name': 'back test'}, {'name': 'trading day'}]" :key="strategyId">
                        <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                          <label class="inline-flex items-center w-full cursor-pointer">
                            <input @click="handleToggleStrategy(strategyId)" checked type="checkbox" value="" class="sr-only peer">
                            <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-blue-600"></div>
                            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{strategyId.name}}</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                </div>
    </div>
    <div id="indicator-k-line" class="k-line-chart" />
    <div class="k-line-chart-menu-container hidden">
      <span data-tooltip-target="benchmark-tooltip" data-v-2e83651c="" class="mr-2 text-base font-medium tracking-tight text-gray-900 dark:text-white">Benchmark<span data-v-2e83651c="" class="material-symbols-outlined text-sm mx-0.5">info</span>:</span>
      <div data-v-2e83651c="" id="benchmark-tooltip" role="tooltip" class="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-0 invisible" data-popper-placement="top" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(367.879px, -1273.94px, 0px);"> benchmark you reports here <div data-v-2e83651c="" class="tooltip-arrow" data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(44.8485px, 0px, 0px);"></div></div>
      <span class="bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400">SPY</span>

    <button id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" data-dropdown-placement="bottom" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Add Securities <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
      </svg></button>

    <!-- Dropdown menu -->
    <div id="dropdownSearch" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
        <div class="p-3">
          <label for="input-group-search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
            </div>
            <input type="text" id="input-group-search" class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Symbol">
          </div>
        </div>
        <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
          <li>
            <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <input id="checkbox-item-11" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              <label for="checkbox-item-11" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Bonnie Green</label>
            </div>
          </li>
          <li>
            <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <input checked id="checkbox-item-12" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <label for="checkbox-item-12" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Jese Leos</label>
              </div>
          </li>
          <li>
            <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <input id="checkbox-item-13" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              <label for="checkbox-item-13" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Michael Gough</label>
            </div>
          </li>
          <li>
            <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <input id="checkbox-item-14" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              <label for="checkbox-item-14" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Robert Wall</label>
            </div>
          </li>
          <li>
            <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <input id="checkbox-item-15" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              <label for="checkbox-item-15" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Joseph Mcfall</label>
            </div>
          </li>
          <li>
            <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <input id="checkbox-item-16" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              <label for="checkbox-item-16" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Leslie Livingston</label>
            </div>
          </li>
                <li>
            <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <input id="checkbox-item-17" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              <label for="checkbox-item-17" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Roberta Casas</label>
            </div>
          </li>
        </ul>
        <a href="#" class="flex items-center p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline">
          <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
            <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z"/>
          </svg>
          Delete user
      </a>
    </div>

    </div>
    <div class="k-line-chart-menu-container hidden">
      <span style="padding-right: 10px">{{this.propSymbol}}</span>
      <button
        v-for="type in mainIndicators"
        :key="type"
        v-on:click="setMainIndicator(type)"
      >
        {{ type }}
      </button>
      <button v-on:click="setMainIndicator('EMOJI')">EMOJI</button>
      <button v-on:click="setMainIndicator('BLOCKS')">BLOCKS</button>
      <span style="padding-right: 10px; padding-left: 12px">副图指标</span>
      <button
        v-for="type in subIndicators"
        :key="type"
        v-on:click="setSubIndicator(type)"
      >
        {{ type }}
      </button>
      <button v-on:click="setSubIndicator('EMOJI')">自定义</button>
    </div>
  <slot></slot>
  </div>
</template>

<style>
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  width: 98%;
  height: 98vh;
  padding: 16px 6px 16px 16px;
  z-index: 3;
}
.k-line-chart-title {
  margin: 0;
  color: #252525;
}
.k-line-chart {
  display: flex;
  flex: 1;
  z-index: 10;
}
.k-line-chart-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #606060;
}
.k-line-chart-menu-container button {
  cursor: pointer;
  background-color: #1677ff;
  border-radius: 2px;
  margin-right: 8px;
  height: 24px;
  line-height: 26px;
  padding: 0 6px;
  font-size: 12px;
  color: #fff;
  border: none;
  outline: none;
}
</style>