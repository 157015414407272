export const formatCashValue = (price) => {
    if (!price){
      return price;
    }
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return USDollar.format(price)
}

export const formatFixedNumber = (c, prepend="", postpend="", decimal=0) => {
  if(isNaN(parseFloat(c)) == false){
    return prepend + parseFloat(c).toFixed(decimal) + postpend
  }
  return c
}

export const booleanToggle = (b) => {
  return !(b ?? false);
}