<template>
  <div class="">
    <!-- Headers -->
    <div class="mx-auto bg-white text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-2">
        <ul class="flex flex-row overflow-x-scroll mostly-customized-scrollbar">
            <li v-for="expirationDate in propData?.signals?.options?.expirations?.dates" :key="expirationDate" class="flex w-fit flex-shrink-0">
                <button
                  :class="propData?.signals?.options?.expirations?.selection?.date_string == expirationDate ? 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500' : 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'"
                  style="text-wrap: nowrap;"
                  >{{expirationDate}}</button>
            </li>
        </ul>
    </div>

    <!-- Switch/Toggle -->
    <div class="flex justify-center	">
      <div class="flex">
        <div class="relative inline-block">
          <input type="checkbox" id="hs-large-solid-switch-with-icons"
          class="peer relative shrink-0 w-[6.20rem] h-[3.2rem] p-px bg-gray-100
          border-transparent text-transparent rounded-full cursor-pointer
          transition-colors ease-in-out duration-200
          focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none
          checked:bg-none checked:text-blue-600 checked:border-blue-600
          focus:checked:border-blue-600 dark:bg-gray-800 dark:border-gray-700
          dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600
          before:inline-block before:w-12 before:h-12 before:bg-white checked:before:bg-blue-200 
          before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow 
          before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 
          dark:before:bg-gray-400 dark:checked:before:bg-blue-200">
          <label for="hs-large-solid-switch-with-icons" class="sr-only">switch</label>
          <span class="peer-checked:text-white text-gray-500 w-8 h-8 absolute top-2 start-2 flex justify-center items-center pointer-events-none transition-colors ease-in-out duration-200">
            <span class="text-sm font-medium text-dark dark:text-white">Call</span>
            <!-- <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg> -->
          </span>
          <span class="peer-checked:text-blue-600 text-gray-500 w-8 h-8 absolute top-2 end-2 flex justify-center items-center pointer-events-none transition-colors ease-in-out duration-200">
            <span class="text-sm font-medium text-dark dark:text-white">Put</span>
            <!-- <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg> -->
          </span>
        </div>
      </div>
    </div>
    <!-- End Switch/Toggle -->

    <!-- Tables -->
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
        <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
            <thead class="text-xs text-white uppercase bg-blue-600 dark:text-white">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Contract Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Price
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Change
                        <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Today</span>
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Strength
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(optionInstance, optionInstanceIdx) in propData?.signals?.options?.calls" :key="optionInstance" :class="Math.abs(optionInstanceIdx % 2) == 1 ? 'bg-blue-600 border-b border-blue-400': 'bg-blue-500 border-b border-blue-400'">
                    <th scope="row" class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100">
                        {{optionInstance.human_string}}
                    </th>
                    <td class="px-6 py-4">
                        Breakeven: $--
                        To Breakeven: +--.--
                    </td>
                    <td class="px-6 py-4">
                        ${{optionInstance.raw.ask}}
                    </td>
                    <td class="px-6 py-4">
                        {{optionInstance.raw.change}}%
                    </td>
                    <td class="px-6 py-4">
                      <button data-v-7ba5bd90="" type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-teal-200 hover:text-teal-900 dark:hover:bg-teal-800 dark:hover:text-teal-300" data-dismiss-target="#badge-dismiss-default" aria-label="Remove"><div data-v-7ba5bd90="" class="mx-2 signal-icon medium"><div data-v-7ba5bd90="" class="signal-bar"></div><div data-v-7ba5bd90="" class="signal-bar"></div><div data-v-7ba5bd90="" class="signal-bar"></div><div data-v-7ba5bd90="" class="signal-bar"></div><div data-v-7ba5bd90="" class="signal-bar"></div></div></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
import { reactive } from 'vue'
// import {useToast} from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-sugar.css';

// const $toast = useToast();
const store = reactive({
    targetModal: null,
    userData: null
})
export default {
 props: ['propSymbol', 'propData'],
 watch: {
    propData: function(newVal, oldVal) { // watch it
      console.log('OPTIONS TABLE propData changed: ', newVal, ' | was: ', oldVal)
      if(this.propData !== null){
        if(this.propData?.data?.main?.data.length <= 0){
          this.isEmptyData = true
        } else {
          this.isEmptyData = false
        }
      }
    },
  },
 mounted(){
 },
 data(){
     return store
 },
 methods: {
  navigateToSymbol(item){
    window.location = `http://localhost:8080/stock?symbol=${item.symbol}`
  },
 }
 
}
</script>

<style scoped>

@media (min-width: 1900px) {

}

@media (min-width: 1440px) {

}

@media (min-width: 1281px) and (max-width: 1440px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) {

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

@media (min-width: 481px) and (max-width: 767px) {

}

@media (min-width: 320px) and (max-width: 480px) {

}
</style>