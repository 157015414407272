<template>
<!DOCTYPE html>
<html lang="en"><head>
  
  <title>SaaS Landing Page - Light Version | library</title>
  <meta charset="utf-8" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta http-equiv="Accept-CH" content="DPR,Width,Viewport-Width" />
  <meta http-equiv="X-UA-Compatible" content="ie=edge,chrome=1" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  
  
  <link rel="stylesheet" href="./css/main.css">
  <link rel="stylesheet" href="./css/extra.css">
</head><body class="font-sans"><div id="content">
<div>
  
  
<main class="bg-white text-gray-700 font-medium">
  
  <!-- header block -->
  <header class="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 py-4 lg:py-6 flex justify-between">
    <a href="/">
      <span class="sr-only">SaaS landing page</span>
      <img style="width: 33%" src="images/barpot_logo_a.svg" class="rounded-lg" alt="">
    </a>
  </header>
  <!--/ header block -->
  
  <!-- header spacing block -->
  <div class="py-12 lg:pb-8 bg-white mb-6 lg:mb-8"></div>
  <!--/ header spacing block -->
  
  <!-- pricing block -->
  <div class="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 mb-12 lg:mb-16 xl:mb-24">
    <div class="text-center text-1xl mb-6 md:mb-8 space-y-3">
      <h1 class="text-5xl">Barpot Terms and Conditions of Use</h1>

      <h2>1. Terms</h2>

      <p>By accessing this website, accessible from https://barstool-frontend-c6rnmqev2q-ue.a.run.app/, you are agreeing to be bound by these website's Terms and Conditions of Use. You must agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this website are protected by copyright and trademark law.</p>

      <h2>2. Use License</h2>

      <p>Permission is granted to temporarily download one copy of the materials on Barpot's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

      <ul>
          <li>modify or copy the materials;</li>
          <li>use the materials for any commercial purpose or for any public display;</li>
          <li>attempt to reverse engineer any software contained on Barpot's website;</li>
          <li>remove any copyright or other proprietary notations from the materials;</li>
          <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
      </ul>

      <p>This will let Barpot terminate your service upon violation of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

      <h2>3. Disclaimer</h2>

      <p>All the materials on Barpot’s website are provided "as is". Barpot makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Barpot does not make any representations concerning the accuracy or reliability of the use of the materials on its website or otherwise relating to such materials or any sites linked to this website.</p>

      <h2>4. Limitations</h2>

      <p>Barpot or its suppliers will not be hold accountable for any damages that could arise with the use or inability to use the materials on Barpot’s website, even if Barpot or an authorized representative of this website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

      <h2>5. Revisions and Errata</h2>

      <p>The materials appearing on Barpot’s website may include technical, typographical, or photographic errors. Barpot will not promise that any of the materials on this website are accurate, complete, or current. Barpot may change the materials contained on its website at any time without notice. Barpot does not make any commitment to update the materials.</p>

      <h2>6. Links</h2>

      <p>Barpot has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Barpot of the site. The use of any linked website is at the user’s own risk.</p>

      <h2>7. Site Terms of Use Modifications</h2>

      <p>Barpot may revise these Terms of Use for its website at any time without prior notice. By using this website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

      <h2>8. Your Privacy</h2>

      <p>Please read our Privacy Policy.</p>

      <h2>9. Governing Law</h2>

      <p>Any claim related to Barpot's website shall be governed by the laws of us without regards to its conflict of law provisions.</p>
    </div>
  </div>
  <!--/ pricing block -->

  <!-- footer block -->
  <footer class="bg-white text-gray-600 py-12 xl:pb-24">

    <div class="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 flex flex-col md:flex-row justify-between items-center space-y-4 mt-16 lg:mt-20">
      <div class="text-sm space-y-4 md:space-y-1 text-center md:text-left">
        <p>&copy;2021 barpot. All rights reserved. | All rights reserved</p>
      </div>
      <a href="/dashboard" class="inline-block bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-700 font-semibold rounded-lg py-4 px-5 lg:px-8 text-white md:transform md:-translate-y-2">Explore Now</a>
    </div>
    
    <div class="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 flex flex-col md:flex-row justify-between items-center space-y-4 mt-8 lg:mt-4">
      <nav class="flex flex-wrap justify-center space-x-6"> 
        <a href="/privacy-policy" class=" text-sm hover:text-gray-700 mb-2">Privacy Policy</a>
        <a href="/disclaimer" class=" text-sm hover:text-gray-700 mb-2">Disclaimer</a>
        <a href="/return-policy" class=" text-sm hover:text-gray-700 mb-2">Return & Refund Policy</a>
        <a href="/terms-and-conditions" class=" text-sm hover:text-gray-700 mb-2">Terms & Conditions</a>
      </nav>
      <nav class="flex items-center space-x-2">
        <a href="#" class="text-gray-500 hover:text-gray-600">
          <span class="sr-only">Facebook</span>
          <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
          </svg>
        </a>
        <a href="#" class="text-gray-500 hover:text-gray-600">
          <span class="sr-only">Instagram</span>
          <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
          </svg>
        </a>
        <a href="#" class="text-gray-500 hover:text-gray-600">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </nav>
    </div>
  </footer>
  <!--/ footer block -->

</main>
  
</div>

        </div>




</body>
</html>

</template>

<script>

export default {
  name: 'TermsAndConditions',
  components: {
    
  },
  data () {
      return {

      }
  },
  mounted(){

  },
  methods: {

  }
}

</script>

<style>

.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
</style>