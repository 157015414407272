<template>

    <tr>
            <!-- Main modal -->
            <div v-if="Object.keys(xhrData.options_stream).length >= 1 && xhrData.options_stream[data.symbol] !== undefined && xhrData.options_stream[data.symbol] !== null" id="defaultModal" tabindex="-1" :class="this.getModelRenderClasses()['css']">
                <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <!-- Modal header -->
                        <div class="flex flex-col space-y-3 justify-between items-center p-4 rounded-t border-b dark:border-gray-600" >
                          
                            <button type="button" class="text-gray-400 bg-transparent hover:bg--200 hover:text-red-500 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" @click="this.toggleOptionsChainModal" data-modal-toggle="defaultModal">
                                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                            </button>
                            <div class="flex">
                              <span class="ml-4 text-xs cursor-pointer inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-white text-gray-700 underline"> {{data.symbol}} </span>
                            <span class="ml-4 text-xs cursor-pointer inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-white text-gray-700 border"> CALLS </span>
                            <span class="ml-4 text-xs cursor-pointer inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-white text-gray-700 border"> PUTS </span>
                          </div>
                          <div class="flex">
                            <div class="grid grid-cols-4 gap-4">
                                <!-- <span v-for="exp_date in data.options.expirations.dates" v-bind:key="exp_date" class="ml-4 text-xs cursor-pointer inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-white text-gray-700 border">
                                  {{exp_date}}
                                </span> -->
                            </div>
                          </div>
                        </div>
                        <!-- Modal body -->
                        <div class="p-6 space-y-6">
                            <p class="hidden text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                            </p>
                            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                  <thead class="text-xs text-gray-900  uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                      <tr>
                                          <th scope="col" class="px-6 py-3">
                                              Contract Name
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                              Strike
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                              Option Type
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                              Cost
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                              Expiration
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="op_contract in Object.keys(xhrData.options_stream[data.symbol])" v-bind:key="op_contract" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                          <th scope="row" class="px-6 py-4 font-large text-base text-gray-900 dark:text-white whitespace-nowrap">
                                              {{xhrData.options_stream[data.symbol][op_contract].contract_name}}
                                          </th>
                                          <td class="px-6 py-4">
                                              {{xhrData.options_stream[data.symbol][op_contract].strike}}
                                          </td>
                                          <td class="px-6 py-4 underline capitalize">
                                              {{xhrData.options_stream[data.symbol][op_contract].option_type}}
                                          </td>
                                          <td class="px-6 py-4">
                                              {{xhrData.options_stream[data.symbol][op_contract].last_price}}
                                          </td>
                                          <td class="px-6 py-4 text-center">
                                              {{this.moment(xhrData.options_stream[data.symbol][op_contract].expiration).format('MMM Do, YYYY')}}
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                              <div v-if='Object.keys(xhrData.options_stream[data.symbol]).length <= 0'>
                                <div class="flex flex-row align-center justify-center font-bold underline">
                                  <div class="flex flex-col align-center justify-center font-bold underline">
                                    <img src="/images/127760-nothing.gif" alt="this slowpoke moves" width="100" style="width: 15em;">
                                    <span class="text-bold text-md py-4">Hmmm... No revalant options found!</span>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="hidden flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                            <button data-modal-toggle="defaultModal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                            <button data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
                        </div>
                    </div>
                </div>
            </div>
            <td class="px-4 py-3 border">
              <div class="flex items-center justify-center text-sm">
                <!-- <div class="relative w-8 h-8 mr-3 rounded-full md:block">
                  <img class="object-cover w-full h-full rounded-full" src="https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260" alt="" loading="lazy">
                  <div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                </div> -->
                <div class="flex flex-col space-y-2">
                  <button @click="get_stock_href(data)" class="text-base text-5xl font-bold text-gray-500">
                    $<span class="text-1xl text-5xl font-bold text-gray-500">
                      <span class="underline px-1">{{data.symbol}}</span>
                      
                      <!-- <span class="text-xl font-semibold leading-tight text-blue-700 bg-blue-100 rounded-sm">{{data.exittime == null ? "OPEN" : "CLOSED"}}</span> -->
                    </span>
                  </button>
                  <!-- <div v-if="data.options !== null && xhrData.options_stream[data.symbol] !== undefined && xhrData.options_stream[data.symbol] !== null">
                    <button v-if="Object.keys(xhrData.options_stream[data.symbol]).length <= 0" type="button" class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1 cursor-not-allowed line-through"><span class="p-1">No Options Alerts 🪄</span></button>
                    <button v-if="Object.keys(xhrData.options_stream[data.symbol]).length > 0" @click="this.toggleOptionsChainModal" type="button" class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-gray-600 bg-green-200 uppercase last:mr-0 mr-1"><span class="p-1">View Options Alerts 🪄</span></button>
                  </div> -->
                </div>
              </div>
            <div v-if="data.entryprice.toString().indexOf('PREMIUM REQUIRED') > -1" id="marketing-banner" tabindex="-1"
              class="z-50 flex flex-col items-center justify-between p-2 bg-white border border-gray-100 rounded-lg shadow-sm mt-6 dark:bg-gray-700 dark:border-gray-600"
              data-v-4c42232a="">
              <div class="flex flex-col items-start mb-3 md:items-center md:flex-row md:mb-0" data-v-4c42232a=""><a
                  href="https://flowbite.com/"
                  class="flex items-center mb-2 md:mb-0"
                  data-v-4c42232a="">
                  <span class="self-center text-lg font-semibold whitespace-nowrap dark:text-white" data-v-4c42232a="">Premium Required</span></a>
              </div>
              <div class="flex items-center flex-shrink-0"><a href="/account"
                  class="px-5 py-2 me-2 text-xs font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  data-v-4c42232a="">Upgrade Now!</a></div>
            </div>
            </td>

            <td class="px-4 py-3 text-ms font-semibold border">
              <div class="flex flex-col px-4 py-3 text-lg font-semibold space-y-2">
                <div class="flex">
                  <span spiketip-title='"Stock strength" refers to how stable and predictable a stock performs in the market. A strong stock is one that shows consistent growth and stability over time, indicating a reliable investment opportunity.' spiketip-color="info" spiketip-length="lg" spiketip-pos="right" id="badge-dismiss-default" class="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-teal-800 bg-teal-100 rounded dark:bg-teal-900 dark:text-teal-300">
                    Stock Strength
                    <button type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-teal-200 hover:text-teal-900 dark:hover:bg-teal-800 dark:hover:text-teal-300" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                      <div :class="'mx-2 signal-icon ' + this.markSignalBar((data.dynamic_model_metrics.winrate_precent + data.neural_model_section_confidence) / 2)">
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                      </div>
                    </button>
                  </span>
                </div>
                <!-- <div class="flex">
                  <span spiketip-title='"Model Win-rate" refers to the ratio of a stock wins to losses to our personalized Neural Algorithm Model. It provides insight into the frequency with which a stock generates positive returns compared to negative returns. A high win rate indicates that a stock has a greater likelihood of generating positive returns, while a low win rate suggests that it may be more likely to generate negative returns.' spiketip-color="info" spiketip-length="lg" spiketip-pos="right" id="badge-dismiss-default" class="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
                    Winrate
                    <button type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                        <span>{{this.winrateFormatting(data.dynamic_model_metrics.winrate_precent,prepend="")}}</span>
                    </button>
                  </span>
                </div> -->
                
                <div class="flex">
                  <span spiketip-title='"Alert confidence" refers to the likelihood that a given investment position will yield a positive return. It is an indicator of the level of confidence an investor has in a particular investment opportunity.' spiketip-color="info" spiketip-length="lg" spiketip-pos="right" id="badge-dismiss-default" class="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
                    Alert Confidence
                    <button type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                        <span>{{this.winrateFormatting(data.neural_model_section_confidence,prepend="")}}</span>
                    </button>
                  </span>
                </div>

                <!-- <div class="flex">
                  <span spiketip-title='"Model Win-rate" refers to the ratio of a stock wins to losses to our personalized Neural Algorithm Model. It provides insight into the frequency with which a stock generates positive returns compared to negative returns. A high win rate indicates that a stock has a greater likelihood of generating positive returns, while a low win rate suggests that it may be more likely to generate negative returns.' spiketip-color="info" spiketip-length="lg" spiketip-pos="right" id="badge-dismiss-default" class="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
                    Rating
                    <button type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                        <span>{{this.position_sentimentFormatting(data.position_sentiment,prepend="")}}</span>
                    </button>
                  </span>
                </div> -->
                <div class="flex">
                    <a :href="'/product/algo_creator?composer_tab=chart&composer_environment=live_chart&symbol=' + data.symbol" class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-gray-600 border-dotted border-2 uppercase last:mr-0 mr-1" style="border-color: #FFB60A;"><span class="p-1">Explore in Composer 🪄</span></a>
                </div>
                <!-- <span class="px-2 py-1 font-sm leading-tight text-gray-700 bg-gray-100 rounded-sm"> {{data.number_of_trades}} </span> -->
              </div>
            </td>

            <td class="px-4 py-3 text-ms font-semibold border w-fit">
              <div class="py-3 text-1xl text-center border">
                <div class="flex flex-row justify-evenly">
                  <div class="flex flex-col text-lg font-semibold space-x-1 justify-center">
                    <div class="flex flex-col">
                      <span>{{this.priceFormatting(data.entryprice)}}</span>
                      <span :class="this.colorOCordPL(data.return_precent, returnColorOCordSchema, true)['css_tag']"> {{this.colorOCordPL(data.return_precent, returnColorOCordSchema, true)['text']}} </span>
                      <span v-if="data.returnpct" :class="this.colorOCordPL(data.return_precent, returnColorOCordSchema, true)['css_tag']">{{this.printableNumber(parseFloat(data.returnpct).toFixed(2)) + "%"}}</span>
                    </div>
                  </div>
                  <div :class="data.entryprice.toString().indexOf('PREMIUM REQUIRED') > -1 ? 'flex flex-col blur-row-non-premium': 'flex flex-col'">
                    <div class="flex flex-row space-x-2 justify-between">
                      <span>Timeframe</span>
                      <div class="flex flex-row px-2 py-1 font-semibold leading-tight text-green-700 rounded-sm">
                        <span>15Min</span>
                      </div>
                    </div>
                    <div class="flex flex-row space-x-2 justify-between">
                      <span>Position Type</span>
                      <div class="flex flex-row px-2 py-1 font-semibold leading-tight text-green-700 rounded-sm">
                        <span>Daytrading</span>
                      </div>
                    </div>
                    <div class="flex flex-row space-x-2 justify-between">
                      <span>Entry Area</span>
                      <div class="flex flex-row">
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.entry_area_low)}}</span>
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">→</span>
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.entry_area_high)}}</span>
                      </div>
                    </div>
                    <div class="flex flex-row space-x-2 justify-between">
                      <span>Take Profit</span>
                      <div class="flex flex-row">
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.take_profit_area_low)}}</span>
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">→</span>
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.take_profit_area_high)}}</span>
                      </div>
                    </div>
                    <div class="flex flex-row space-x-2 justify-between">
                      <span>Stop Loss</span>
                      <div class="flex flex-row">
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.stop_loss_area_high)}}</span>
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">→</span>
                        <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.stop_loss_area_low)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>

            <!-- <td class="px-4 py-3 text-ms font-semibold border">{{data.neural_confidence ? normalizeProjectValues(parseFloat(data.neural_confidence != null ? data.neural_confidence: 0)): null}}%</td> -->
            <!-- <td class="px-4 py-3 text-1xl border">
              <div class="flex flex-col items-center px-4 py-3 text-semibold border" style="min-width: max-content;">
                <span :class="this.colorOCord(data.return_precent, returnColorOCordSchema, true)['css_tag']"> {{this.colorOCord(data.return_precent, returnColorOCordSchema, true)['text']}} </span>
              </div>
            </td> -->
            
            <!-- <td class="px-4 py-3 text-1xl border">
                <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.entry_area_low)}}</span>
                <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">→</span>
                <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.priceFormatting(data.entry_area_high)}}</span>
              </td> -->
            <td class="px-4 py-3 text-semibold border" style="min-width: max-content;">
              <div class="flex flex-col items-center px-4 py-3 text-semibold border" style="min-width: max-content;">
                <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.convert_to_time_elp_string_date(data.entrytime)}}</span>
                <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">{{this.convert_to_time_elp_string_time(data.entrytime)}}</span>
                <!-- <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">({{this.convert_to_time_elp_string_from(data.entrytime)}})</span> -->
              </div>
              </td>
          </tr>
</template>

<script>
import moment from 'moment-timezone'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();
export default {
 props: {
   risk_indicators: Object,
   data: Object,
   xhrData: Object
 },
 data(){
     return {
      moment: moment,
         modalOptions: {
        show: false,
        info: {
          phone_number: ""
        }
      },
         symbolDict: {},
         stats_risk_code: this.comp_stats_risk_code(this.$props.data.stats_risk_code),
         winRateColorOCordSchema: [{min: 0, max: 5, color: "red"}, {min: 50, max: 60, color: "yellow"}, {min: 60, max: 80, color: "orange"}, {min: 80, max: 95, color: "blue"}, {min: 95, max: 100, color: "green"}],
         returnColorOCordSchema: [{min: 0, max: 2, color: "blue"}, {min: 2, max: 3, color: "yellow"}, {min: 3, max: 20, color: "green"}]
     }
 },
 methods: {
  getModelRenderClasses(){
    console.log(this.modalOptions)
    return {"css": this.modalOptions.show == true ? 'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full' : 'hidden'}
  },
  get_stock_href(data){
    if(data.entryprice.toString().indexOf('PREMIUM REQUIRED') > -1){
      $toast.open({
          message: "👋 Hello, Please upgrade your subscription tier to view this stock.",
          type: 'error',
          duration: 5000,
          position: 'bottom'
          // all of other options may go here
        });
        return
    }

    if (data?.auth_token !== undefined){
      window.location = `/stock?symbol=${data.symbol}&access_token=${data?.auth_token}`
    } else {
      window.location = `/stock?symbol=${data.symbol}`
    }
  },
   toggleOptionsChainModal(){
    console.log(this.modalOptions)
    if (this.modalOptions.show == true){
      this.modalOptions.show = false
    } else {
      this.modalOptions.show = true
    }
   },
   comp_stats_risk_code(risk_type){
      switch (risk_type) {
        case 'risk_high':
          return "background: #3498DB;"
        case 'risk_moderate':
          return "background: #2ECC70;"
        case 'risk_low':
          return "background: #F1C40F;"
        default:
          return "background: transparent;"
      }
   },
   priceFormatting(c, prepend="$"){
     if(isNaN(parseFloat(c)) == false){
       return prepend + parseFloat(c).toFixed(2)
     }
     return c
   },
   winrateFormatting(c, prepend="$"){
     if(isNaN(parseFloat(c)) == false){
       return prepend + parseFloat(c).toFixed(0) + "%"
     }
     return c
   },
   position_sentimentFormatting(c, prepend="$"){
     let covert = {"hold":"HOLD", "strong_buy":"STRONG BUY", "buy":"BUY"}
     return prepend + covert[c]
   },
   normalizeProjectValues(_num){
            let num = (_num * 100).toFixed(2);
            if (num >= 100.0){
                num = 99.99
            }
            return num
        },
  convert_to_time_elp_string_date(last_time) {
    return moment.utc(last_time).tz('America/New_York').add(4, 'hours').format('MMM Do YYYY');
   },
   convert_to_time_elp_string_time(last_time) {
    return moment.utc(last_time).tz('America/New_York').add(4, 'hours').format('h:mm a z');
   },
  convert_to_time_elp_string_from(last_time) {
    return moment.utc(last_time).tz('America/New_York').add(4, 'hours').fromNow();
   },
   printableNumber(n) {
     return (n > 0) ? "+" + n : n;
  },

  colorOCordPL(amount, schema, entireContainer) {
        let color = 'gray'
        let text_of = ''
        // console.log(parseFloat(this.data.quote.p_l))
        if(this.data.exittime !== null){
          text_of = "CLOSED"
          color = 'blue'
        } else {
          if(this.data.quote.p_l < 0) {
            text_of = this.printableNumber(parseFloat(this.data.quote.p_l).toFixed(2)) + "%"
            color = 'red'
          } else {
            // if(this.data.quote.p_l < 1) {
            //   text_of = this.printableNumber(parseFloat(this.data.quote.p_l).toFixed(2)) + "%"
            //   color = 'gray'
            // }
            // if(this.data.quote.p_l > 1) {
            //   text_of = this.printableNumber(parseFloat(this.data.quote.p_l).toFixed(2)) + "%"
            //   color = 'yellow'
            // }
            if(this.data.quote.p_l > 0) {
              text_of = this.printableNumber(parseFloat(this.data.quote.p_l).toFixed(2)) + "%"
              color = 'green'
            }
          }
        }

        // console.log(this.data.stats_risk_code, {"css": isHiddenString + `bg-blue`, "text": text_of})
      if (entireContainer){
        return {"css": ``,
                'css_tag': `px-2 py-1 font-semibold leading-tight text-${color}-700 underline`,
                "text": text_of}
      }
     return {"css": ``,
             'css_tag': `px-2 py-1 font-semibold leading-tight text-${color}-700 underline`,
             "text": text_of}
   },
   markSignalBar(precent){
    console.log(precent)
    precent = parseFloat(precent)
    // extra-weak, weak, medium, strong, super-strong
    let css_strength = ''
    if( precent >= 0 && precent <= 20){
        css_strength = "extra-weak";
    }
    
    if( precent >= 20 && precent <= 40){
        css_strength = "weak";
    }

    if( precent >= 40 && precent <= 70){
        css_strength = "medium";
    }

    if( precent >= 70 && precent <= 80){
        css_strength = "strong";
    }

    if( precent >= 80 && precent <= 100){
        css_strength = "super-strong";
    }

    console.log(111111,precent, css_strength)
    return css_strength
   },
   colorOCord(amount, schema, entireContainer) {
        amount = parseFloat(amount)
        let color = 'gray'
        let text_of = ''
        let _schema = schema ? schema : [{min: 0, max: 5, color: "blue"}, {min: 5, max: 10, color: "yellow"}, {min: 10, max: 100, color: "green"}]
        _schema.map((x) => {
            if(amount >= x.min && amount <= x.max){
                color = x.color;
            }
        })
        
        // let isHiddenString = "";
        if(this.data.stats_risk_code == "risk_high") {
          text_of = 'High Risk'
          color = 'red'
        }
        if(this.data.stats_risk_code == "risk_low") {
          text_of = 'Low Risk'
          color = 'green'
        }
        if(this.data.stats_risk_code == "risk_moderate") {
          text_of = 'Moderate Risk'
          color = 'orange'
        }

        // console.log(this.data.stats_risk_code, {"css": isHiddenString + `bg-blue`, "text": text_of})
      if (entireContainer){
        return {"css": ``,
                'css_tag': `px-2 py-1 font-semibold leading-tight text-${color}-700 bg-${color}-100 rounded-sm`,
                "text": text_of}
      }
     return {"css": ``,
             'css_tag': `px-2 py-1 font-semibold leading-tight text-${color}-700 bg-green-100 rounded-sm`,
             "text": text_of}
   }
 }
}
</script>

<style scoped>
.Header {
  margin-bottom: 15px;
}
.hidden-as-sec-look {
  opacity: 0.3;
  -webkit-text-security: disc;
}
.blur-row-non-premium {
  filter: blur(8px);
}
</style>