<template>
<div class="flex flex-col items-center md:px-0 sm:px-6">
    <div class="flex xl:flex-row sm:flex-col items-center my-4">
        <div class="flex mr-2 mb-4">
            <span class="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
                Alert Confidence 
            </span>
        </div>
        <div class="items-center xl:w-[30em] md:w-[15em] text-center">
            <div class="block">
                <vue-slider
                ref="filterAlertConfidenceSlider"
                @change="store.filterAlertConfidence.filterAlertConfidenceOnChange"
                @drag-start="store.filterAlertConfidence.filterAlertConfidenceDragStart"
                @drag-end="store.filterAlertConfidence.filterAlertConfidenceDragEnd"
                v-model="store.filterAlertConfidence.filterAlertConfidenceValue"
                :tooltip="'always'"
                :tooltip-formatter="store.filterAlertConfidence.filterAlertConfidenceFormatter"
                :tooltip-placement="['top', 'top']"></vue-slider>
            </div>
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400 italic">Move the slider to filter each Alert Confidence</p>
        </div>
    </div>
    <div v-if="store.showApplyFilter" class="flex flex-row items-center my-4">
        <button @click="applyFilter" class="bg-yellow-300 hover:bg-yellow-400 text-white font-bold py-2 px-4 border-b-4 border-yellow-400 hover:border-yellow-500 rounded">
        Apply Filter
        </button>
    </div>
</div>
</template>

<script>
import { reactive } from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

const store = reactive({
    showApplyFilter: false,
    currUrl: new URL(window.location.href),
    filterAlertConfidence: {
        filterAlertConfidenceValue: [80, 100],
        filterAlertConfidenceFormatter: '{value}%',
        filterAlertConfidenceOnChange: () => {
            store.showApplyFilter = true
        },
        filterAlertConfidenceDragStart: () => {
            store.showApplyFilter = true
        },
        filterAlertConfidenceDragEnd: () => {
            store.showApplyFilter = true
        }
    }
})
export default {
    props: {
        risk_indicators: Object,
        data: Object
    },
    components: {
        VueSlider
    },
    mounted(){
        
    },
    data() {
        // Apply default Filter Alert Confidence Min and Max
        let currUrlFilterAlertConfidenceMin = store.currUrl.searchParams.get("filter_alert_confidence_min")
        let currUrlFilterAlertConfidenceMax = store.currUrl.searchParams.get("filter_alert_confidence_max")
        if(currUrlFilterAlertConfidenceMin){
            store.filterAlertConfidence.filterAlertConfidenceValue[0] = parseFloat(currUrlFilterAlertConfidenceMin)
        } else {
            store.filterAlertConfidence.filterAlertConfidenceValue[0] = 0
        }
        if(currUrlFilterAlertConfidenceMax){
            store.filterAlertConfidence.filterAlertConfidenceValue[1] = parseFloat(currUrlFilterAlertConfidenceMax)
        } else {
            store.filterAlertConfidence.filterAlertConfidenceValue[1] = 100
        }
      return {store}
    },
    methods: {
        async applyFilter(){
            let currFilterAlertConfidence = this.$refs.filterAlertConfidenceSlider.getValue()
            let currUrl = new URL(window.location.href);
            currUrl.searchParams.set("filter_alert_confidence_min", currFilterAlertConfidence[0])
            currUrl.searchParams.set("filter_alert_confidence_max", currFilterAlertConfidence[1])
            window.location.href = currUrl.href;
        }
    }
}
</script>

<style scoped>
.Header {
  margin-bottom: 15px;
}
.hidden-as-sec-look {
  opacity: 0.3;
  -webkit-text-security: disc;
}
</style>