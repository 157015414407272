<script setup>
import {defineProps, ref} from 'vue';

import { Handle, Position, useVueFlow } from '@vue-flow/core'

const theme = ref({
    background: 'rgba(79,91,102,1)',
    left: "px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-green-400 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white",
    right:"px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white",
    active: "text-blue-700",
  })

const props = defineProps(['id', 'label', 'data'])

const { updateNodeData } = useVueFlow()

/**
 * Enum for common colors.
 * @readonly
 * @enum {{name: string, hex: string}}
 */
 const TradeTypes = Object.freeze({
  BUY:   { name: "Buy", type: "buy", background: "rgba(14, 159, 110, 1)"},
  SELL:  { name: "Sell", type: "sell", background: "rgba(233, 92, 92, 1)"}
});

if (props.data.configuration === undefined){
  updateNodeData(props.id, { configuration: {} })
} else {
  if (props.data.configuration.background){
  theme.value.background = props.data.configuration.background
  }
}

const applyTradeType = (type) => {
  updateNodeData(props.id, { configuration: type })
  theme.value.background = type.background
}
</script>

<template>
<div class="vue-flow__node-trade_execute_node_container shadow-[0px_10px_20px_0px_rgba(82,100,136,0.75)]">
  <div v-if="Object.keys(props.data.configuration ? props.data.configuration: {}).length == 0" class="absolute inline-flex items-center justify-center w-10 h-10 text-xs font-bold text-white bg-yellow-300 border-2 border-white rounded-full -top-6 -end-4 dark:border-gray-900">
    <span class="material-symbols-outlined text-2xl">warning</span>
  </div>
  <div class="flex flex-col items-center hero" style="max-height: 700px;padding: 12% 0%;margin: 0px auto;text-align: center;" >
  <svg class="vue-flow__node-trade_execute_node_svg_wave_one" viewBox="5 0 80 60">
    <path class="wave" fill="none" stroke="#fff" stroke-width="4" stroke-linecap="round" d="M 0 37.5 c 7.684299348848887 0 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15" />
  </svg>
  <h4 class="text-white text-xl px-6">{{props.label}}</h4>
  <div>
<div class="inline-flex rounded-md shadow-sm mt-2">
  <button @click="applyTradeType(TradeTypes.BUY)" :class="theme.left + theme.active">
    Buy
  </button>
  <button @click="applyTradeType(TradeTypes.SELL)" :class="theme.right">
    Sell
  </button>
</div>
  </div>
  <svg class="vue-flow__node-trade_execute_node_svg_wave_two" viewBox="5 0 80 60">
    <path class="wave" fill="none" stroke="#fff" stroke-width="4" stroke-linecap="round" d="M 0 37.5 c 7.684299348848887 0 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15" />
  </svg>
</div>
  <Handle type="target" :position="Position.Top" />
  </div>
</template>

<style>
.vue-flow__node-trade_execute_node_svg_wave_one {
  height: 7em;
}

.vue-flow__node-trade_execute_node_svg_wave_two {
  height: 7em;
}

.vue-flow__node-trade_execute_node_container {
  background: v-bind('theme.background');
}

.vue-flow__node-trade_execute_node {
  position: absolute;
  border-radius: 3px;
  border-style: dashed !important;
  width: 200px;
  font-size: 12px;
  text-align: center;
  color: var(--vf-node-text);
  cursor: grab;
  transform-origin: 0 0;
  box-sizing: border-box;
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);

  border-color: var(--vf-node-color);
  outline: none;
  border: 1px solid hwb(240 29% 70%);
  box-shadow: 0 0 0 0.5px var(--vf-box-shadow);
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);
}

.wave {
  animation: moveTheWave 2400ms linear infinite;
  stroke-dasharray: 0 16 101 16;
}

@keyframes moveTheWave {

  0% {
    stroke-dashoffset: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    stroke-dashoffset: -133;
    transform: translate3d(-90px, 0, 0);
  }

}
</style>