<script setup>
import {defineProps } from 'vue';

import { Handle, Position } from '@vue-flow/core'

const props = defineProps(['label'])
// const toProperCase = (_string) => {
//     return _string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
// };

// const labelTitlized = toProperCase(props.label.split("-").join(" "))
</script>

<template>
<div class="node-container h-full shadow-[0px_10px_20px_0px_rgba(82,100,136,0.75)]">
  <Handle type="target" :position="Position.Right" />
  <div class="flex justify-center items-center h-48 tracking-tight text-gray-500 md:text-lg dark:text-gray-400">
    <p class="vue-flow__node-stock_aggregator_node-text text-white">{{ props.label }}</p>
  </div>
  <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
  <Handle type="source" :position="Position.Bottom" />
  </div>
</template>

<style>

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.672);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 6s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}



@keyframes animate {

    0%{
        transform: translateY(-600px) rotate(720deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(0) rotate(0deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.vue-flow__node-stock_aggregator_node-text {
    font-weight: 500;
    color: white;
  }

.vue-flow__node-stock_aggregator_node {
  position: absolute;
  border-radius: 3px;
  border-style: dashed !important;
  min-width: 200px;
  height: fit-content;
  width: 200px;
  font-size: 12px;
  text-align: center;
  color: var(--vf-node-text);
  cursor: grab;
  transform-origin: 0 0;
  box-sizing: border-box;
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);

  border-color: var(--vf-node-color);
  outline: none;
  border: 2px solid #707083;
  box-shadow: 0 0 0 0.5px var(--vf-box-shadow);
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);
  background: rgba(14, 159, 110, 1);  
    /* background: linear-gradient(to bottom, rgb(22, 184, 130), rgba(14, 159, 110, 1));   */
}

.vue-flow__node-stock_aggregator_node > .node-container > .vue-flow__handle-top {
  width: 10px;
  height: 10px;
}

.vue-flow__node-stock_aggregator_node > .node-container > .vue-flow__handle-bottom {
  width: 10px;
  height: 10px;
}
</style>