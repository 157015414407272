import { createApp } from 'vue'
import "./assets/styles/vue_flow_index.css";
import "./assets/styles/vue_flow_resize.css";
import DashboardTrade from './DashboardTrade.vue'
import PageBotTraderDashboard from './PageBotTraderDashboard.vue'
import PageBotTraderCreate from './PageBotTraderCreate.vue'
import PageBotTraderInstance from './PageBotTraderInstance.vue'
import PageInvestments from './PageInvestments.vue'
import DashboardOptions from './DashboardOptions.vue'
import PageDashboard from './PageDashboard.vue'
import DashboardComposer from './DashboardComposer.vue'
import ReportingDashboard from './ReportingDashboard.vue'
import PageHome from './PageHome.vue'
import PagePricing from './PagePricing.vue'
import App from './App.vue'
import PageLogin from './PageLogin.vue'
import PagePasswordReset from './PagePasswordReset.vue'
import PageRegister from './PageRegister.vue'
import AccountPage from './AccountPage.vue'
import PageChat from './PageChat.vue'

import PrivacyPolicy from './views/PrivacyPolicy.vue';
import PageDisclaimer from './views/PageDisclaimer.vue';
import RefundPolicy from './views/RefundPolicy.vue';
import TermsAndConditions from './views/TermsAndConditions.vue';
//import VueMeta from 'vue-meta'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faArrowRight, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faArrowRight, faBars)


import './assets/styles/index.css';

// Vue.config.productionTip = false

// Vue.use(VueMeta, {
//   // optional pluginOptions
//   refreshOnceOnNavigation: true
// })

if (window.location.pathname == "/"){
  createApp(PageHome)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
}

if (window.location.pathname == "/pricing"){
  createApp(PagePricing)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/login"){
  createApp(PageLogin)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/credentials/password/reset"){
  createApp(PagePasswordReset)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/register"){
  createApp(PageRegister)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/privacy-policy"){
  createApp(PrivacyPolicy)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/disclaimer"){
  createApp(PageDisclaimer)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/return-policy"){
  createApp(RefundPolicy)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/terms-and-conditions"){
  createApp(TermsAndConditions)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/product/plays_stocks"){
  createApp(DashboardTrade)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/product/plays_options"){
  createApp(DashboardOptions)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/dashboard"){
  createApp(PageDashboard)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/product/algo_creator"){
  createApp(DashboardComposer)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/product/portfolio_trader"){
  createApp(PageBotTraderDashboard)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/product/portfolio_operation"){
  createApp(PageBotTraderCreate)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/product/bot_instance"){
  createApp(PageBotTraderInstance)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/product/investments"){
  createApp(PageInvestments)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/reporting_dashboard"){
  createApp(ReportingDashboard)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

// if (window.location.pathname == "/hotpicks_dashboard"){
//   new Vue({
//     render: h => h(HotPicksDashboard),
//   }).$mount('#app')
// }

if (window.location.pathname == "/account"){
  createApp(AccountPage)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/stock"){
  createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}

if (window.location.pathname == "/chat"){
  createApp(PageChat)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
}