<template>
  <div class="p-4 sm:ml-0 md:ml-[17em] md:visible md:block sm:hidden">
      <!-- Breadcrumb -->
      <nav class="justify-between px-4 py-3 text-gray-700 border border-gray-200 rounded-lg sm:flex sm:px-5 bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
        <ol class="inline-flex items-center mb-3 sm:mb-0">
          <li v-for="bread, breadidx in breadArray" :key="bread">
            <div class="flex items-center">
              <a href="#" class="ml-1 mr-2 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">{{bread}}</a>
              <!-- {{breadidx}}{{breadArray.length -1}}{{breadidx <= (breadArray.length - 2)}} -->
              <svg v-if="breadidx <= (breadArray.length - 2)" class="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
              </svg>
            </div>
          </li>
        </ol>

        <!-- <div class="p-2 flex justify-center items-center bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
          <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New Feature</span>
          <span class="font-semibold mr-2 text-left flex-auto">
            <a href="/product/algo_creator" class="underline">Algo Creator 🧙‍♂️ Check it Out!</a>
          </span><svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"></path></svg>
        </div> -->
      </nav>
    </div>
</template>

<script>
export default {
 props: {
   risk_indicators: Object,
   data: Object
 },
 mounted(){
 },
 data(){
    const pathAttrs = {
      "/dashboard": ["Home", "Dashboard"],
      "/product/plays_stocks": ["Home", "Momentum Trader"],
      "/product/portfolio_trader": ["Home", "Strategy Trader"],
      "/product/portfolio_operation": ["Home", "Portfolio Creator"],
      "/product/bot_instance": ["Home", "Strategy Trader", "Explore"],
      "/stock": ["Home", "Stock", "Explore"],
    }
    let breadArray = []
    if (Object.keys(pathAttrs).indexOf(window.location.pathname) > -1){
      breadArray = pathAttrs[window.location.pathname]
    }
     return {
         xhrReportingData: {},
         breadArray
     }
 },
 methods: {

 }
 
}
</script>

<style scoped>
.Header {
  margin-bottom: 15px;
}
.hidden-as-sec-look {
  opacity: 0.3;
  -webkit-text-security: disc;
}
</style>