<template>
<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <div>
      <img class="mx-auto h-12 w-auto" src="images/logos/barpot_logo_only_w_text.svg" alt="Barpot">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Or
        <a href="/register" class="font-medium text-indigo-600 hover:text-indigo-500">
          Register for an account
        </a>
      </p>
    </div>
    <div v-if="isAttemptingAuth == true" class="flex flex-col items-center justify-center">
      <fingerprint-spinner
        :animation-duration="1500"
        :size="120"
        color="#0051d8"
      />
      <p class="mt-2 text-center text-xl text-gray-600">Loading...</p>
    </div>
    <form v-if="isAttemptingAuth == false" id="login_form" class="mt-8 space-y-6" method="POST" @submit="onFormSubmit">
      <input type="hidden" name="remember" value="true">
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="username" class="sr-only">Username</label>
          <input id="username" name="username" type="username" autocomplete="username" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Username">
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <input id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
          <label for="remember-me" class="ml-2 block text-sm text-gray-900">
            Remember me
          </label>
        </div>

        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            Forgot your password?
          </a>
        </div>
      </div>

      <div>
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Sign in
        </button>
      </div>
    </form>
  </div>
</div>
</template>

<script>

import { FingerprintSpinner } from 'epic-spinners'

export default {
  name: 'PageLogin',
  components: {
    FingerprintSpinner
  },
  data () {
      return {
          isAttemptingAuth: true,
          authUrl: `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/`
      }
  },
  mounted(){
    setTimeout(this.checkIsLoginedIn, 2000);
  },
  methods: {
    gotodashboard(){
        window.location.href = '/dashboard'
    },
    async checkIsLoginedIn(){
      await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 200){
                window.location = "/product/portfolio_trader"
              }
              if(response.status == 401){
                this.isAttemptingAuth = false
              }
              return response.json()
            })
            .then(data => {
                console.log(data)
            });
    },
    onFormSubmit(e){
      this.isAttemptingAuth = true
      var formdata = new FormData(document.querySelector('form[id="login_form"]'));

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/`, requestOptions)
        .then(response => {
          if(response.status == 401){
            this.isAttemptingAuth = false
            alert("Invaild login Creditials. Please try again!")
          }
          return response.json()
        })
        .then(result => {
          if (result.access && result.access){
              localStorage.setItem("barpot_jwt_access", result.access)
              localStorage.setItem("barpot_jwt_refresh", result.refresh)
              window.location = "/product/portfolio_trader"
            }
        })
        .catch(error => console.log('error', error));

      e.preventDefault();
    }
    }
}

</script>

<style>

.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
</style>