<template>
    <div class="flex xl:w-1/12 md:w-3/6 sm:w-3/6 flex-col items-center">
        <div class="bar">
            <div class="progress"></div>
        </div>
        <div class="percentage"></div>
    </div>
</template>

<script>
export default {
 props: {
   risk_indicators: Object,
   data: Object
 },
 mounted(){
    const check_percentage = () => {
        let percentage = document.querySelector('.percentage');
        let percentageValue =  +percentage.textContent;
        
        setTimeout(function(){
          if (percentageValue < 100) {
            update_percentage(); 
          }
        }, 500)
      }

      const update_percentage = () => {   
        let percentage = document.querySelector('.percentage');
        if (!percentage){
            return
        }
        let percentageValue =  +percentage.textContent;
        let progress = document.querySelector('.progress');
        
        percentage.textContent = percentageValue + Math.ceil(Math.random() * 5);
        progress.setAttribute('style', `width:${percentageValue + Math.ceil(Math.random() * 5)}%`);
      
        check_percentage();
      }

      check_percentage();
 },
 data(){
     return {
     }
 },
 methods: {
   async subscribeViaEmail(){

   }
 }
 
}
</script>

<style scoped>
.bar {
  background: silver;
  border-radius: 30px;
  margin: 0 0 10px;
  width: 100%;
  height: 2px;
  overflow: hidden;
  position: relative;
  }
.progress{
  background: #568259;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: .1s;
}
    
.percentage{
  font-size: 12px;
  }
  
.percentage:after{
  content: '%';
  }
</style>