<template>

  <baselineLayout>
  <link rel="stylesheet" href="/css/extra.css">
  <link rel="stylesheet" href="/css/SliderAnimation.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
    <baselineBreadCrumbs/>
    <div class="md:p-2 sm:p-1 md:ml-72 sm:ml-2 mx-2 mt-2 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
    <div class="flex justify-center items-center space-x-2 border-b-2 border-gray-100 py-6 hidden">
      <div class="toggle-defines">
        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input v-on:click="toggleRiskClick" checked v-model="store.risk_indicators.low" type="checkbox" name="toggle_low" id="toggle_low" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
            <label for="toggle_low" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
        </div>
        <label for="toggle_low" class="text-base text-gray-700"><span style="background-color: #F1C40F; color: #ffffff;">Low Risk</span></label>
      </div>

      <div class="toggle-defines">
        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input v-on:click="toggleRiskClick" checked v-model="store.risk_indicators.high" type="checkbox" name="toggle_high" id="toggle_high" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
            <label for="toggle_high" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
        </div>
        <label for="toggle_high" class="text-base text-gray-700"><span style="background-color: #3498DB; color: #ffffff;">High Risk</span></label>
      </div>

      <div class="toggle-defines">
        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input v-on:click="toggleRiskClick" checked v-model="store.risk_indicators.moderate" type="checkbox" name="toggle_moderate" id="toggle_moderate" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
            <label for="toggle_moderate" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
        </div>
        <label for="toggle_moderate" class="text-base text-gray-700"><span style="background-color: #2ECC70; color: #ffffff;">Moderate Risk</span></label>
      </div>
    </div>
    <section class="flex flex-col items-center font-mono">
      <!-- <div class="flex flex-col items-center">
        <h1 class="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Barpot Screener</h1>
        <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">These are screener recommendations created by our Algorithm.</p>
      </div>
       <div class="flex w-full m-2 mb-8 space-x-3 place-content-center overflow-x-scroll stories bg-white p-4 rounded drop-shadow-xl mostly-customized-scrollbar">
        <PortfolioBoxes />
      </div> -->
      <div class="flex flex-col items-center">
        <h1 class="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Momentum Trader</h1>
        <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">These are alert recommendations created by our Algorithm.</p>
      </div>
      <a v-if="store.xhrDataCardData.detail" href="/account" class="flex flex-col max-w-sm items-center text-center p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <svg class="w-1/2 mb-4" style="fill: indianred;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
          <path
            d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
        </svg>
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Trader Plan Required</h5>
        <span class="w-8/12 mb-4 tracking-normal text-gray-500 md:text-lg dark:text-gray-400 dark:text-white">Your account is currently limited to our free tier</span>
        <span class="font-semibold text-gray-900 dark:text-white underline decoration-red-500">Sign up for our Trader Plan</span>
      </a>

      <div v-if="store.userData?.subscription_status !== 'subscription.null'" class="flex flex-col w-full">
        <div class="flex justify-center">
          <sliderConfiSelector />
        </div>
        <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
          <div class="w-full overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                  <th class="px-4 py-3">Symbol</th>
                  <th class="px-4 py-3">Metrics</th>
                  <th class="px-4 py-3">Strategy</th>
                  <!-- <th class="px-4 py-3">Momentum Status</th> -->
                  <!-- <th class="px-4 py-3">Entry Price</th> -->
                  <th class="px-4 py-3">Alert Time</th>
                </tr>
              </thead>
              <tbody v-for="symbolStatus in store.xhrDataCardData['signal_stream']" v-bind:key="symbolStatus.symbol + symbolStatus.lastest_trade_time + symbolStatus.entryprice">
                <StockSnippitTable
                          v-bind:xhrData="store.xhrDataCardData"
                          v-bind:key="symbolStatus.symbol + symbolStatus.timestamp"
                          v-bind:data="symbolStatus"
                          v-bind:risk_indicators="store.risk_indicators"
                          >{{symbolStatus}}</StockSnippitTable>
              </tbody>

              
            </table>
            <div v-if="store.xhrDataCardData.signal_stream.length <= 0" class="flex w-full overflow-x-auto justify-center py-4">
                <semipolar-spinner
                  :animation-duration="2000"
                  :size="120"
                  color="#0051d8"
                />
            </div>
            <div class="flex flex-row w-full mx-auto px-4 sm:px-6 sm:py-6 justify-center">
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <a v-on:click="handleBeginPage" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Next</span>
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-5 w-5" fill="currentColor" aria-hidden="true"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 415.1V96.03c0-17.67 14.33-31.1 31.1-31.1C49.67 64.03 64 78.36 64 96.03v131.8l171.5-156.5C256.1 54.28 288 68.66 288 96.03v131.9l171.5-156.5C480.1 54.28 512 68.66 512 96.03v319.9c0 27.37-31.88 41.74-52.5 24.62L288 285.2v130.7c0 27.37-31.88 41.74-52.5 24.62L64 285.2v130.7c0 17.67-14.33 31.1-31.1 31.1C14.33 447.1 0 433.6 0 415.1z"/></svg>
                </a>

                <a v-on:click="handlePreviousPage" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Previous</span>
                  <!-- Heroicon name: solid/chevron-left -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </a>
                <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
                <!--
                <a href="#" aria-current="page" class="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 1 </a>
                <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 2 </a>
                <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 3 </a>
                -->
                <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"> {{store.xhrDataCardData["pagination"]["curr_page"]}} of {{store.xhrDataCardData["pagination"]["page_total"]}} </span>
                <!--
                <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 8 </a>
                <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 9 </a>
                <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 10 </a>
                -->
                <a v-on:click="handleNextPage" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Next</span>
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </a>

                <a v-on:click="handleLastPage" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                  <span class="sr-only">Next</span>
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-5 w-5" fill="currentColor" aria-hidden="true"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 96.03v319.9c0 17.67-14.33 31.1-31.1 31.1C462.3 447.1 448 433.6 448 415.1V284.1l-171.5 156.5C255.9 457.7 224 443.3 224 415.1V284.1l-171.5 156.5C31.88 457.7 0 443.3 0 415.1V96.03c0-27.37 31.88-41.74 52.5-24.62L224 226.8V96.03c0-27.37 31.88-41.74 52.5-24.62L448 226.8V96.03c0-17.67 14.33-31.1 31.1-31.1C497.7 64.03 512 78.36 512 96.03z"/></svg>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
</section>
</div>
  </baselineLayout>
</template>

<script>
import StockSnippitTable from './components/StockSnippitTable.vue'
import { SemipolarSpinner  } from 'epic-spinners'
import { reactive } from 'vue'
import sliderConfiSelector from './components/sliderConfiSelector.vue'
import baselineLayout from './components/baselineLayout.vue'
import baselineBreadCrumbs from './components/baselineBreadCrumbs.vue'

const store = reactive({
      xhrDataCardDataPopular: [
        ],
      xhrDataCardDataETF: [
        ],
      xhrDataCardData: {
        signal_stream: [],
        pagination: {
          curr_page: "",
          page_total: ""
        }
      }
        ,
      risk_indicators: {
          low: true,
          high: true,
          moderate: true
      },
      userData: {
        username: "...",
        email: "...",
        info: {
          phone_number: ""
        }
      },
})
export default {
  name: 'DashboardTrade',
  components: {
    StockSnippitTable,
    SemipolarSpinner,
    // PortfolioBoxes,
    baselineLayout,
    baselineBreadCrumbs,
    sliderConfiSelector
  },
  data () {
      return {store}
  },
  mounted(){
      this.refreshToken();
      this.getUser();
      setInterval(() => {
        console.log("Auto JWT refresh");
        this.refreshToken();
      }, 60000);

      this.populateGrid();
      setInterval(() => {
            let date = new Date();
            // console.log(date.getSeconds());
            if (date.getSeconds() == 1){
                this.populateGrid();
            }
        },999);
  },
  methods: {
        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => response.json())
            .then(data => {
              store.userData = data
              localStorage.setItem("username", data["info"]["username"])
            })
            .catch(error => console.log('error', error));
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        handleAccount(){
          window.location = "/account"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
        async populateGridAsOverviewAsNonPremiumMember(){
            let currUrl = new URL(window.location.href);
            let apiUrl = new URL(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream_overview`)
            for (const [key, value] of currUrl.searchParams.entries()) {
             apiUrl.searchParams.set(key, value)
            }
            await fetch(apiUrl.href, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                store.xhrDataCardData = {
                  ...store.xhrDataCardData,
                  ...data,
                }
            });
        },
        async toggleRiskClick(){
          this.populateGrid()
        },
        async populateGrid(){
            let currUrl = new URL(window.location.href);
            let c_page = currUrl.searchParams.get("page_num");
            let risk_types = [];
            if(document.getElementById("toggle_low").checked){
              risk_types.push("risk_low")
            }
            if(document.getElementById("toggle_high").checked){
              risk_types.push("risk_high")
            }
            if(document.getElementById("toggle_moderate").checked){
              risk_types.push("risk_moderate")
            }
            risk_types = risk_types.join(",")
            if (risk_types.length <= 0){
              risk_types = ["risk_low"]
            }
            let apiUrl = new URL(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream?page_num=${c_page}&risk_types=${risk_types}`)
            for (const [key, value] of currUrl.searchParams.entries()) {
             apiUrl.searchParams.set(key, value)
            }
            await fetch(apiUrl.href, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                this.populateGridAsOverviewAsNonPremiumMember()
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                store.xhrDataCardData = {
                  ...store.xhrDataCardData,
                  ...data,
                }
            });
        },
        onStockSubmit(event){
          if (event.keyCode === 13){
            window.location.href = `/stock?symbol=${event.target.value}`
          }
          
        },
        handlePreviousPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0){
            c_page = 0;
          }
          url.searchParams.set('page_num', (c_page - 1));
          window.location.href = url.href;
        },
        handleNextPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0 || isNaN(c_page) == true){
            c_page = 0;
          }
          url.searchParams.set('page_num', store.xhrDataCardData.pagination.next_page);
          window.location.href = url.href;
        },
        handleBeginPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', 0);
          window.location.href = url.href;
        },
        handleLastPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', store.xhrDataCardData.pagination.page_total);
          window.location.href = url.href;
        },
        normalizeTime(){
            console.log(store.users)
        },
        generateRandomNumber(min, max) {
            let highlightedNumber = Math.random() * (max - min) + min;
            return(highlightedNumber);
        }
    }
}

</script>

<style>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 95%;
  height: 35vw;
  padding: 16px 6px 16px 16px;
}
</style>