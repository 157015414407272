<template>
    <div class="flex justify-center">
        <div class="flex rounded-lg shadow-sm">
            <button v-for="time_item in Object.keys(defaultData)"
            :key="time_item"
            type="button"
            v-on:click="onPeriodSelect(defaultData[time_item].value)"
            :class="respTimePeriodConfig?.config?.value == defaultData[time_item].value ? this.active_class : this.default_class">
              {{time_item}}
            </button>
        </div>
    </div>
</template>

<script>

export default {
 props: {
  defaultData: Object,
  respTimePeriodConfig: Object,
 },
 components: {
  },
 data(){
     return {
        default_class: "py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
        active_class: "py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-blue-500 text-white shadow-sm hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
     }
 },
 methods: {
    onPeriodSelect(time_period_identifier){
        let currPath = new URL(window.location.href);
        currPath.searchParams.set('time_span', time_period_identifier)
        window.location.href = currPath.toString();
    }
 }
}
</script>

<style scoped>

</style>