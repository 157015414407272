<script setup>
import {defineProps } from 'vue';
import { Handle, Position} from '@vue-flow/core'
import { NodeToolbar } from '@vue-flow/node-toolbar'

const props = defineProps(['id', 'data', 'label', 'removeNodes', 'findNode'])

const operationDeleteNode = (nodeId) => {
    props.removeNodes([props.findNode(nodeId)])
}
</script>

<template>
<div>
  <NodeToolbar
    style="display: flex; gap: 0.5rem; align-items: center"
    :is-visible="props.data.toolbarVisible"
    :position="props.data.toolbarPosition">
        <div class="flex flex-col items-center">
            <button @click="operationDeleteNode(props.id)" type="button" class="text-white p-3 border-2 border-gray-600 hover:bg-red-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" style=" fill: #f63824; font-size: 2em; ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="0.5em" height="0.5em"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
            </button>
        </div>
  </NodeToolbar>

  <!-- <div :style="{ padding: '10px 20px' }">
    {{ label }}
  </div> -->

  <Handle type="target" :position="Position.Left" />
  <Handle type="source" :position="Position.Right" />
  </div>
</template>
