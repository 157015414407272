<script setup>
import { MiniMap, Controls, Background, Panel, PanelPosition } from '@vue-flow/additional-components'
import { VueFlow, useVueFlow, MarkerType, } from '@vue-flow/core'
import { nextTick, watch } from 'vue'
import ComposerSidebar from './ComposerSidebar.vue'
import ResizableNode from './vue-flow-nodes/ResizableNode.vue'
import CustomNode from './vue-flow-nodes/StandardNode.vue'
import StandardInputNode from './vue-flow-nodes/StandardInputNode.vue'
import ToolbarNode from './vue-flow-nodes/ToolbarNode.vue'
import TriggerNode from './vue-flow-nodes/TriggerNode.vue'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

// make sure to include the necessary styles!
import '@vue-flow/node-resizer/dist/style.css'

const $toast = useToast();


class StorageLocal {
  static getNodesData(symbol){
    try {
      let data = JSON.parse(localStorage.getItem("composer_node_data"))
      return [...data[symbol]["_object"]["nodes"], ...data[symbol]["_object"]["edges"]]
    } catch (err) {
      console.log("ERROR getNodesData", err)
      return []
    }
  }

  static save(symbol, nodesData){
    console.log("Saving to LocalStorage")
    localStorage.setItem("composer_node_data", JSON.stringify({[symbol]: nodesData}))
  }

  static all(symbol){
    let data = JSON.parse(localStorage.getItem("composer_node_data"))
    if (data == null){
      return null
    }
    return data[symbol]
  }
}

let id = 0
const getId = () => `${id++}`

let cached_node = [...StorageLocal.getNodesData()]
console.log("cached_node", cached_node)

let { onPaneReady, onNodeDrag, getIntersectingNodes, getNodes, isNodeIntersecting, onNodeDragStop, findNode, onConnect, nodes, edges, addEdges, addNodes, removeNodes, viewport, project, vueFlowRef } = useVueFlow({
  fitViewOnInit: true,
  // set this to true so edges get elevated when selected, defaults to false
  elevateEdgesOnSelect: true,
  nodes: [],
  // [
  //   { id: '1', type: 'input', label: 'Node 1', position: { x: 250, y: 5 } },
  // { id: '2', type: 'output', label: 'Node 2', position: { x: 100, y: 100 } },
  // { id: '3', label: 'Node 3', position: { x: 400, y: 100 } },
  // { id: '4', label: 'Node 4', position: { x: 150, y: 200 } },
  // { id: '5', type: 'output', label: 'Node 5', position: { x: 300, y: 300 } },
  // { id: 'e1-2', source: '1', target: '2', animated: true },
  // { id: 'e1-3', label: 'edge with arrowhead', source: '1', target: '3', markerEnd: MarkerType.Arrow },
  // { 
  //   id: 'e4-5', 
  //   type: 'step', 
  //   label: 'step-edge', 
  //   source: '4', 
  //   target: '5', 
  //   style: { stroke: 'orange' }, 
  //   labelBgStyle: { fill: 'orange' } 
  // },
  // { id: 'e3-4', type: 'smoothstep', label: 'smoothstep-edge', source: '3', target: '4' },
  // {
  //     id: 'parent0',
  //     type: 'resizable',
  //     label: 'nested parent node',
  //     position: { x: 15, y: 120 },
  //     style: { backgroundColor: 'rgba(139, 92, 246, 0.5)', height: '150px', width: '270px', border: '2px solid black'  },
  //     parentNode: '4',
  //   },
  //   {
  //     id: 'parent0:c0',
  //     label: 'nested child node',
  //     position: { x: 20, y: 40 },
  //     parentNode: 'p0',
  //   },
  // ],
})
console.log(nodes, edges, viewport, MarkerType, getIntersectingNodes, isNodeIntersecting)

const populateBasedPreselectedModelId = () => {
    let daa = []
    var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/models`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              store.composerStockModelInput = respData.data[currPath.searchParams.get('composer_model_id')].name
              addNodes(respData.data[currPath.searchParams.get('composer_model_id')].data.nodes)
              addEdges(respData.data[currPath.searchParams.get('composer_model_id')].data.edges)
              return
            })
            .catch(error => console.log('error', error));
    console.log("warrrrppp", daa)
    return daa
  }
if (currPath.searchParams.get('composer_model_id') != null){
  populateBasedPreselectedModelId()
} else {
  addNodes(cached_node)
}
const onDragOver = (event) => {
  event.preventDefault()

  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'move'
  }
}

onPaneReady(({ fitView }) => {
  fitView()
})

onNodeDragStop((e) => {
  console.log('drag stop', e)
  // e.node.parentNode
  if(e.intersections.length <= 0 && e.node.parentNode != null){
    e.node.parentNode = null
    console.log("Node Par Removed")
  } else {
    console.log("acc", e)
    if(e.node.type == "standard_node" || e.node.type == "standard_input_node"){
      e.intersections.map( (eS) => {
        console.log("Apples", eS)
        if (["stock_data_aggregator"].indexOf(eS.type) >= -1){
          console.log("Viable Par found", eS)
          if (e.node.parentNode != eS.id){
            console.log("setting at intersec", e.node, eS)
            e.node.parentNode = eS.id
          }
        }
      })
    }
  }
})

onNodeDrag(({ intersections }) => {
  if (window.onbeforeunload == null){
    console.log("APLLING")
      window.onbeforeunload = function () {
        return "Are you sure you want to leave?";
      };
  }
  const intersectionIds = intersections.map((intersection) => intersection.id)
  getNodes.value.forEach((n) => {
    const isIntersecting = intersectionIds.includes(n.id)
    n.class = isIntersecting ? 'intersecting' : ''
  })
})

// const getNameData = () => {
//   let saveId = store.urlObject.searchParams.get('composer_model_id') ? store.urlObject.searchParams.get('composer_model_id') : store.urlObject.searchParams.get('symbol')
//   let _userData = StorageLocal.all(saveId)
//   return _userData?.model_name !== (null || undefined) ? _userData?.model_name : getRandomName()
// }

const apiRequestComposerStartTrainModel = async () => {
  console.log("apiRequestComposerStartTrainModel")
  let constructure = {...{"version": 1, composer_model_id: currPath.searchParams.get('composer_model_id')}}
  // Can accept an Object of options

  fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/train_model/`, {
    method: "POST", // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
    },
    body: JSON.stringify(constructure),
  })
  .then((response) => response.json())
  .then((data) => {
    console.log("Success:", data);

    if(data.status == "ok"){
      $toast.open({
        message: data.message + ' 🚀',
        type: 'success',
        duration: 10000,
        position: 'bottom'
        // all of other options may go here
      });
    }

    if(data.status == "error"){
      $toast.open({
        message: data.message + ' 🚀',
        type: 'error',
        duration: 10000,
        position: 'bottom'
        // all of other options may go here
      });
    }

  })
  .catch((error) => {
    console.error("Error:", error);
  });
}

const apiRequestComposerSaveModel = async () => {
  console.log("apiRequestComposerSaveModel")

  let saveId = store.urlObject.searchParams.get('composer_model_id') ? store.urlObject.searchParams.get('composer_model_id') : store.urlObject.searchParams.get('symbol')

  // Pre-Checks
  let _userData = StorageLocal.all(saveId)
  if (_userData !== (undefined || null)){
    if(document.getElementById("composer_model_name_input").value == '' || document.getElementById("composer_model_name_input").value.length <= 1){
      alert("Please Enter a Model Name to Save")
      return
    }
  }
  
  let constructure = {...{"version": 1, composer_model_id: currPath.searchParams.get('composer_model_id')}, ...{data: {meta: {symbol: currPath.searchParams.get('symbol')}, nodes: nodes._object.nodes, edges: nodes._object.edges,  model_name: document.getElementById("composer_model_name_input").value}}}

  fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/save_model/`, {
    method: "POST", // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
    },
    body: JSON.stringify(constructure),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      if (data.status == "ok"){
          $toast.open({
            message: `${data.message} 🪄`,
            type: 'info',
            duration: 10000,
            position: 'bottom'
        });
      }
      if (data.status == "error"){
          $toast.open({
            message: data.message,
            type: 'error',
            duration: 10000,
            position: 'bottom'
        });
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });

    // Save to local
    StorageLocal.save(saveId, {...nodes, ...edges, ...{model_name: document.getElementById("composer_model_name_input").value}})
}

onConnect((params) => {
  console.log("fjdohfd", nodes)
  addEdges([params])
})

const randomId = function(length = 6) {
  return Math.random().toString(36).substring(2, length+2);
};

const onDrop = (event) => {
  let _parentNode = null;
  console.log(event)
  if (event.srcElement.dataset.id){
    console.log("pass")
    if (event.srcElement.dataset.id.indexOf(":") > -1){
      console.log("is parent child", event.srcElement.dataset)
      if (event.srcElement.dataset.id.indexOf("parent") > -1){
        _parentNode = event.srcElement.dataset.id
        console.log("parent drop")
      }
    } else {
       console.log("Not a parent below")
    }
  }
  const type = event.dataTransfer?.getData('application/vueflow-type')
  const label = event.dataTransfer?.getData('application/vueflow-label')
  const attributeType = event.dataTransfer?.getData('application/vueflow-attribute-type')
  const { left, top } = vueFlowRef.value.getBoundingClientRect()

  console.log("EVENT", event)
  const position = project({
    x: event.clientX - left, // - event.offsetX,
    y: event.clientY - top, // - event.layerY,
  })

  console.log("first pass", id, getId(), type, position)
  let _id = ""
  if (type == "stock_data_aggregator"){
    _id = "dndnode_parent:" + randomId(13)
  } else {
    _id = "dndnode_" + randomId(12)
  }
  const newNode = {
    id: _id,
    type: `${type}`,
    position,
    label: `${label}`,
    parentNode: _parentNode,
    data: {attributeType: attributeType}
  }
  console.log("Ondrop", position, newNode)

  addNodes([newNode])

  // align node position after drop, so it's centered to the mouse
  nextTick(() => {
    const node = findNode(newNode.id)
    const stop = watch(
      () => node.dimensions,
      (dimensions) => {
        if (dimensions.width > 0 && dimensions.height > 0) {
          node.position = { x: node.position.x - node.dimensions.width / 2, y: node.position.y - node.dimensions.height / 2 }
          stop()
        }
      },
      { deep: true, flush: 'post' },
    )
  })

   // StorageLocal.save(nodes)
}
</script>
<template>
  <div class="flex flex-col relative bg-white">
  <link rel="stylesheet" href="/css/extra.css">
  <link rel="stylesheet" href="/css/SliderAnimation.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
    <!-- This example requires Tailwind CSS v2.0+ -->
<interHeader/>
<div class="text-center pt-2 lg:px-4">
  <div v-if="store.userData.info.phone_number == null" class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
    <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
    <span class="font-semibold mr-2 text-left flex-auto"><a href="/account" class="underline">Enter your phone number on the account page to receive text message alerts!</a></span>
    <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
  </div>
  <!-- <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
    <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">P/L Performance</span>
    <span class="font-semibold mr-2 text-left flex-auto"><a href="/reporting_dashboard" class="underline">Click Here to view our alert P/L Win Rate.</a></span>
    <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
  </div> -->
  <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
    <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New Feature</span>
    <span class="font-semibold mr-2 text-left flex-auto"><a href="/product/algo_creator" class="underline">Welcome to Composer 👋</a></span>
    <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
  </div>
</div>
<div class="min-h-screen flex mx-4 ">
  
  <nav class="flex flex-col w-min" style="width: min-content; min-width: 15em;">
    <ul class="flex flex-wrap mb-4 justify-center items-center text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li class="mr-2">
          <button href="#" class="inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500">Symbols</button>
        </li>
    </ul>
    <div class="flex justify-between items-center border-b-2 border-gray-100 py-4 mb-3">
        <!-- Search Button -->
        <div class="max-w-md mx-auto">
          
    <div class="relative">
      <div class="h-10 bg-white flex border border-gray-200 rounded items-center">
        <input @keyup="onPressSymbolSearch($event)" placeholder="Enter Your Symbol" name="select" id="select" class="px-4 appearance-none outline-none text-gray-800 w-full" checked />

        <button class="cursor-pointer outline-none focus:outline-none transition-all text-gray-300 hover:text-gray-600">
          <svg class="w-4 h-4 mx-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <!-- <label for="show_more" class="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-300 hover:text-gray-600">
          <svg class="w-4 h-4 mx-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
        </label> -->
      </div>

      <input type="checkbox" name="show_more" id="show_more" class="hidden peer" checked />
      <div class="z-10 absolute w-full">
      <div v-for="stockSearch in store.xhrDataStockSearch.data" v-bind:key={stockSearch} class="z-10 rounded shadow bg-white flex-col w-full border border-gray-200">
        <div class="group">
          <a @click="getHrefForStock($event, stockSearch.symbol)" class="block p-2 border-transparent border-l-4 group-hover:border-blue-600 group-hover:bg-gray-100">{{stockSearch.symbol}}</a>
        </div>
      </div>
    </div>
    </div>
  </div >
      </div>
      <div v-if="store.httpUserInfo.status != 'ok'" class="flex justify-center">
      <self-building-square-spinner
  :animation-duration="6000"
  :size="40"
  color="#48bb78"
/>
</div>
    <ul v-if="store.httpUserInfo.status == 'ok'"  class="flex flex-col mb-4  text-sm font-medium text-center text-gray-500 dark:border-gray-700 dark:text-gray-400">
        <li style=" min-width: 11em; max-width: 17em;" v-if="Object.values(store.httpUserInfo.composer.favorite_stocks).map(x => x.symbol).indexOf(store.urlObject.searchParams.get('symbol')) == -1 && store.urlObject.searchParams.get('symbol') != null" class="flex flex-row mr-2 mb-2 w-full" v-bind:key={item}>
          <div class=" max-w-sm flex flex-col rounded-lg overflow-hidden bg-white shadow">
            <!-- card content -->
            <div class="flex-1 px-12 py-2 justify-center items-center leading-none lg:rounded-full flex lg:inline-flex text-gray-500">
            <a data-v-4c42232a="" @click="getHrefForStock($event, store.urlObject.searchParams.get('symbol'))" class="text-base text-xl font-bold text-gray-500"> $<span data-v-4c42232a="" class="text-1xl text-xl font-bold text-gray-500 uppercase"><span data-v-4c42232a="" class="underline px-1">{{store.urlObject.searchParams.get('symbol')}}</span></span></a>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current opacity-75 h-6 w-8"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"></path></svg>
            </div>
            <!-- end card content -->
          </div>
          <button @click="addUserStockApi($event, store.urlObject.searchParams.get('symbol'))" class="bg-green-500 mx-1 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded" style="height: max-content;">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </button>
        </li>
        <li style=" min-width: 11em; max-width: 17em;" class="flex flex-row justify-evenly mr-2 mb-2 w-full" v-for="item in Object.values(store.httpUserInfo.composer.favorite_stocks)" v-bind:key={item}>
          <div class="max-w-sm flex flex-col rounded-lg overflow-hidden bg-white shadow"> 
            <!-- card content -->
            <div class="flex-1 px-12 py-2 justify-center items-center leading-none lg:rounded-full flex lg:inline-flex text-gray-500">
            <a data-v-4c42232a="" @click="getHrefForStock($event, item.symbol)" class="text-base text-xl font-bold text-gray-500"> $<span data-v-4c42232a="" class="text-1xl text-xl font-bold text-gray-500 uppercase"><span data-v-4c42232a="" class="underline px-1">{{item.symbol}}</span></span></a>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current opacity-75 h-6 w-8"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"></path></svg>
            </div>
            <!-- end card content -->
          </div>
          <button class="bg-red-300 mx-1 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-400 hover:border-red-500 rounded" style="height: max-content;">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </button>
        </li>
    </ul>
  </nav>
  <nav class="w-min" v-if="!showWarningToSelectSymbol()" style="width: min-content;" >
    <ul class="flex flex-wrap mb-4 justify-center items-center text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li class="mr-2">
          <button href="#" class="inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500">Neural Models</button>
        </li>
    </ul>
    
    <div class="relative overflow-x-hidden mostly-customized-scrollbar" style="height: 70vh; box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px; padding: 0.7em 1em; margin: 2em 1em;">
    
    <div class="flex flex-col justify-center mb-2">
      <button @click="onClickComposerHandleLiveChartEnviroment()" class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"> View Live Chart 📊 </button>
    </div>
    
    <div  v-if="store.urlObject.searchParams.get('composer_environment') == 'live_chart'"  class="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
      <strong class="font-bold">Live Chart Mode</strong>
      <span class="block sm:inline"> | Take a look at all the momentum markers.</span>
    </div>
    <div  v-if="store.urlObject.searchParams.get('composer_environment') == 'model_exploration'"  class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
      <strong class="font-bold">Model Exploration Mode</strong>
      <span class="block sm:inline"> | Modify your parameters in the control panel on the right.</span>
    </div>
    <div  v-if="store.urlObject.searchParams.get('composer_environment') == 'model_creation' && store.urlObject.searchParams.get('composer_model_id') == null"  class="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
      <strong class="font-bold">Model Creation Mode</strong>
        <span class="block sm:inline"> | Modify your parameters in the control panel on the right.</span>
    </div>
    <div  v-if="store.urlObject.searchParams.get('composer_environment') == 'model_creation' && store.urlObject.searchParams.get('composer_model_id') != null"  class="bg-yellow-300 border border-yellow-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
      <strong class="font-bold">Model Edit Mode</strong>
        <span class="block sm:inline"> | Modify your parameters in the control panel on the right.</span>
    </div>

    <table class="w-max text-sm text-left text-gray-500 dark:text-gray-400" style="width: max-content;">
              
        <tbody :v-if="store.composerModelsData.status == 'ok'">
            <tr :class="'flex justify-between items-center mt-2 border-b dark:bg-gray-800 dark:border-gray-700 bg-white-600 hover:bg-teal-400 ' + (store.urlObject.searchParams.get('composer_model_id') == store.composerModelsData.data[model]._id ? 'bg-green-200' : 'bg-white') " v-for="model in Object.keys(Object.fromEntries( Object.entries(store.composerModelsData.data).filter(([key, value]) => ['draft', 'training', 'stop'].indexOf(value.status) >= 0)))" v-bind:key={model}>
                <button scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300">
                  <div class="flex items-center space-x-4"> 
                    <div class="flex-shrink-0">
                      <img style="width: 2vw" :src="'https://api.dicebear.com/5.x/bottts/svg?seed=' + store.composerModelsData.data[model]._id" alt="avatar" />
                  </div>
                  <div class="flex flex-col" style="max-width: 8em">
                      <p class="text-sm font-medium text-gray-900 truncate text-center dark:text-white">
                        {{store.composerModelsData.data[model].name }}
                      </p>
                      <p class="text-sm text-gray-500 dark:text-gray-400">
                        {{moment(store.composerModelsData.data[model].updated_at).format('MMM Do, YYYY h:mm A')}}
                      </p>
                  </div>
                  </div>
                </button>
                <td class="mr-2">
                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      <span 
                        class="bg-green-100 p-2 text-green-800
                              text-xs font-medium mr-2 px-2.5 py-0.5 rounded capitalize
                              dark:bg-gray-700 dark:text-green-400 border border-green-400"
                      >{{store.composerModelsData.data[model].status}}</span>
                  </div>
                </td>
                <td class="mr-2">
                    <!-- <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {{store.composerModelsData.data[model].winrate_precent}}%
                  </div> -->
                </td>
                <td class="mr-2">
                    <div class="flex m-2">
                      <button @click="onClickComposerModelEditHandle(store.composerModelsData.data[model])"  class="text-base  rounded-r-none  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                        hover:bg-gray-400  
                        bg-blue-100 
                        text-gray-700 
                        border duration-200 ease-in-out 
                        border-gray-600 transition">
                          <div class="flex leading-5">Edit</div>
                      </button>

                      <button v-if="store.composerModelsData.data[model].status == 'stop'" @click="onClickComposerModelOperationHandle(store.composerModelsData.data[model])"  class="text-base  rounded-r-none  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 font-bold cursor-pointer 
                        hover:bg-green-400  
                        bg-green-300 
                        text-gray-700 
                        border duration-200 ease-in-out
                        border-gray-600 transition">
                          <div class="flex leading-5">Start</div>
                      </button>

                      <button @click="onClickComposerModelOperationHandle(store.composerModelsData.data[model])" class="text-base rounded-l-none border-l-0  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                        hover:bg-gray-500  
                        bg-red-300 
                        text-gray-700 
                        border duration-200 ease-in-out 
                        border-green-600 transition">
                          <div class="flex leading-5">{{store.composerModelsData.data[model].status == "training" ? "Stop" : "Delete"}}

                          </div>
                      </button>
                  </div>
                </td>
            </tr>
        </tbody>
        
        <div :class='store.urlObject.searchParams.get("tab") != "profiler" ? "flex justify-between font-bold" : "flex justify-center font-bold"'>
          <div class="flex flex-col justify-center">
            <span class="text-bold text-2xl py-4 underline">Your Models</span>
          </div>
          <div v-if="store.urlObject.searchParams.get('composer_tab') != 'profiler'" class="flex flex-col justify-center">
            <button @click="onClickModelCreateButtonHandle()"  class="bg-green-600 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"> Create Model </button>
          </div>
        </div>

        <thead class="flex justify-center text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr class="space-x-12">
                <th scope="col" class="px-12 py-3">
                    Name
                </th>
                <th scope="col" class="px-12 py-3">
                    Confidence
                </th>
                <th scope="col" class="px-12 py-3">
                    Actions
                </th>
            </tr>
        </thead>
        
        <div v-if='store.stockModelsData.status !== "ok"' class="flex flex-col justify-start mt-12 text-center items-center">
            <fingerprint-spinner
                :animation-duration="2000"
                :size="160"
                color="#0051d8"
            />
            <hr class="my-4"/>
            <p class="mt-2 text-center text-2xl text-gray-600">Loading<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></p>
        </div>
        
        <div v-if='Object.keys(store.stockModelsData.data).length == 0 && store.stockModelsData.status == "ok"' class="flex flex-col justify-start mt-12 text-center items-center">
          <div class="flex w-full overflow-x-auto justify-center py-8">
            <div class="flex w-24">
              <svg style="stroke: khaki;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-24 h-24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
              </svg>
            </div>
          </div>
            <p class="flex flex-col mt-2 mb-6 text-center text-2xl text-gray-600">No Models Found...<span>Maybe it's time to create it. 🪄</span></p>
        </div>

        <tbody :v-if="store.composerModelsData.status == 'ok'">
            <tr :class="'flex justify-between items-center border-b dark:bg-gray-800 dark:border-gray-700 bg-white-600 hover:bg-gray-400 ' + (store.urlObject.searchParams.get('composer_model_id') == store.composerModelsData.data[model]._id ? 'bg-green-200' : 'bg-white')" v-for="model in  Object.keys(Object.fromEntries( Object.entries(store.composerModelsData.data).filter(([key, value]) => value.status === 'trained')))" v-bind:key={model}>
                <button scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300">
                  <div class="flex items-center space-x-4"> 
                    <div class="flex-shrink-0">
                      <img style="width: 2vw" :src="'https://api.dicebear.com/5.x/bottts/svg?seed=' + store.composerModelsData.data[model]._id" alt="avatar" />
                  </div>
                  <div class="flex flex-col" style="max-width: 8em">
                      <p class="text-sm font-medium text-gray-900 truncate text-center dark:text-white">
                        {{store.composerModelsData.data[model].name}}
                      </p>
                      <p class="text-sm text-gray-500 dark:text-gray-400">
                        {{moment(store.composerModelsData.data[model].updated_at).format('MMM Do, YYYY h:mm A')}}
                      </p>
                  </div>
                  </div>
                </button>
                <td class="mr-2">
                    <!-- <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {{store.composerModelsData.data[model]?.neural_model_section?.number_of_trades}}
                  </div> -->
                </td>
                <td class="mr-2">
                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {{parseFloat(store.composerModelsData.data[model]?.neural_model_section?.winrate_precent).toFixed(2)}}%
                  </div>
                </td>
                <td class="mr-2">
                    <div class="flex m-2">
            <button @click="onClickUserModelHandle(model)" class="text-base  rounded-r-none  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
              hover:bg-green-400 
                        bg-green-300 
                        text-gray-700 
                        border duration-200 ease-in-out
                        border-gray-600 transition">
                <div class="flex leading-5">Explore</div>
            </button>
            <button @click="onClickComposerModelEditHandle(store.composerModelsData.data[model])" class="text-base border-l-0 rounded-r-none  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 font-bold cursor-pointer 
                        hover:bg-gray-400  
              bg-blue-100 
              text-gray-700 
              border duration-200 ease-in-out 
              border-gray-600 transition">
                          <div class="flex leading-5">Modify</div>
                      </button>
            <button @click="onClickComposerModelOperationHandle(store.composerModelsData.data[model])" class="text-base rounded-l-none border-l-0  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                        hover:bg-gray-500  
                        bg-red-300 
                        text-gray-700 
                        border duration-200 ease-in-out 
                        border-green-600 transition">
                <div class="flex leading-5">Delete

                </div>
            </button>
        </div>
                </td>
            </tr>
        </tbody>
        <div v-if='store.stockModelsData.status == "ok"'>
          <div class="flex align-center justify-left font-bold border-t-2 border-gray-100">
            <span class="text-bold text-2xl py-4 underline">Barpot Models</span>
          </div>
        </div>

        <div v-if='store.stockModelsData.status != "ok"'>
          <div class="flex flex-row align-center justify-center font-bold underline">
            <div class="flex flex-col align-center justify-center font-bold underline">
              <img src="/images/127760-nothing.gif" alt="this slowpoke moves" width="100" style="width: 15em;">
              <span class="text-bold text-md py-4">No Community Models Found</span>
            </div>
          </div>
        </div>

        <tbody :v-if="store.stockModelsData.status == 'ok'">
            <tr :class="'flex justify-between items-center border-b dark:bg-gray-800 dark:border-gray-700 bg-white-600 hover:bg-gray-400 ' + (store.urlObject.searchParams.get('stock_model_id') == store.stockModelsData.data[model]._id ? 'bg-green-200' : 'bg-white')" v-for="model in Object.keys(store.stockModelsData.data)" v-bind:key={model}>
                <button scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300">
                  <div class="flex items-center space-x-4"> 
                    <div class="flex-shrink-0">
                      <img style="width: 2vw" :src="'https://api.dicebear.com/5.x/bottts/svg?seed=' + store.stockModelsData.data[model]._id" alt="avatar" />
                  </div>
                  <div class="flex flex-col" style="max-width: 12em">
                      <p class="text-sm font-medium text-gray-900 truncate text-center dark:text-white">
                        {{store.stockModelsData.data[model].model_name}}
                      </p>
                      <p class="text-sm text-gray-500 dark:text-gray-400">
                        {{moment(store.stockModelsData.data[model].updated_at).format('MMM Do, YYYY h:mm A')}}
                      </p>
                  </div>
                  </div>
                </button>
                <td class="px-6 py-4">
                    <!-- <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {{store.stockModelsData.data[model].number_of_trades}}
                  </div> -->
                </td>
                <td class="px-6 py-4">
                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {{parseFloat(store.stockModelsData.data[model]?.dynamic_model_metrics?.winrate_precent).toFixed(1)}}%
                  </div>
                </td>
                <td class="px-6 py-4">
                    <button @click="onClickModelHandle(model)" class="bg-green-600 hover:bg-green-400 text-white font-bold py-2 ml-6 px-4 border-b-4 border-green-700 hover:border-green-500 rounded">
                        Explore
                      </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>


  </nav>
  
  <main class="flex flex-row w-full">
      <div style="height: 70%" class="h-1/6 w-full justify-center items-center">
      <ul class="flex flex-wrap justify-center items-center text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          <li class="mr-2">
              <a @click="onClickTabHandle('chart', {'composer_environment': 'model_exploration'})" :class="correctTabHighlight('chart')">Chart</a>
          </li>
          <li class="mr-2">
              <a @click="onClickTabHandle('profiler', {'composer_environment': 'model_creation'})" :class="correctTabHighlight('profiler')">Profiler</a>
          </li>
          <li class="mr-2">
              <a @click="onClickTabHandle('settings', {'composer_environment': 'model_setting'})" :class="correctTabHighlight('settings')">Settings</a>
          </li>
      </ul>

      <StockChartView v-if="store.urlObject.searchParams.get('composer_tab') == 'chart' && store.urlObject.searchParams.get('symbol') != null &&  store.urlObject.searchParams.get('stock_model_id') != null"/>
      <StockChartView v-if="store.urlObject.searchParams.get('composer_tab') == 'chart' && store.urlObject.searchParams.get('symbol') != null &&  store.urlObject.searchParams.get('composer_environment') == 'live_chart'"/>
      
      <div id="defaultModal" v-if="store.urlObject.searchParams.get('symbol') != null && store.urlObject.searchParams.get('stock_model_id') == null && store.urlObject.searchParams.get('composer_environment') == 'model_exploration' " tabindex="-1" class="flex top-0 left-0 right-0 z-50 w-full p-4 items-center justify-center md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-md md:h-auto">
              <!-- Modal content -->
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <!-- Modal header -->
                  <div class="flex justify-center	p-4 border-b rounded-t dark:border-gray-600">
                      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                          <circles-to-rhombuses-spinner
                            :animation-duration="1200"
                            :circles-num="3"
                            :circle-size="15"
                            color="#ff1d5e"
                            />
                      </h3>
                      <!-- <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span class="sr-only">Close modal</span>
                      </button> -->
                  </div>
                  <!-- Modal body -->
                  <div class="p-6 space-y-6 text-center items-center justify-center">
                      <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                          Please Select a model from the left, or Create a model for exploration 🚀.
                      </p>
                  </div>
              </div>
          </div>
      </div>

      <div id="defaultModal" v-if="showWarningToSelectSymbol()" tabindex="-1" class="flex top-0 left-0 right-0 z-50 w-full p-4 items-center justify-center md:inset-0 h-modal md:h-full">
          <div class="relative w-full h-full max-w-2xl md:h-auto">
              <!-- Modal content -->
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <!-- Modal header -->
                  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                          Hello 👋
                      </h3>
                      <!-- <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span class="sr-only">Close modal</span>
                      </button> -->
                  </div>
                  <!-- Modal body -->
                  <div v-if="store.httpUserInfo.subscription_status == 'subscription.active'" class="p-6 space-y-6 text-center items-center justify-center">
                      <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                          Please Select a Symbol from the left to get started!
                      </p>
                  </div>
                  <div class="flex flex-row w-full mx-auto px-4 sm:px-6 sm:py-6 items-center">
                <div class="flex sm:flex-col md:flex-row flex-row mx-auto px-4 sm:px-6 items-center md:space-x-24">
                  <div v-if="store.httpUserInfo.subscription_status == 'subscription.null'" class="flex flex-col bg-red-100 border border-yellow-400 text-blue-700 text-center px-4 py-3 rounded relative" role="alert">
                    <strong class="font-bold">You are not a Premium Member!</strong>
                    <a href="/account" class="underline">Subscribe to a premium account here to access Composer!</a>
                  </div>
                  <!-- <div class="flex justify-between items-center border-b-2 border-gray-100 py-6">
                    <input class="flex relative space-x-10 px-3 md:w-full py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline" ref="submit" v-on:keyup="onStockSubmit" type="text" placeholder="Search a Symbol..."/>
                  </div> -->

                  <!-- <div class="flex flex-col bg-red-100 border border-yellow-400 text-blue-700 text-center px-4 py-3 rounded relative" role="alert">
                    <a href="/hotpicks_dashboard" class="underline">View All HotPicks</a>
                  </div> -->
                </div>
              </div>
              </div>
              
          </div>
      </div>

    <!-- Settings -->
    <div v-if="store.urlObject.searchParams.get('composer_tab') == 'settings' && store.urlObject.searchParams.get('symbol') != null" class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-8">
      <div class="flex flex-col items-center pb-10">
        <img
        style="width: 4vw"
        src="https://api.dicebear.com/5.x/bottts/svg?seed=b5W9fHZ6pZG288Ig4mHDkGBBkXwioVDi"
        alt="avatar" />

        <div :v-if="store.stockModelsData.status == 'ok'" class="flex flex-col text-center">
          <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Model Name</h5><span
           class="text-sm text-gray-500 dark:text-gray-400">Feb 16th, 2023 @2:23PM</span>
        </div>

      </div>

      <div>
        <div class="mx-6 mb-5">
          <div class="flex rounded-md shadow-sm">
            <span
              class="px-4 flex w-2/6 items-center rounded-l-md border border-r-0 border-gray-200 bg-gray-50 text-sm text-gray-500 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400">Model Name</span>
            <input type="text"
              class="py-3 px-4 pr-11 block w-full border-gray-200 shadow-sm rounded-r-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400">
          </div>
        </div>

        <div class="flex flex-col items-center pb-10">
          <div class="flex mt-4 space-x-3 md:mt-6"><a href="#"
              class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</a><a href="#"
              class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Cancel</a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="store.urlObject.searchParams.get('composer_tab') == 'profiler' && store.urlObject.searchParams.get('composer_environment') == 'model_creation' && store.urlObject.searchParams.get('symbol') != null" style="box-shadow: 0 5px 20px rgb(0 0 0 / 20%); padding: 0.7em 1em; margin: 1vw 0vw;" class="flex h-full dndflow z-10" @drop="onDrop">
      <ComposerSidebar v-if="store.urlObject.searchParams.get('composer_tab') == 'profiler' && store.urlObject.searchParams.get('symbol') != null"/>
      <VueFlow @dragover="onDragOver" v-if="store.urlObject.searchParams.get('composer_tab') == 'profiler' && store.urlObject.searchParams.get('symbol') != null" class="vue-flow-basic-example z-10" :default-zoom="1.5" :min-zoom="0.3" :max-zoom="4">
      
        <Background pattern-color="#aaa" gap="8" />
      
        <MiniMap />
      
        <Controls />

        <Panel :position="PanelPosition.TopLeft">
          <div class="flex flex-row">
            <div class="flex flex-row">
              <div class="mb-6">
                <label for="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">{{store.urlObject.searchParams.get('symbol')}} | Model Name</label>
                <input
                  type="text"
                  id="composer_model_name_input"
                  class="bg-green-50 p-2 border border-green-500 text-green-900 dark:text-green-400 placeholder:italic placeholder-red-700 dark:placeholder-red-700 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"
                  placeholder="Enter Name Here..."
                  :value="store.composerStockModelInput">
              </div>
            </div>
            </div>
        </Panel>
        <Panel :position="PanelPosition.TopRight">
          <div class="flex flex-row">
            <div class="flex flex-col space-y-2">
              <button @click="apiRequestComposerSaveModel() && getNeuralModels()" style="height: max-content" class="bg-blue-500 mx-1 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
                Save Model
              </button>
              <button @click="apiRequestComposerStartTrainModel() && getNeuralModels()" style="height: max-content" class="bg-green-500 mx-1 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded">
                Start Training
              </button>
              </div>
            <!-- <div class="flex flex-col">
              <button type="button" @click="resetTransform" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Reset Transform</button>
              <button type="button" @click="updatePos" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Reset Focus</button>
              <button type="button" @click="toggleclass" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Update Position</button>
              <button type="button" @click="toggleclass" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Clear All</button>
            </div> -->
          </div>
        </Panel>
        <template #node-stock_data_aggregator="resizableNodeProps">
          <ResizableNode :label="resizableNodeProps.label" />
          <ToolbarNode
            :id="resizableNodeProps.id"
            :data="resizableNodeProps.data"
            :label="resizableNodeProps.label"
            :findNode="findNode"
            :removeNodes="removeNodes" />
        </template>
        <template #node-standard_node="standardNodeProps">
          <ToolbarNode
            :id="standardNodeProps.id"
            :data="standardNodeProps.data"
            :label="standardNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <CustomNode
            :data="standardNodeProps.data"
            :label="standardNodeProps.label" />
        </template>
        <template #node-standard_input_node="standardInputNodeProps">
          <ToolbarNode
            :id="standardInputNodeProps.id"
            :data="standardInputNodeProps.data"
            :label="standardInputNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <StandardInputNode
            :id="standardInputNodeProps.id"
            :data="standardInputNodeProps.data"
            :label="standardInputNodeProps.label"
            :findNode="findNode" />
        </template>
        <template #node-trigger_node="triggerNodeProps">
          <ToolbarNode
            :id="triggerNodeProps.id"
            :data="triggerNodeProps.data"
            :label="triggerNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <TriggerNode
            :id="triggerNodeProps.id"
            :data="triggerNodeProps.data"
            :label="triggerNodeProps.label"
            :findNode="findNode" />
        </template>
      </VueFlow>
    </div>
  </div>
  </main>
</div>
</div>
</template>

<script>
import StockChartView from './StockChartView.vue'
import moment from 'moment'
import { reactive } from 'vue'
import { SelfBuildingSquareSpinner, CirclesToRhombusesSpinner, FingerprintSpinner } from 'epic-spinners'
import interHeader from './components/InterHeader.vue'

const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');

const getRandomName = () => {
  const randomName = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals], separator: '', style: 'capital'}); // big_red_donkey
  return randomName
}

let currPath = new URL(window.location.href);
const $toastSecond = useToast();
const store = reactive({
        xhrDataStockSearch: {data: [], timestamp: null},
        xhrDataCardDataETF: [],
        xhrDataCardData: {
          "signal_stream": [],
          "pagination": {
            "curr_page": "",
            "page_total": ""
          }
        },
        httpUserInfo: {
          "data": null,
          "status": "",
          "composer": {"favorite_stocks": {}}
        },
        urlObject: new URL(window.location.href),
        userData: {
          paramSymbol: currPath.searchParams.get("symbol"),
          userHref: window.location.href,
          userHash: window.location.hash,
          userSearch: currPath.searchParams.toString(),
          username: "...",
          email: "...",
          info: {
            phone_number: ""
          }
        },
        composerStockModelInput: currPath.searchParams.get("composer_model_id") == null ? getRandomName(): "",

        stockModelsData: {data: {}, status: ''},
        composerModelsData: {data: {}, status: ''}
})
/* eslint-disable */
export default {
  name: 'DashboardTrade',
  components: { FingerprintSpinner, SelfBuildingSquareSpinner, CirclesToRhombusesSpinner,interHeader },
  data() {
      return {
        store
      }
  },
  mounted() {
      if (navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i)) {
            alert("Greetings! It appears that you are currently accessing our website from a mobile device. To ensure that you have the optimal browsing experience, we kindly recommend using a laptop or desktop computer. Thank you for choosing our website!")
         }
      this.refreshToken();
      this.getNeuralModels();
      this.getUser();
      setInterval(() => {
        console.log("Auto JWT refresh");
        this.refreshToken();
      }, 60000);

  },
  methods: {
        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => response.json())
            .then(data => {
              // {"composer": {"favorite_stocks": {'1': {"symbol": 'SPY'}, '2': {"symbol": 'MSFT'}, '3': {"symbol": 'CAT'}}}}
              store.httpUserInfo = data
              store.userData.username = data["info"]["username"]
              localStorage.setItem("username", data["info"]["username"])
            })
            .catch(error => console.log('error', error));
        },
        async getNeuralModels(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stock_models?symbol=${store.userData.paramSymbol}`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              store.stockModelsData = respData
              return
            })
            .catch(error => console.log('error', error));

            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/models`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              store.composerModelsData = respData
              return
            })
            .catch(error => console.log('error', error));
        },
        showWarningToSelectSymbol(){
          console.log(this)
          return store.userData.userHref.indexOf('symbol') == -1
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        updateHash(){
          console.log("Update Hash", this.userData.userHash)
          this.userData.userHash = window.location.hash
          console.log("Update Hash (Completed)", this.userData.userHash)
        },
        handleAccount(){
          window.location = "/account"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
        getHrefForStock(event, symbol){
          currPath.searchParams.set('composer_tab', "chart")
          currPath.searchParams.set('composer_environment', "model_exploration")
          currPath.searchParams.set('symbol', symbol)
          currPath.searchParams.delete('stock_model_id')
          currPath.searchParams.delete('stock_model_name')
          window.location.href = currPath.toString();
          return
        },
        onStockSubmit(event){
          if (event.keyCode === 13){
            window.location.href = `/stock?symbol=${event.target.value}`
          }
          
        },
        handlePreviousPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0){
            c_page = 0;
          }
          url.searchParams.set('page_num', (c_page - 1));
          window.location.href = url.href;
        },
        handleNextPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0 || isNaN(c_page) == true){
            c_page = 0;
          }
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.next_page);
          window.location.href = url.href;
        },
        handleBeginPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', 0);
          window.location.href = url.href;
        },
        correctTabHighlight(tabId){
          let selected = "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
          let alternate = "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
          let _tabId = currPath.searchParams.get('composer_tab')
          
          if (_tabId){
            if (tabId == _tabId){
              return selected
            }
          }
          return alternate
        },
        async addUserStockApi(event, symbol){
          let data = {
            composer: {
              symbol: symbol,
              type: "update"
            }
          };

          var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/account_update`, requestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.status == "ok"){
                this.getUser();
              }
            })
            .catch(error => console.log('error', error));
        },
        onClickModelHandle(modelId){
          store.stockModelsData.data[modelId]
          currPath.searchParams.set('stock_model_id', store.stockModelsData.data[modelId]._id)
          currPath.searchParams.set('stock_model_name', store.stockModelsData.data[modelId].model_name)
          currPath.searchParams.set('composer_environment', 'model_exploration')
          currPath.searchParams.set('composer_tab', 'chart')
          window.location.href = currPath.toString();
        },
        onClickUserModelHandle(modelId){
          // console.log("SLVERxxxxxxxxxxxxxxxxxxx", store.composerModelsData.data[modelId])
          currPath.searchParams.set('stock_model_id', store.composerModelsData.data[modelId].neural_model_section_id)
          currPath.searchParams.set('stock_model_name', store.composerModelsData.data[modelId].name)
          currPath.searchParams.set('composer_environment', 'model_exploration')
          currPath.searchParams.set('composer_tab', 'chart')
         window.location.href = currPath.toString();
        },
        async onClickComposerModelOperationHandle(composer_model){
          console.log("apiRequestComposerStartTrainModel")
          // Can accept an Object of options
          let targetEndpoint = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}`
          let ReqBody = {}
          if (composer_model.status == "draft"){
            targetEndpoint = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/delete/`
          } else if (composer_model.status == "training"){
            targetEndpoint = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/model_operations/`
            ReqBody = {"operation_type": "stop"}
          } else if (composer_model.status == "stop"){
            targetEndpoint = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/model_operations/`
            ReqBody = {"operation_type": "training"}
          }
          fetch(targetEndpoint, {
            method: "POST", // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            },
            body: JSON.stringify({...{"composer_model_id": composer_model._id}, ...ReqBody}),
          })
          .then((response) => response.json())
          .then((data) => {
              this.getNeuralModels()
              $toastSecond.open({
                message: data.message,
                type: 'success',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
          })
          .catch((error) => {
            console.error("Error:", error);
          });

        },
        onClickComposerModelEditHandle(composer_model){
          currPath.searchParams.set('composer_tab', 'profiler')
          currPath.searchParams.set('composer_environment', 'model_creation')
          currPath.searchParams.set('composer_model_id', composer_model._id)
          currPath.searchParams.delete('stock_model_id')
          window.location.href = currPath.toString();
        },
        onClickModelCreateButtonHandle(){
          currPath.searchParams.set('composer_tab', 'profiler')
          currPath.searchParams.set('composer_environment', 'model_creation')
          currPath.searchParams.delete('stock_model_id')
          window.location.href = currPath.toString();
        },
        onClickTabHandle(tabId, urlParams){
          currPath.searchParams.set('composer_tab', tabId)
          Object.keys(urlParams).map((Uvkey) => {
            currPath.searchParams.set(Uvkey, urlParams[Uvkey])
          })
          
          window.location.href = currPath.toString();
        },
        onClickComposerHandleLiveChartEnviroment(){
          currPath.searchParams.set('composer_tab', 'chart')
          currPath.searchParams.set('composer_environment', 'live_chart')
          window.location.href = currPath.toString();
        },
        async onPressSymbolSearch(event){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };
          fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stock_search?keyword=${event.target.value}`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              if (store.xhrDataStockSearch.timestamp != null){
                if (Date.now().valueOf() > store.xhrDataStockSearch.timestamp){
                  store.xhrDataStockSearch = respData
                }
              } else {
                store.xhrDataStockSearch = respData
              }
              return
            })
            .catch(error => console.log('error', error));
            return
        },
        generateRandomNumber(min, max) {
            let highlightedNumber = Math.random() * (max - min) + min;
            return(highlightedNumber);
        }
    }
}

</script>
<style>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #494949;
}
.container {
    min-height: calc(100vh - 100px);
}
.column {
    border-right: 1px solid #494949;
}
.column ul {
    padding-inline-start: 0px;
    padding: 10px 10px;
 
}
.column li {
    background: transparent;
}

.node {
    border-radius: 8px;
    border: 2px solid #494949;
    display: block;
    height:60px;
    line-height:40px;
    padding: 10px;
    margin: 10px 0px;
    cursor: move;

}
#drawflow {
  width: 100%;
  height: 100%;
  text-align: initial;
  background: #2b2c30;
  background-size: 20px 20px;
  background-image: radial-gradient(#494949 1px, transparent 1px);
  
}
</style>
<style>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
</style>