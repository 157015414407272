<script setup>
import { MiniMap, Controls, Background, Panel, PanelPosition } from '@vue-flow/additional-components'
import { VueFlow, useVueFlow, MarkerType, } from '@vue-flow/core'
import { onMounted, nextTick, watch } from 'vue'
import { initFlowbite } from 'flowbite'
import PageBotTraderSidebar from './PageBotTraderSidebar.vue'
import ResizableNode from './vue-flow-nodes/ResizableNode.vue'
import StandardNode from './vue-flow-nodes/StandardNode.vue'
import StandardFlowNode from './vue-flow-nodes/StandardFlowNode.vue'
import StandardInputNode from './vue-flow-nodes/StandardInputNode.vue'
import StockSymbolNode from './vue-flow-nodes/portfolioTrader/StockSymbolNode.vue'
import StockModelNode from './vue-flow-nodes/portfolioTrader/StockModelNode.vue'
import StockDataAggregatorNode from './vue-flow-nodes/portfolioTrader/StockDataAggregatorNode.vue'
import ExecuteTradeNode from './vue-flow-nodes/portfolioTrader/ExecuteTradeNode.vue'
import ToolbarNode from './vue-flow-nodes/ToolbarNode.vue'
import TriggerNode from './vue-flow-nodes/TriggerNode.vue'
import {useToast} from 'vue-toast-notification';
import { reactive } from 'vue'
import baselineLayout from './components/baselineLayout.vue'
import 'vue-toast-notification/dist/theme-sugar.css';
/* these are necessary styles for vue flow */
import '@vue-flow/core/dist/style.css';

/* this contains the default theme, these are optional styles */
import '@vue-flow/core/dist/theme-default.css';
// make sure to include the necessary styles!
import '@vue-flow/node-resizer/dist/style.css'
import LoadingContent from './components/LoadingContent.vue'

let currPath = new URL(window.location.href);
const store = reactive({
      configGenerate: {
        version: 1,
        name: null,
        entry_price: {
          min_entryprice: null,
          max_entryprice: null,
        },
        risk_tolerance: {
          type: null, // custom | low | high | moderate
          custom: {
            neural_model_winrate_precent: {
              "label": "Minimum Winrate Chance",
              "value": 80,
            },
            neural_model_section_confidence: {
              "label": "Minimum Decision Confidence",
              "value": 90,
            }
          },
        },
        securities: {
          stocks: {
            automatic: null, // true|false
            symbols: {}
          }
        },
        runtime: {
          models_selection: {
            automatic: true, // true|false
            models: {
                "production/lstm_binary_model_2024-06-16_03.19.00": {"checked": false, "symbol": "General Trading - v0.1", 'model_path': 'production/lstm_binary_model_2024-06-16_03.19.00'},
                "production/lstm_binary_model_2024-06-15_11.38.00": {"checked": false, "symbol": "Short Squeeze Antler - v0.1", 'model_path': 'production/lstm_binary_model_2024-06-15_11.38.00'},
                "production/lstm_binary_model_2024-06-15_11.06.00": {"checked": false, "symbol": "Short Squeeze Beetle - v0.1", 'model_path': 'production/lstm_binary_model_2024-06-15_11.06.00'},
                "production/lstm_binary_model_2024-07-26_20.59.00": {"checked": false, "symbol": "ETF Model - Omega - v0.1", 'model_path': 'production/lstm_binary_model_2024-07-26_20.59.00'},
                "production/lstm_binary_model_2024-07-27_00.34.00": {"checked": false, "symbol": "ETF Model - Foxtrot - v0.1", 'model_path': 'production/lstm_binary_model_2024-07-27_00.34.00'},
                "production/lstm_binary_model_2024-08-19_21.18.00": {"checked": false, "symbol": "ETF Model - Omega - v0.2", 'model_path': 'production/lstm_binary_model_2024-08-19_21.18.00'},
                "production/lstm_binary_model_2024-08-19_21.21.00": {"checked": false, "symbol": "ETF Model - Foxtrot - v0.2", 'model_path': 'production/lstm_binary_model_2024-08-19_21.21.00'},
            }
          }
        },
        simulation: {
            initial_investment: 10000,
        },
        user: {
            is_live: null,
            allow_notifications: null,
            is_public: null,
        }
      },
      xhrFetchStrategyData: {
        status: null,
      },
      urlObject: new URL(window.location.href),
      userData: {
        subscription_status: "",
        username: "...",
        email: "...",
        info: {
          phone_number: ""
        }
      },
})

// initialize components based on data attribute selectors
onMounted(() => {
    initFlowbite();
})

const $toast = useToast();

/*
class StorageLocal {
  static getNodesData(symbol){
    try {
      let data = JSON.parse(localStorage.getItem("composer_node_data"))
      return [...data[symbol]["_object"]["nodes"], ...data[symbol]["_object"]["edges"]]
    } catch (err) {
      console.log("ERROR getNodesData", err)
      return []
    }
  }

  static save(symbol, nodesData){
    console.log("Saving to LocalStorage")
    localStorage.setItem("composer_node_data", JSON.stringify({[symbol]: nodesData}))
  }

  static all(symbol){
    let data = JSON.parse(localStorage.getItem("composer_node_data"))
    if (data == null){
      return null
    }
    return data[symbol]
  }
}
*/

let id = 0
const getId = () => `${id++}`

let cached_node = []
console.log("cached_node", cached_node)

let { onPaneReady,
  onNodeDrag,
  getIntersectingNodes,
  getNodes,
  isNodeIntersecting,
  onNodeDragStop,
  findNode,
  onConnect,
  nodes,
  edges,
  addEdges,
  addNodes,
  removeNodes,
  viewport,
  project,
  vueFlowRef,
  onEdgeClick,
  onEdgeDoubleClick,
  onEdgeContextMenu,
 } = useVueFlow({
  fitViewOnInit: true,
  // set this to true so edges get elevated when selected, defaults to false
  elevateEdgesOnSelect: true,
  nodes: [],
  // [
  //   { id: '1', type: 'input', label: 'Node 1', position: { x: 250, y: 5 } },
  // { id: '2', type: 'output', label: 'Node 2', position: { x: 100, y: 100 } },
  // { id: '3', label: 'Node 3', position: { x: 400, y: 100 } },
  // { id: '4', label: 'Node 4', position: { x: 150, y: 200 } },
  // { id: '5', type: 'output', label: 'Node 5', position: { x: 300, y: 300 } },
  // { id: 'e1-2', source: '1', target: '2', animated: true },
  // { id: 'e1-3', label: 'edge with arrowhead', source: '1', target: '3', markerEnd: MarkerType.Arrow },
  // { 
  //   id: 'e4-5', 
  //   type: 'step', 
  //   label: 'step-edge', 
  //   source: '4', 
  //   target: '5', 
  //   style: { stroke: 'orange' }, 
  //   labelBgStyle: { fill: 'orange' } 
  // },
  // { id: 'e3-4', type: 'smoothstep', label: 'smoothstep-edge', source: '3', target: '4' },
  // {
  //     id: 'parent0',
  //     type: 'resizable',
  //     label: 'nested parent node',
  //     position: { x: 15, y: 120 },
  //     style: { backgroundColor: 'rgba(139, 92, 246, 0.5)', height: '150px', width: '270px', border: '2px solid black'  },
  //     parentNode: '4',
  //   },
  //   {
  //     id: 'parent0:c0',
  //     label: 'nested child node',
  //     position: { x: 20, y: 40 },
  //     parentNode: 'p0',
  //   },
  // ],
})
console.log(nodes, edges, viewport, MarkerType, getIntersectingNodes, isNodeIntersecting)

const populateBasedPreselectedModelId = () => {
  console.log("populateBasedPreselectedModelId Grabbing")
    let daa = []
    var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };
          
          fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/portfolio_item?portfolio_id=${store.urlObject.searchParams.get('portfolio_id')}`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              store.xhrFetchStrategyData = respData
              console.warn("JJJJJJ", store.xhrFetchStrategyData)

              store.composerStockModelInput = respData.name
              addNodes(respData.data.workflow.nodes)
              addEdges(respData.data.workflow.edges)
              return
            })
            .catch(error => console.log('error', error));
    console.log("warrrrppp", daa)
    return daa
}

if (currPath.searchParams.get('portfolio_id') != null){
  populateBasedPreselectedModelId()
}

const hasDataStreamNode = () => {
  const filteredStockAggregateNodes = nodes.value.filter(sigNode => sigNode.type == "stock_aggregator_node" );
  return filteredStockAggregateNodes.length > 0
}

const onDragOver = (event) => {
  event.preventDefault()

  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'move'
  }
}

onPaneReady(({ fitView }) => {
  fitView()
})

// bind listeners to the event handlers
onEdgeClick((event, edge) => {
  console.log('edge clicked', edge)
})

onEdgeDoubleClick((event, edge) => {
  console.log('edge double clicked', edge)
})

onEdgeContextMenu((event, edge) => {
  console.log('edge context menu', edge)
})

onNodeDragStop((e) => {
  console.log('drag stop', e)
  // e.node.parentNode
  if(e.intersections.length <= 0 && e.node.parentNode != null){
    e.node.parentNode = null
    console.log("Node Par Removed")
  } else {
    console.log("acc", e)
    if(e.node.type == "standard_node" || e.node.type == "standard_input_node"){
      e.intersections.map( (eS) => {
        console.log("Apples", eS)
        if (["stock_data_aggregator"].indexOf(eS.type) >= -1){
          console.log("Viable Par found", eS)
          if (e.node.parentNode != eS.id){
            console.log("setting at intersec", e.node, eS)
            e.node.parentNode = eS.id
          }
        }
      })
    }
  }
})

onNodeDrag(({ intersections }) => {
  if (window.onbeforeunload == null){
    console.log("APLLING")
      window.onbeforeunload = function () {
        return "Are you sure you want to leave?";
      };
  }
  const intersectionIds = intersections.map((intersection) => intersection.id)
  getNodes.value.forEach((n) => {
    const isIntersecting = intersectionIds.includes(n.id)
    n.class = isIntersecting ? 'intersecting' : ''
  })
})

// const getNameData = () => {
//   let saveId = store.urlObject.searchParams.get('composer_model_id') ? store.urlObject.searchParams.get('composer_model_id') : store.urlObject.searchParams.get('symbol')
//   let _userData = StorageLocal.all(saveId)
//   return _userData?.model_name !== (null || undefined) ? _userData?.model_name : getRandomName()
// }

/*
const apiRequestComposerStartTrainModel = async () => {
  console.log("apiRequestComposerStartTrainModel")
  let constructure = {...{"version": 1, composer_model_id: currPath.searchParams.get('composer_model_id')}}
  // Can accept an Object of options

  fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/train_model/`, {
    method: "POST", // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
    },
    body: JSON.stringify(constructure),
  })
  .then((response) => response.json())
  .then((data) => {
    console.log("Success:", data);

    if(data.status == "ok"){
      $toast.open({
        message: data.message + ' 🚀',
        type: 'success',
        duration: 10000,
        position: 'bottom'
        // all of other options may go here
      });
    }

    if(data.status == "error"){
      $toast.open({
        message: data.message + ' 🚀',
        type: 'error',
        duration: 10000,
        position: 'bottom'
        // all of other options may go here
      });
    }

  })
  .catch((error) => {
    console.error("Error:", error);
  });
}
*/

const apiRequestComposerSaveModel = async () => {
  console.log("apiRequestComposerSaveModel")
  $toast.open({
        message: 'Saving PortFolio, Please Wait... 🚀',
        type: 'info',
        duration: 10000,
        position: 'bottom'
        // all of other options may go here
      });
  // let saveId = store.urlObject.searchParams.get('composer_model_id') ? store.urlObject.searchParams.get('composer_model_id') : store.urlObject.searchParams.get('symbol')

  // Pre-Checks
  /*
  let _userData = StorageLocal.all(saveId)
  if (_userData !== (undefined || null)){
    if(document.getElementById("portfolio_name_input").value == '' || document.getElementById("portfolio_name_input").value.length <= 1){
      alert("Please Enter a Model Name to Save")
      return
    }
  }
  */
  
  let constructure = {...{"version": 1},
                      ...{workflow: {
                            meta: {},
                            nodes: nodes._object.nodes,
                            edges: nodes._object.edges, 
                            name: store.composerStockModelInput}
                          }
                      }
  console.warn(constructure)
  let postUrl = "";
  if (store.urlObject.searchParams.get('portfolio_id') !== null){
    postUrl = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/update_portfolio_item_config?portfolio_id=${store.urlObject.searchParams.get('portfolio_id')}`
  } else {
    postUrl = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/create_portfolio_item_config`
    $toast.open({
      message: "Creating New Portfolio Named: " + store.composerStockModelInput,
      type: 'info',
      duration: 10000,
      position: 'bottom'
      // all of other options may go here
    });
  }

  await fetch(postUrl, {
      method: 'POST', // or 'PUT'
      headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
      },
      body: JSON.stringify(constructure)
    })
    .then(response => {
      if(response.status == 401 || response.status == 500 || response.status == 404){
        $toast.open({
          message: "Failed to create Portfolio Named: " + store.composerStockModelInput + " | Code: " + response.status ,
          type: 'error',
          duration: 10000,
          position: 'bottom'
          // all of other options may go here
        });
      }
      return response.json()
    })
    .then(data => {
      if (data?.status == "ok"){
        let _operation_type_text = store.urlObject.searchParams.get('operation_environment') == 'portfolio_modify'? 'Updated Portfolio': 'Created Portfolio'
        $toast.open({
          message: _operation_type_text + " Named: " + currPath.searchParams.get('portfolio_id') + " - was successful!",
          type: 'success',
          duration: 10000,
          position: 'bottom'
          // all of other options may go here
        });
        // window.location = `/product/bot_instance?portfolio_id=${data.data._id}`
      }

      if (data?.status == "error"){
        $toast.open({
          message: "Error: " + data.message,
          type: 'error',
          duration: 10000,
          position: 'bottom'
          // all of other options may go here
        });
      }
    });
  /*
  fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/composer/save_model/`, {
    method: "POST", // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
    },
    body: JSON.stringify(constructure),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      if (data.status == "ok"){
          $toast.open({
            message: `${data.message} 🪄`,
            type: 'info',
            duration: 10000,
            position: 'bottom'
        });
      }
      if (data.status == "error"){
          $toast.open({
            message: data.message,
            type: 'error',
            duration: 10000,
            position: 'bottom'
        });
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
    */
    // Save to local
    // StorageLocal.save(saveId, {...nodes, ...edges, ...{model_name: document.getElementById("portfolio_name_input").value}})
}

onConnect((params) => {
  addEdges([params])
})

const resetFlow = () => {
  console.log('Reset PortFolio', nodes.value, edges.value)
  edges.value = []
  nodes.value = []
}

const randomId = function(length = 6) {
  return Math.random().toString(36).substring(2, length+2);
};

const onDrop = (event) => {
  let _parentNode = null;
  console.log(event)
  if (event.srcElement.dataset.id){
    console.log("pass")
    if (event.srcElement.dataset.id.indexOf(":") > -1){
      console.log("is parent child", event.srcElement.dataset)
      if (event.srcElement.dataset.id.indexOf("parent") > -1){
        _parentNode = event.srcElement.dataset.id
        console.log("parent drop")
      }
    } else {
       console.log("Not a parent below")
    }
  }
  const type = event.dataTransfer?.getData('application/vueflow-type')
  const label = event.dataTransfer?.getData('application/vueflow-label')
  const attributeType = event.dataTransfer?.getData('application/vueflow-attribute-type')
  const { left, top } = vueFlowRef.value.getBoundingClientRect()

  console.log("EVENT", event)
  const position = project({
    x: event.clientX - left, // - event.offsetX,
    y: event.clientY - top, // - event.layerY,
  })

  console.log("first pass", id, getId(), type, position)
  let _id = ""
  if (type == "stock_data_aggregator"){
    _id = "dndnode_parent:" + randomId(13)
  } else {
    _id = "dndnode_" + randomId(12)
  }
  const newNode = {
    id: _id,
    type: `${type}`,
    position,
    label: `${label}`,
    parentNode: _parentNode,
    data: {attributeType: attributeType}
  }
  console.log("Ondrop", position, newNode)

  addNodes([newNode])

  // align node position after drop, so it's centered to the mouse
  nextTick(() => {
    const node = findNode(newNode.id)
    const stop = watch(
      () => node.dimensions,
      (dimensions) => {
        if (dimensions.width > 0 && dimensions.height > 0) {
          node.position = { x: node.position.x - node.dimensions.width / 2, y: node.position.y - node.dimensions.height / 2 }
          stop()
        }
      },
      { deep: true, flush: 'post' },
    )
  })

   // StorageLocal.save(nodes)
}
</script>

<template>

  <baselineLayout>
  <link rel="stylesheet" href="/css/extra.css">
  <link rel="stylesheet" href="/css/SliderAnimation.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
    <baselineBreadCrumbs/>
    <!-- Reset Portfolio Modal -->
    <div id="reset-portfolio-modal" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-md max-h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="reset-portfolio-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <div class="p-4 md:p-5 text-center">
                    <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to reset this portfolio?</h3>
                    <div class="flex justify-center mb-4">
                      <p class="italic">You will lost all your configurations for this portfolio</p>
                    </div>
                    <button @click="resetFlow()" data-modal-hide="reset-portfolio-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                    </button>
                    <button data-modal-hide="reset-portfolio-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="store.xhrFetchStrategyData.status == null" class="mx-auto fixed inset-0 flex items-center z-10">
      <LoadingContent />
    </div>

    <div :class="(store.xhrFetchStrategyData.status == null ? 'blur-state-loading disabled' : '') + 'p-4 2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 mr-4 h-[95vh] p-4 mt-2 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100'">
        <a v-if="false" :href="'/product/bot_instance?portfolio_id=' + store.urlObject.searchParams.get('portfolio_id')" type="button" class="focus:outline-none text-white bg-blue-300 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">View Portfolio</a>
        <div v-if="false" class="flex flex-row items-start h-full w-full mx-auto px-4 sm:px-6 sm:py-6 justify-center space-x-4">
        <section class="flex flex-col justify-center text-gray-600 space-y-2">
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Portfolio Name</label>
              </div>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <span class="material-symbols-outlined">signature</span>
                </div>
                <input type="text" :value="store.configGenerate.name" @input="store.configGenerate.name = $event.target.value" id="email-address-icon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Name of Portfolio">
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Minimum Entry Price</label>
              </div>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <span class="material-symbols-outlined">remove</span>
                </div>
                <input type="number" :value="store.configGenerate.entry_price.min_entryprice" @input="store.configGenerate.entry_price.min_entryprice = $event.target.value" id="email-address-icon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Minimum Entry Price">
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Maximum Entry Price</label>
              </div>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <span class="material-symbols-outlined">add</span>
                </div>
                <input type="number" :value="store.configGenerate.entry_price.max_entryprice" @input="store.configGenerate.entry_price.max_entryprice = $event.target.value" id="email-address-icon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Maximum Entry Price">
              </div>
            </div>            
            
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Risk Tolerance</label>
              </div>
              <genericCheckboxDropdown ref="riskToleranceGenericCheckboxDropdown" :selectedDefault="store.configGenerate.risk_tolerance.type" :customData="{isActive: true, data: store.configGenerate.risk_tolerance.custom}"/>
            </div>
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Symbols</label>
              </div>
              <modifyStockDropdown ref="symbolsStockDropdown" :isAutomatic="store.configGenerate.securities.stocks.automatic" :defaultData="store.configGenerate.securities.stocks.symbols"/>
            </div>
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Models</label>
              </div>
              <modifyModelsDropdown ref="ModelsDropdown" :isAutomatic="store.configGenerate.runtime.models_selection.automatic" :defaultData="store.configGenerate.runtime.models_selection.models"/>
            </div>
            <div class="flex justify-between pt-4">
              <button @click="handleDeleteRequest()" v-if="store.urlObject.searchParams.get('operation_environment') == 'portfolio_modify'" type="button" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">Archive Portfolio</button>
              <button @click="handlePostRequest()" type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">{{store.urlObject.searchParams.get('operation_environment') == 'portfolio_modify'? 'Update Portfolio': 'Create Portfolio'}}</button>
            </div>
        </section>
        </div>
      
        <div style="box-shadow: 0 5px 20px rgb(0 0 0 / 20%); padding: 0.7em 1em; margin: 1vw 0vw;" class="flex h-[98%] dndflow z-10" @drop="onDrop">
      <PageBotTraderSidebar/>
      <VueFlow @dragover="onDragOver" class="vue-flow-basic-example z-10" :connection-radius="50" :default-zoom="1.5" :min-zoom="0.3" :max-zoom="4">
      
        <Background pattern-color="#aaa" gap="8" />
      
        <MiniMap />
      
        <Controls />

        <Panel :position="PanelPosition.TopLeft">
          <div class="flex flex-row">
            <div class="flex flex-row">
              <div class="mb-6">
                <label for="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">PortFolio Name</label>
                <input
                  type="text"
                  id="portfolio_name_input"
                  onkeydown="if(event.keyCode==13){ this.blur(); return false;}"
                  class="bg-green-50 p-2 border border-green-500 text-green-900 focus:w-[200%] transition-[width] duration-300 dark:text-green-400 placeholder:italic placeholder-red-700 dark:placeholder-red-700 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"
                  placeholder="Enter Name Here..."
                  @change="store.composerStockModelInput = $event.target.value"
                  :value="store.composerStockModelInput">
              </div>
            </div>
            </div>
        </Panel>
        <Panel class="flex flex-col items-end" :position="PanelPosition.TopRight">
          <div class="flex flex-row">
            <div class="flex flex-col space-y-2">
              <button @click="apiRequestComposerSaveModel() && getNeuralModels()" style="height: max-content" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Update Portfolio
              </button>
              </div>
          </div>
          
          <div class="mt-4">
            <div v-if="hasDataStreamNode() == false" class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <span class="material-symbols-outlined mr-2"> error </span>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">Alert!</span> You are required to have at-least 1 "Stock Data Stream" present.
              </div>
            </div>
          </div>
        </Panel>
        <Panel :position="PanelPosition.BottomCenter">
          <div class="flex flex-row">
            <div class="flex flex-row space-x-2">
              <a :href="'/product/bot_instance?portfolio_id=' + store.urlObject.searchParams.get('portfolio_id') + '&tab=backtest'" type="button" style="height: max-content" class="focus:outline-none text-white bg-blue-300 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                View Portfolio
              </a>
              <button @click="apiRequestComposerSaveModel() && console.log('Archive Portfolio')" style="height: max-content" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                Archive Portfolio
              </button>
              </div>
          </div>
        </Panel>
        <Panel :position="PanelPosition.BottomLeft">
          <div class="flex flex-row">
            <div class="flex flex-row space-x-2">
              <button style="height: max-content" data-modal-target="reset-portfolio-modal" data-modal-toggle="reset-portfolio-modal" class="flex item-center focus:outline-none text-white bg-red-400 hover:bg-red-500 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <span class="material-symbols-outlined text-sm mr-1">reset_wrench</span>
                Reset Portfolio
              </button>
              </div>
          </div>
        </Panel>
        <template #node-stock_data_aggregator="resizableNodeProps">
          <ResizableNode :label="resizableNodeProps.label" />
          <ToolbarNode
            :id="resizableNodeProps.id"
            :data="resizableNodeProps.data"
            :label="resizableNodeProps.label"
            :findNode="findNode"
            :removeNodes="removeNodes" />
        </template>
        <template #node-standard_end_node="standardNodeProps">
          <ToolbarNode
            :id="standardNodeProps.id"
            :data="standardNodeProps.data"
            :label="standardNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <StandardNode
            :data="standardNodeProps.data"
            :label="standardNodeProps.label" />
        </template>
        <template #node-standard_flow_node="standardFlowNodeProps">
          <ToolbarNode
            :id="standardFlowNodeProps.id"
            :data="standardFlowNodeProps.data"
            :label="standardFlowNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <StandardFlowNode
            :data="standardFlowNodeProps.data"
            :label="standardFlowNodeProps.label" />
        </template>
        <template #node-standard_input_node="standardInputNodeProps">
          <ToolbarNode
            :id="standardInputNodeProps.id"
            :data="standardInputNodeProps.data"
            :label="standardInputNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <StandardInputNode
            :id="standardInputNodeProps.id"
            :data="standardInputNodeProps.data"
            :label="standardInputNodeProps.label"
            :findNode="findNode" />
        </template>
        <template #node-stock_aggregator_node="StockDataAggregatorNodeProps">
          <ToolbarNode
            :id="StockDataAggregatorNodeProps.id"
            :data="StockDataAggregatorNodeProps.data"
            :label="StockDataAggregatorNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <StockDataAggregatorNode
            :data="StockDataAggregatorNodeProps.data"
            :label="StockDataAggregatorNodeProps.label" />
        </template>
        <template #node-stock_symbol_node="StockSymbolNodeProps">
          <ToolbarNode
            :id="StockSymbolNodeProps.id"
            :data="StockSymbolNodeProps.data"
            :label="StockSymbolNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <StockSymbolNode
            :id="StockSymbolNodeProps.id"
            :data="StockSymbolNodeProps.data"
            :label="StockSymbolNodeProps.label"
            :findNode="findNode" />
        </template>
        <template #node-trade_execute_node="TradeExecuteNodeProps">
          <ToolbarNode
            :id="TradeExecuteNodeProps.id"
            :data="TradeExecuteNodeProps.data"
            :label="TradeExecuteNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <ExecuteTradeNode
            :id="TradeExecuteNodeProps.id"
            :data="TradeExecuteNodeProps.data"
            :label="TradeExecuteNodeProps.label"
            :findNode="findNode" />
        </template>
        <template #node-stock_model_node="StockModelNodeProps">
          <ToolbarNode
            :id="StockModelNodeProps.id"
            :data="StockModelNodeProps.data"
            :label="StockModelNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <StockModelNode
            :id="StockModelNodeProps.id"
            :data="StockModelNodeProps.data"
            :label="StockModelNodeProps.label"
            :findNode="findNode" />
        </template>
        <template #node-trigger_node="triggerNodeProps">
          <ToolbarNode
            :id="triggerNodeProps.id"
            :data="triggerNodeProps.data"
            :label="triggerNodeProps.label"
            :removeNodes="removeNodes"
            :findNode="findNode"/>
          <TriggerNode
            :id="triggerNodeProps.id"
            :data="triggerNodeProps.data"
            :label="triggerNodeProps.label"
            :findNode="findNode" />
        </template>
      </VueFlow>
    </div>
    </div>
  </baselineLayout>
</template>

<style scoped>
body {
  height: 100vh;
}
.blur-state-loading {
  filter: blur(16px);
}
.vue-flow-basic-example {
  height: auto;
}
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 95%;
  height: 35vw;
  padding: 16px 6px 16px 16px;
}
</style>