<template>
  <div class="flex flex-col items-center w-4/5 p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white ">Join Waitlist 👋</h5>
      <p class="w-1/2 mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">We're currently releasing this feature to a select group of individuals, send us a message in chat to join the list!</p>
      <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
          <button @click="this.handleJoinWaitList" class="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-[#008FFB] rounded-full shadow-md group">
            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#008FFB] group-hover:translate-x-0 ease">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            </span>
            <span class="absolute flex items-center justify-center w-full h-full text-gray-500 transition-all duration-300 transform group-hover:translate-x-full ease">Join Waitlist</span>
            <span class="relative invisible">Button Text</span>
          </button>
      </div>
  </div>
</template>

<script>
export default {
 props: {
 },
 mounted(){
 },
 data(){
    return {
    }
 },
 methods: {
  handleJoinWaitList(){
    window.tidioChatApi.open();
  }
 }
 
}
</script>

<style scoped>

</style>