<template>
  <svg class="sparkline" :id="id" height=60 width=150 viewBox="0 0 300 60">
    <path :class="data[0] <= data[data.length -1] ? 'positive': 'negative'" d="M 0 20 H 200" fill="none" stroke-width="3" stroke="gold" />
  </svg>
</template>

<script>
import * as d3 from "d3";

const randId = () => {
     return Math.random().toString(36).replace(/[^a-z]+/g, '');
}

export default {
  name: "SparkLine",
  props: {
    data: {
      type: Array,
      default() {
        return [0, 0];
      }
    }
  },
  data() {
    return {
      id: `chart-${randId()}`
    };
  },
  watch: {
    data() {
      this.plot();
    }
  },
  methods: {
    plot() {
      this.x.domain([0, this.data.length - 1]);
      this.y.domain(d3.extent(this.data));
      this.chart.select("path").attr("d", this.line(this.data));
    }
  },
  mounted() {
    this.chart = d3.select(`#${this.id}`);

    this.x = d3.scaleLinear().range([0, 200]);

    this.y = d3.scaleLinear().range([40, 0]);

    this.line = d3
      .line()
      .x((d, i) => this.x(i))
      .y(d => this.y(d));

    this.plot();
  }
};
</script>

<style scoped>
svg {
  stroke: #81ba67;
  fill: rgba(129, 186, 103, 0.1);
  transition: all 1s ease-in-out;
}

svg path {
  box-sizing: border-box;
  stroke: #81ba67;
}

.negative {
  box-sizing: border-box;
  stroke: #d4161d;
}
</style>